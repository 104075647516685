import { io } from 'socket.io-client'
import config from '../config'

// Messages that can be received from socket server
const messageTypes = [
    '@@SOCKET/SET_DASHBOARD',
    '@@SOCKET/SET_MODULES',
    '@@SOCKET/DELETE_MODULE',
    '@@SOCKET/REFRESH_MODULE',
    '@@SOCKET/EDITING_ADD_MODULE',
    '@@SOCKET/NAVIGATION_SET_MESSAGE',
    '@@SOCKET/UPDATE_MODULE_REVISION',
    '@@SOCKET/BROADCAST/DASHBOARD_UPDATE',
    '@@SOCKET/MODULES/CREATED',
    '@@SOCKET/ADMIN_SET_PROGRESS',
    '@@SOCKET/SET_ANALYTICS',
    '@@SOCKET/SET_ORG',
    '@@SOCKET/SET_LOCKS',
]

let token = ''

export const socket = io(config.api.url, {
    // path: '/socket.io',
    query: {
        token: token,
    },
})

// socket.on('reconnect_attempt', () => {
//     socket.io.opts.query = {
//         token: token
//     }
// })

export const init = (store) => {
    messageTypes.forEach((type) =>
        socket.on(type, (payload) => store.dispatch({ type, payload }))
    )

    // socket.on('BROADCAST/DASHBOARD_UPDATE', payload => {
    //     console.log('received broadcast ')

    //     const { dashboard } = store.getState()
    //     if (dashboard._id === payload._id) {
    //         store.dispatch({
    //             type: 'SET_DASHBOARD',
    //             payload
    //         })
    //     }
    // })

    // store.subscribe(() => {
    //     const { keycloak } = store.getState()
    //     if (!isEmpty(keycloak) && token === '') {
    //         console.log('----- got cloaked')
    //         token = keycloak.token
    //         socket.disconnect()
    //         socket.connect()
    //     }
    // })
}

export const emit = (type, payload, cb = null) => socket.emit(type, payload, cb)
