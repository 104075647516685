import React from 'react'
import classname from 'classnames'
import styled from 'styled-components'
import {
    Button,
    ButtonGroup,
    Icon,
    Popover,
    Menu,
    Position,
    Tooltip,
    PopoverInteractionKind,
    NumericInput,
    Checkbox,
} from '@blueprintjs/core'
import _ from 'lodash'
import ColorPicker from 'v2/components/widgets/color'
import { ImagePreview, ImageUploader } from 'v2/components/widgets/image'
import { useDocumentUpdateTools } from 'v2/components/manager/api'
import FontLoader from 'v2/components/widgets/gfonts'

const StyledEditMenu = styled.div`
    background: #f5f8fa;
    border: 1px solid rgba(0, 0, 0, 0.1);
    /*box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.12);*/
    border-radius: 2px;
    padding: 15px 20px;

    /*box-shadow: 0 5px 8px 3px rgba(0,0,0,0.1), inset -1px -3px 0 0 rgba(0,0,0,0.2);*/
    box-shadow: 0 6px 9px 3px rgba(0, 0, 0, 0.05),
        inset -1px -2px 0 0 rgba(0, 0, 0, 0.2);

    display: flex;

    button {
        font-weight: bold;
    }

    .bp3-icon {
        color: #000;
    }

    &.minimal {
        box-shadow: none;
    }
`

const EditMenuDivider = styled.div`
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    height: 60px;
    margin-top: -15px;
    margin-bottom: -20px;
    margin-right: 20px;
    margin-left: 20px;
`

const EditMenuPopover = ({ children, renderMore }) => {
    const [content, target] = React.Children.toArray(children)
    return (
        <Popover
            autoFocus={false}
            minimal={false}
            targetClassName="edit-menu-target"
            popoverClassName="edit-menu-popover"
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.TOP}
            content={content}
            target={target}
        />
    )
}

const EditMenuDropdown = ({ children, title, more }) => {
    const target = more ? (
        <Button icon="more" minimal={true} />
    ) : (
        <Button rightIcon="chevron-down" text={title} minimal={true} />
    )
    return (
        <Popover
            autoFocus={false}
            interactionKind={PopoverInteractionKind.HOVER}
            position={Position.BOTTOM}
            target={target}
            content={<Menu>{children}</Menu>}
        />
    )
}

EditMenuDropdown.defaultProps = {
    more: false,
}

const EditMenu = ({ children, minimal }) => {
    return (
        <StyledEditMenu className={classname({ minimal })}>
            {children}
        </StyledEditMenu>
    )
}

EditMenu.defaultProps = {
    minimal: false,
}

const EditMenuBorderColor = ({ element, ...props }) => {
    const { updateElementData } = useDocumentUpdateTools()
    const initialColor = _.get(element.data, 'border.color', props.initialColor)

    const _onChange = (color) => {
        updateElementData(element, {
            border: {
                color,
            },
        })
    }
    return (
        <ColorPicker initialColor={initialColor} onChange={_onChange}>
            <Tooltip content="Border Color" autoFocus={false}>
                <Button minimal={true}>
                    <Icon
                        icon="circle"
                        color={
                            initialColor === 'transparent' ? null : initialColor
                        }
                    />
                </Button>
            </Tooltip>
        </ColorPicker>
    )
}

EditMenuBorderColor.defaultProps = {
    initialColor: 'transparent',
}

const EditBackgroundColor = ({ element, ...props }) => {
    const { updateElementData } = useDocumentUpdateTools()
    const initialColor = element.data.backgroundColor || props.initialColor

    const _onChange = (color) => {
        updateElementData(element, {
            backgroundColor: color,
        })
    }

    return (
        <ColorPicker initialColor={initialColor} onChange={_onChange}>
            <Tooltip content="Background Color" autoFocus={false}>
                <Button minimal={true}>
                    <Icon
                        icon="full-circle"
                        color={
                            initialColor === 'transparent' ? null : initialColor
                        }
                    />
                </Button>
            </Tooltip>
        </ColorPicker>
    )
}

EditBackgroundColor.defaultProps = {
    initialColor: 'transparent',
}

const EditPadding = ({ element, ...props }) => {
    const { updateElementData } = useDocumentUpdateTools()
    const initialData = _.get(element.data, 'padding', props.padding)

    const _onChange = (padding) => {
        updateElementData(element, {
            padding,
        })
    }

    return (
        <Tooltip content="Padding">
            <NumericInput
                style={{
                    width: '50px',
                }}
                value={initialData}
                onValueChange={_onChange}
            />
        </Tooltip>
    )
}

EditPadding.defaultProps = {
    padding: 0,
}

const EditScroll = ({ element, ...props }) => {
    const { updateElementData } = useDocumentUpdateTools()
    const initialData = _.get(element.data, 'scroll', props.scroll)

    const _onChange = (event) => {
        updateElementData(element, {
            scroll: event.currentTarget.checked,
        })
    }

    return (
        <Checkbox
            label="Horizontal Scrolling"
            defaultChecked={initialData}
            onChange={_onChange}
            style={{ marginBottom: 0, lineHeight: '30px' }}
        />
    )
}

EditScroll.defaultProps = {
    scroll: false,
}

const EditAlignment = ({ element, ...props }) => {
    const { updateElementData } = useDocumentUpdateTools()
    const _onClick = (align) => {
        updateElementData(element, {
            align,
        })
    }

    return (
        <ButtonGroup minimal={true}>
            <Tooltip content="Align Left">
                <Button icon="align-left" onClick={() => _onClick('left')} />
            </Tooltip>
            <Tooltip content="Align Center">
                <Button
                    icon="align-center"
                    onClick={() => _onClick('center')}
                />
            </Tooltip>
            <Tooltip content="Align Right">
                <Button icon="align-right" onClick={() => _onClick('right')} />
            </Tooltip>
        </ButtonGroup>
    )
}

const EditBorderRadius = ({ element, ...props }) => {
    const { updateElementData } = useDocumentUpdateTools()
    const initialData = _.get(element.data, 'border.radius', props.radius)

    const _onChange = (radius) => {
        updateElementData(element, {
            border: {
                radius,
            },
        })
    }
    return (
        <Tooltip content="Border Radius">
            <NumericInput
                style={{
                    width: '50px',
                }}
                value={initialData}
                onValueChange={_onChange}
            />
        </Tooltip>
    )
}

EditBorderRadius.defaultProps = {
    radius: 0,
}

const EditBackgroundImage = ({ element, ...props }) => {
    const { updateElementData, deleteElementData } = useDocumentUpdateTools()
    const initialImage = element.data.image || props.image

    const _onUpload = (base64) => {
        updateElementData(element, {
            image: { base64 },
        })
    }

    const _onFitChange = (fit) => {
        updateElementData(element, {
            image: { fit },
        })
    }

    const _onClear = () => {
        deleteElementData(element, 'image')
    }

    return (
        <Popover
            autoFocus={false}
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.BOTTOM}
            target={
                <Tooltip content="Background Image">
                    <Button
                        minimal={true}
                        icon="media"
                        active={!_.isEmpty(initialImage)}
                    />
                </Tooltip>
            }
            content={
                <React.Fragment>
                    <ImagePreview image={initialImage} onClear={_onClear} />
                    <Menu>
                        <Menu.Item text="Image Fit">
                            <Menu.Item
                                text="Contain"
                                onClick={() => _onFitChange('contain')}
                            />
                            <Menu.Item
                                text="Cover"
                                onClick={() => _onFitChange('cover')}
                            />
                            <Menu.Item
                                text="Fill"
                                onClick={() => _onFitChange('fill')}
                            />
                            <Menu.Item
                                text="Fit Height"
                                onClick={() => _onFitChange('fitHeight')}
                            />
                            <Menu.Item
                                text="Fit Width"
                                onClick={() => _onFitChange('fitWidth')}
                            />
                        </Menu.Item>
                    </Menu>
                    <ImageUploader image={initialImage} onChange={_onUpload} />
                </React.Fragment>
            }
        />
    )
}

EditBackgroundImage.defaultProps = {
    image: {},
}

const EditFont = ({ element, ...props }) => {
    const { updateElementData } = useDocumentUpdateTools()
    const initialData = element.data.font || props.font

    const _onChange = (font) => {
        updateElementData(element, {
            font,
        })
    }

    return (
        <React.Fragment>
            <Tooltip content="Font Family and Weight">
                <FontLoader initialData={initialData} onChange={_onChange} />
            </Tooltip>
            <Tooltip content="Font size">
                <NumericInput
                    style={{
                        width: '50px',
                    }}
                    value={initialData.size}
                    onValueChange={(size) =>
                        _onChange({
                            ...initialData,
                            size,
                        })
                    }
                />
            </Tooltip>
            <ColorPicker
                initialColor={initialData.color}
                onChange={(color) =>
                    _onChange({
                        ...initialData,
                        color,
                    })
                }
            >
                <Tooltip content="Font color" autoFocus={false}>
                    <Button minimal={true}>
                        <Icon
                            icon="full-circle"
                            color={
                                initialData.color === 'transparent'
                                    ? null
                                    : initialData.color
                            }
                        />
                    </Button>
                </Tooltip>
            </ColorPicker>
        </React.Fragment>
    )
}

EditFont.defaultProps = {
    font: {
        family: 'Open Sans',
        weight: 200,
        color: '#000',
        size: 14,
    },
}

export {
    EditMenuPopover,
    EditMenu,
    EditMenuDivider,
    EditMenuDropdown,
    EditMenuBorderColor,
    EditBackgroundColor,
    EditBackgroundImage,
    EditFont,
    EditBorderRadius,
    EditPadding,
    EditScroll,
    EditAlignment,
}
