import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classname from 'classnames'
import {
    Icon,
    InputGroup,
    Callout,
    Intent,
    Dialog,
    Button,
    Classes,
    ButtonGroup,
    Divider,
} from '@blueprintjs/core'
import moment from 'moment'
import get from 'lodash/get'
import RenderDocument from './render'
import DeleteDocument from './delete'
import DateFromNow from '../modules/date'
import RenderLink from '../modules/linker/render'
import { ModuleHeader } from '../modules/header'
import DocumentEdit from './edit/index'
import { EditMetaDataDialog } from './create'
// Temporary v2 renderer for timeline
import MainV2Render from 'v2/components/bridge'

const Document = styled.div`
    /*min-height: 250px;
    min-width: 175px;
    max-width: 275px;*/
    border: 1px solid #ced9e0;
    border-radius: 3px;
    background: #f5f8fa;
    margin-right: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;

    &.selected {
        border: 2px solid #0f9960;
    }

    .doc-screen {
        margin: -15px;
        margin-bottom: 10px;
        padding: 4px 8px;
        background: #bfccd6;
        font-weight: bold;
        font-size: 0.75rem;

        &.TimelineViewer {
            background-color: green;
            color: white;
        }
    }

    .doc {
        display: flex;
        flex-direction: column;

        .icon {
            width: 24px;
        }

        .identifier {
            display: flex;
        }

        flex-grow: 2;
    }

    label {
        color: #8a9ba8;
        font-size: 0.75rem;
        margin: 4px 0;
    }

    .id {
        font-size: 1rem;
        width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .rev {
        font-size: 1rem;
        font-weight: bold;
        margin-right: 10px;
    }

    h3 {
        margin: 0;
        line-height: 1;
        font-size: 1rem;
    }

    .doc-title {
        display: flex;
        align-items: top;
        margin-bottom: 8px;

        h3 {
            flex-grow: 1;
            margin: 0 3px;
        }
    }

    .desc {
        flex-grow: 2;
        span {
            color: #bfccd6;
        }
    }

    .editing {
        font-size: 0.75rem;

        span {
            margin-right: 4px;
        }
    }

    .doc-parents {
        margin: 8px -16px;
        background: #ebf1f5;
        adding: 4px 0;
    }
`

const ModuleListWrapper = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;

    column-width: 250px;
    column-gap: 15px;
    width: 95%;
    margin: 50px auto;

    display: flex;
    flex-wrap: wrap;

    li {
        display: inline-block;
        margin-bottom: 10px;
        column-break-inside: avoid;
        width: 300px;
        margin: 0 2px 15px;
    }
`

const PreviewMeta = styled.div`
    padding: 0 0 20px;

    label {
        color: #8a9ba8;
        font-size: 0.75rem;
        margin: 4px 0;
    }

    .id {
        font-size: 1.5rem;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        color: #5c7080;
    }
    .rev {
        font-size: 1.5rem;
        font-weight: bold;
        margin-right: 10px;
    }

    .metadata {
        display: flex;
        padding: 0 0 10px 0;
        .title {
            font-weight: bold;
            margin-right: 10px;
        }

        .desc {
            flex-grow: 1;
            span {
                color: #bfccd6;
            }
        }
    }
`

const DocumentPreview = styled.div`
    display: flex;

    .preview {
        min-width: 375px;
        width: 375px;
        margin-right: 10px;
        border: 2px solid black;
        height: 900px;
        overflow-y: scroll;

        h1 {
            line-height: 1.28581;
        }
    }
    .edit {
        margin-left: 10px;
    }
`

export class PreviewRender extends React.Component {
    state = {
        isOpen: false,
    }
    _handleClose = () => {
        this.setState({ isOpen: false })
    }
    render() {
        const { module } = this.props
        const { isOpen } = this.state
        const metadata = get(module, 'metadata', {})
        return (
            <React.Fragment>
                <Button
                    text="Edit view"
                    icon="cog"
                    onClick={() => this.setState({ isOpen: true })}
                    minimal
                    fill
                />
                <Dialog
                    isOpen={isOpen}
                    usePortal={true}
                    lazy={true}
                    title={`Edit View`}
                    onClose={this._handleClose}
                    style={{ width: 1200 }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <PreviewMeta>
                            <label>title and description</label>
                            <div className="metadata">
                                <div className="title">{metadata.title}</div>
                                <div className="desc">
                                    {metadata.description || (
                                        <span>empty description</span>
                                    )}
                                </div>
                            </div>
                            <label>revision</label>
                            <div className="identifier">
                                <span className="rev">
                                    {module._rev.split('-')[0]}
                                </span>
                                <span className="id">{module._id}</span>
                            </div>
                            <label>updated</label>
                            <DateFromNow
                                date={module.updated}
                                prefix="Saved"
                                icon="saved"
                            />
                        </PreviewMeta>
                        <Divider />
                        <DocumentPreview>
                            <RenderLink moduleId={module._id} />
                            <div className="preview">
                                <ModuleHeader header={module.header} />
                                <RenderDocument module={{ id: module._id }} />
                            </div>
                            <div className="edit">
                                <DocumentEdit
                                    navigation={{
                                        module: {
                                            id: module._id,
                                        },
                                    }}
                                />
                            </div>
                        </DocumentPreview>
                        <Divider />
                        <DeleteDocument
                            id={module._id}
                            rev={module._rev}
                            onDelete={this._handleClose}
                        />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button minimal onClick={this._handleClose}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

const friendlyName = (screen) => {
    switch (screen) {
        case 'GenericView':
            return 'Default view'
        case 'TabbedView':
            return 'Tabbed view'
        case 'NearbyView':
            return 'Nearby map'
        case 'TimelineView':
            return 'Timeline'
        case 'TimelineViewer':
            return 'Timeline v2'
        default:
            return 'Unknown screen type'
    }
}

export const ModuleWrapper = ({ module, edit, children, impressions }) => {
    if (!module) {
        return <Callout intent={Intent.DANGER}>Missing document data!</Callout>
    }
    const metadata = get(module, 'metadata', {})

    return (
        <Document>
            <div className={classname('doc-screen', module.screen)}>
                {friendlyName(module.screen)}
            </div>
            <div className="doc">
                <div className="doc-title">
                    <Icon icon="document" />
                    <h3>{metadata.title}</h3>
                    <EditMetaDataDialog moduleId={module._id} />
                </div>
                <div className="desc">
                    {metadata.description || <span>empty description</span>}
                </div>
                <label>revision</label>
                <div className="identifier">
                    <span className="rev">{module._rev.split('-')[0]}</span>
                    <span className="id">{module._id}</span>
                </div>
                <label>updated</label>
                <DateFromNow
                    date={module.updated}
                    prefix="Saved"
                    icon="saved"
                />
                <label>status</label>
                <DateFromNow
                    date={edit.date}
                    prefix="Downloaded"
                    icon="database"
                />
                <label>impressions</label>
                <span>{(impressions || 0).toLocaleString()}</span>
            </div>
            <div className="doc-parents">
                <RenderLink
                    moduleId={module._id}
                    linkedModule={<ModuleHeader header={module.header} />}
                />
            </div>
            {children}
        </Document>
    )
}

ModuleWrapper.defaultProps = {
    edit: {},
}

const ModuleList = ({ modules, editing, getModule, analytics }) => (
    <ModuleListWrapper>
        {modules.map((module, index) => (
            <li key={index}>
                <ModuleWrapper
                    module={module}
                    edit={editing.find((mod) => mod.id === module._id)}
                    impressions={analytics[module._id]}
                >
                    {/* This is where we check for new version renderer */}
                    {module.screen === 'TimelineViewer' ? (
                        <MainV2Render module={module} />
                    ) : (
                        <PreviewRender module={module} />
                    )}
                </ModuleWrapper>
            </li>
        ))}
    </ModuleListWrapper>
)

ModuleList.propTypes = {
    modules: PropTypes.array,
}

class AllDocumentView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modules: props.modules,
            searchFilter: '',
            sortFilter: 'metadata.title',
        }
    }
    _onSearch(filter) {
        const { modules } = this.props

        this.setState({ searchFilter: filter })

        if (filter === '') {
            this.setState({ modules })
            return
        }

        const textToFind = filter.toLowerCase()

        const _modules = Object.keys(modules)
            .map((index) => modules[index])
            .filter((mod) => mod.version === undefined || mod.version === 1)
            .filter((mod) => {
                const { metadata, header, _id } = mod

                // @note: for v2, safe `get` these fields.
                // Document fragments don't have metadata or headers.
                const title = get(metadata, 'title', '')
                const description = get(metadata, 'description', '')
                const headerTitle = get(header, 'title', '')

                return (
                    title.toLowerCase().includes(textToFind) ||
                    description.toLowerCase().includes(textToFind) ||
                    headerTitle.toLowerCase().includes(textToFind) ||
                    _id.toLowerCase().includes(textToFind)
                )
            })

        this.setState({ modules: _modules })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { searchFilter } = this.state
        this._onSearch(searchFilter)
    }

    render() {
        const { modules, sortFilter } = this.state
        const { editing, getModule, analytics } = this.props

        return (
            <div>
                <InputGroup
                    placeholder="Search views"
                    type="search"
                    dir="auto"
                    onChange={(e) => this._onSearch(e.target.value)}
                />
                <h4>Sort views by</h4>
                <ButtonGroup minimal>
                    <Button
                        text="Document title"
                        active={sortFilter === 'metadata.title'}
                        onClick={() =>
                            this.setState({ sortFilter: 'metadata.title' })
                        }
                    />
                    <Button
                        text="Header title"
                        active={sortFilter === 'header.title'}
                        onClick={() =>
                            this.setState({ sortFilter: 'header.title' })
                        }
                    />
                    <Button
                        text="Date updated"
                        active={sortFilter === 'updated'}
                        onClick={() => this.setState({ sortFilter: 'updated' })}
                    />
                    <Button
                        text="Impressions"
                        active={sortFilter === 'impressions'}
                        onClick={() =>
                            this.setState({ sortFilter: 'impressions' })
                        }
                    />
                </ButtonGroup>
                <ModuleList
                    modules={Object.keys(modules)
                        .map((key) => modules[key])
                        .filter(
                            (mod) =>
                                mod.version === undefined || mod.version === 1
                        )
                        .sort((a, b) => {
                            if (sortFilter === 'updated') {
                                if (a.updated && b.updated) {
                                    if (moment(a.updated).isBefore(b.updated)) {
                                        return -1
                                    }
                                    if (moment(a.updated).isAfter(b.updated)) {
                                        return 1
                                    }
                                    return 0
                                }
                                // if (a.updated && !b.updated) {
                                //     return -1
                                // }
                                // if (!a.updated && b.updated) {
                                //     return 1
                                // }
                                return 0
                            } else if (sortFilter === 'impressions') {
                                const impressionsA = analytics[a._id] || 0
                                const impressionsB = analytics[b._id] || 0

                                if (impressionsA > impressionsB) {
                                    return -1
                                } else if (impressionsA < impressionsB) {
                                    return 1
                                } else {
                                    return 0
                                }
                            } else {
                                if (
                                    get(a, sortFilter, '').toLowerCase() <
                                    get(b, sortFilter, '').toLowerCase()
                                ) {
                                    return -1
                                }
                                if (
                                    get(a, sortFilter, '').toLowerCase() >
                                    get(b, sortFilter, '').toLowerCase()
                                ) {
                                    return 1
                                }
                                return 0
                            }
                        })}
                    editing={editing}
                    getModule={getModule}
                    analytics={analytics}
                />
            </div>
        )
    }
}

export default AllDocumentView
