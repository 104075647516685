import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Icon, Callout, Intent, Card, Button, Tooltip } from '@blueprintjs/core'
import LinkerDialog from './linker/dialog'
import { ModuleWrapper } from '../document/all'
import { isValidDocument, isValidUrl } from '../../utils'
import Tags from './tags'

const LinkBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Highlight = styled.div`
    border: 2px solid #0e5a8a;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const WebContextPreview = ({ navigation }) => (
    <React.Fragment>
        <Callout icon="globe" title="Web link">
            A link to{' '}
            <a
                href={navigation.url.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Tooltip content={navigation.url.url}>
                    {navigation.url.title}
                </Tooltip>
            </a>{' '}
            will be presented to the user in the device.
        </Callout>
        <br />
        <div>
            The device preview cannot show embedded URLs due to browser security
            policy.
        </div>
    </React.Fragment>
)

const ModuleContextPreview = ({ navigation, modules, editing, analytics }) => (
    <React.Fragment>
        <ModuleWrapper
            module={modules[navigation.module.id]}
            edit={editing.find(
                mod => mod.id === modules[navigation.module.id]._id
            )}
            impressions={analytics[navigation.module.id]}
        >
            <Tags module={modules[navigation.module.id]} />
        </ModuleWrapper>
        <div>
            You can see the rendered document in the device preview{' '}
            <Icon icon="arrow-right" iconSize={16} />{' '}
        </div>
    </React.Fragment>
)

const ContextPreview = ({ navigation, modules, editing, analytics }) => {
    let renderElement = (
        <Callout intent={Intent.WARNING}>
            Button does not open anything!
        </Callout>
    )

    if (isValidDocument(navigation, modules)) {
        renderElement = (
            <ModuleContextPreview
                modules={modules}
                navigation={navigation}
                editing={editing}
                analytics={analytics}
            />
        )
    }

    if (isValidUrl(navigation)) {
        renderElement = <WebContextPreview navigation={navigation} />
    }

    return renderElement
}

class ModuleContextView extends React.Component {
    state = {
        isOpen: false,
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        const { isOpen } = this.state
        const { dashboard, preview, modules, editing, parent, analytics } = this.props
        return (
            <Card>
                <LinkBody>
                    <strong>When a user presses this button</strong>
                    <Icon icon="arrow-down" color="#0E5A8A" iconSize={30} />
                    <Highlight>
                        {createElement(parent, {
                            dashboard,
                            modules,
                            parent: preview.parent,
                        })}
                    </Highlight>
                    <strong>It will open this view</strong>
                    <Icon icon="arrow-down" color="#0E5A8A" iconSize={30} />
                    <Highlight>
                        <ContextPreview
                            modules={modules}
                            navigation={preview.navigation}
                            editing={editing}
                            analytics={analytics}
                        />
                    </Highlight>
                    <Callout intent={Intent.PRIMARY}>
                        You can change the view that will open
                    </Callout>
                    <Button
                        text="Change view"
                        minimal
                        fill
                        onClick={() => this.setState({ isOpen: true })}
                    />
                    <LinkerDialog
                        isOpen={isOpen}
                        handleClose={this.handleClose}
                        parent={preview.parent}
                    />
                </LinkBody>
            </Card>
        )
    }
}

ModuleContextView.propTypes = {
    parent: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    preview: state.preview,
    modules: state.modules,
    editing: state.editing,
    dashboard: state.dashboard,
    analytics: state.analytics,
})

export default connect(mapStateToProps)(ModuleContextView)
