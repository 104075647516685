import React from 'react'
import styled from 'styled-components'
import { Callout, Intent } from '@blueprintjs/core'

const Wrapper = styled.div`
    max-width: 300px;
    overflow-x: scroll;
`

class EditorError extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong in the editor.</h1>
        }

        return this.props.children
    }
}

class DocumentError extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
    }

    render() {
        const id = this.props.doc._id || 'unknown ID'

        if (this.state.hasError) {
            return (
                <Wrapper>
                    <Callout intent={Intent.WARNING}>
                        <p>
                            something failed rendering the document{' '}
                            <strong>{id}</strong>
                        </p>
                        <p>look at the development log for more info</p>
                    </Callout>
                </Wrapper>
            )
        }

        return this.props.children
    }
}

export { EditorError, DocumentError }
