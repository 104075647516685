import React from 'react'
import { Button, InputGroup, Label, Callout } from '@blueprintjs/core'

class Module extends React.Component {
    state = {
        phone: '',
    }

    render() {
        return (
            <Callout title="Dial a phone number">
                <Label>
                    Phone number
                    <InputGroup
                        type="tel"
                        maxLength={500}
                        placeholder="3105551234"
                        value={this.state.phone}
                        onChange={e =>
                            this.setState({
                                phone: e.target.value,
                            })
                        }
                    />
                </Label>
                <Button
                    minimal
                    icon="phone"
                    disabled={this.state.phone === ''}
                    fill
                    text="Select this phone number"
                    onClick={() => this.props.onLink(this.state)}
                />
            </Callout>
        )
    }
}

export default Module
