import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Collapse, Tooltip } from '@blueprintjs/core'
import {
    revisionPrettyPrint,
    useDocumentPublishTools,
} from 'v2/components/manager/api'
import moment from 'moment'

const StyledStatsBox = styled.div`
    color: #000;
    font-size: 0.75rem;
    font-weight: 300;

    .revision {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .version {
            font-weight: bold;
            font-size: 1rem;
        }
    }

    .published {
        padding: 0 10px;

        .title {
            font-weight: 500;
        }
    }
`
const DocumentStat = ({ doc, allowPublish }) => {
    const [isOpen, setOpen] = useState(false)
    // const { publish, lastPublish } = useDocumentPublishTools()

    //     const _onPublish = () => {
    //         publish(doc)
    //     }
    //
    //     const last = lastPublish(doc)
    //     const _renderPublished = last ? (
    //         <p>
    //             published {moment(last.on).format('MMMM Do YYYY, h:mm a')} @{' '}
    //             <strong>
    //                 revision <span>{last.revision}</span>
    //             </strong>
    //         </p>
    //     ) : (
    //         <p>Unpublished</p>
    //     )

    return (
        <StyledStatsBox>
            {allowPublish ? (
                <React.Fragment>
                    <div className="revision">
                        <span>rev</span>
                        <span className="version">
                            {revisionPrettyPrint(doc._rev)}
                        </span>
                    </div>
                    <Collapse isOpen={isOpen}>
                        <div className="published">
                            <Tooltip content="Publishing will make edits live on your apps">
                                <Button
                                    text="Publish"
                                    fill={true}
                                    outlined={true}
                                />
                            </Tooltip>
                        </div>
                    </Collapse>
                </React.Fragment>
            ) : (
                <div className="revision">
                    <span>rev</span>
                    <span className="version">
                        {revisionPrettyPrint(doc._rev)}
                    </span>
                </div>
            )}
        </StyledStatsBox>
    )
}

DocumentStat.defaultProps = {
    allowPublish: false,
}

export default DocumentStat
