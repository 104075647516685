import React from 'react'
import { Tab, Tabs, Icon, Button, Spinner } from '@blueprintjs/core'
import { RenderBody, EmptyBody } from './body'

const RenderNearby = ({ body, openPanel, module, modules, setRenderTabId, selectedTabId }) => {

	return (
		<Tabs
			id="NearbyTabs"
			onChange={setRenderTabId}
			selectedTabId={selectedTabId}
		>
			{body.nearby.map((tab, index) => (
				<Tab
					key={index}
					id={index}
					title={tab.title}
					panel={
						<React.Fragment>
							<RenderBody
								module={module}
								openPanel={openPanel}
								body={tab.body}
								selectedTabId={index}
								modules={modules}
							/>
						</React.Fragment>
					}
				/>
			))}

			<Tabs.Expander />
		</Tabs>
	)
}

export default RenderNearby
