import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'

import App from './App'
import reportWebVitals from './reportWebVitals'
import createStore, { sagaMiddleware } from './store/create-store'
import rootSaga from './store/sagas'

import './index.css'
import '@blueprintjs/core/lib/css/blueprint.css'

const store = createStore()
sagaMiddleware.run(rootSaga)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
