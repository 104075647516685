import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { documentState } from 'v2/components/manager/document'

const Header = styled.div`
    display: flex;
    align-items: center;

    span {
        display: inline-block;
        padding: 2px;
        height: 24px;
        background: #fff;
        border-radius: 3px;
        margin-right: 10px;

        img {
            height: 100%;
        }
    }
`

const DocumentHeader = ({ load, ...props }) => {
    const [doc] = useRecoilState(documentState)

    let base64 = _.get(props, 'header.image.base64')
    let src = _.get(props, 'header.image.url', base64)
    let title = _.get(props, 'header.title', '')

    if (load === doc._id) {
        base64 = _.get(doc, 'header.image.base64')
        src = _.get(doc, 'header.image.url', base64)
        title = _.get(doc, 'header.title', '')
    }

    const img = _.isEmpty(src) ? null : (
        <span>
            <img src={src} alt={title} />
        </span>
    )
    return (
        <Header>
            {img}
            {title}
        </Header>
    )
}

export default DocumentHeader
