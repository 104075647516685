import React from 'react'
import { Divider } from '@blueprintjs/core'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { CreateLinkedView } from './create'
import WebLink from '../modules/linker/web'
import ModuleLink from '../modules/linker/modules'
import PhoneLink from '../modules/linker/phone'
import AppLaunchLink from '../modules/linker/appLaunch'

const Body = styled.div`
    padding: 4px;

    display: flex;
    flex-direction: column;
`

const Module = ({
    addNewModule,
    org,
    parent,
    linkModule,
    setPreviewPanel,
    saveModule,
}) => (
    <Body>
        <CreateLinkedView
            onCreate={module => {
                // create the module
                addNewModule(module, id => {
                    // After module is created...
                    // Get the module ID from the server callback, and link and update
                    // device preview
                    const navigation = {
                        module: {
                            id: id,
                            screen: module.screen,
                        },
                    }

                    // Link the module
                    linkModule(
                        parent.type.toUpperCase(),
                        navigation,
                        parent.itemPath,
                        parent
                    )

                    // Update the preview so that it renders in the device in real time!
                    setPreviewPanel({
                        navigation,
                        parent,
                        render: {},
                    })
                })
                // Update the preview panel
            }}
            org={org}
        />
        <Divider />
        <WebLink
            onLink={navigation => {
                // console.log(navigation, parent)
                // Link the module
                linkModule(
                    parent.type.toUpperCase(),
                    navigation,
                    parent.itemPath,
                    parent
                )
                // Update the preview so that it renders in the device in real time!
                setPreviewPanel({
                    navigation,
                    parent,
                    render: {},
                })
            }}
        />
        <Divider />
        <ModuleLink
            parent={parent}
            onLink={navigation => {
                linkModule(
                    parent.type.toUpperCase(),
                    navigation,
                    parent.itemPath,
                    parent
                )
                // Update the preview so that it renders in the device in real time!
                setPreviewPanel({
                    navigation,
                    parent,
                    render: {},
                })
            }}
        />
        <Divider />
        <PhoneLink
            onLink={navigation => {
                // Link the number
                linkModule(
                    parent.type.toUpperCase(),
                    navigation,
                    parent.itemPath,
                    parent
                )
                // Update the preview so that it renders in the device in real time!
                setPreviewPanel({
                    navigation,
                    parent,
                    render: {},
                })
            }}
        />
        <Divider />
        <AppLaunchLink
            onLink={navigation => {
                // Link the app
                linkModule(
                    parent.type.toUpperCase(),
                    navigation,
                    parent.itemPath,
                    parent
                )
                // Update the preview so that it renders in the device in real time!
                setPreviewPanel({
                    navigation,
                    parent,
                    render: {},
                })
            }}
        />
    </Body>
)

const mapStateToProps = (state, ownProps) => ({
    modules: state.modules,
    org: state.navigation.org,
    editing: state.editing,
})

const mapDispatchToProps = dispatch => ({
    loadModule: id => {
        dispatch({ type: 'MODULES/EDIT', payload: id })
    },
    addNewModule: (module, cb) =>
        dispatch({ type: 'MODULES/ADD', payload: module, cb }),
    linkModule: (type, navigation, path, parent) => {
        dispatch({
            type: `MODULES/LINK`,
            payload: {
                type,
                navigation,
                path,
                parent,
            },
        })
    },
    setPreviewPanel: panel => {
        dispatch({
            type: 'PREVIEW_SET_MODULE',
            payload: panel,
        })
    },
    saveModule: id => dispatch({ type: 'MODULES/SAVE', payload: id }),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Module)
