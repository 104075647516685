import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, Classes, Icon } from '@blueprintjs/core'
import styled from 'styled-components'
import uuid from 'uuid/v4'

import { getCanWrite } from 'store/selectors'

const Wrapper = styled.div`
    display: flex;
    justify-content: space-evenly;

    .doc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border: 1px solid #005687;
        background-color: #005687;
        color: white;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: #005687;
        }

        .bp3-icon {
            margin-bottom: 10px;
        }
    }
`

const CreateView = ({ org }) => {
    const [isOpen, setOpen] = useState(false)
    const dispatch = useDispatch()
    const canWrite = useSelector(getCanWrite)
    const _title = 'Create a new view'

    const _onClose = () => setOpen(false)
    const _onOpen = () => setOpen(true)
    const _onCreate = (type) => {
        let title = 'Zap View'
        if (type.endsWith('Dashboard')) {
            title = 'Zap Dashboard'
        }

        let data = {}
        if (type === 'MapView') {
            data = {
                map: {
                    center: {
                        latitude: 34.068734,
                        longitude: -118.445236,
                    },
                    zoom: 13,
                },
                categories: [{
                    id: uuid(),
                    name: 'Default',
                    icon: 0xe3ab,
                    color: '#525252',
                }],
                locations: [],
            }
        }

        const doc = {
            version: 2,
            screen: type,
            org,
            header: {
                title,
                image: {},
            },
            updated: new Date(),
            data,
            body: [],
        }
        dispatch({ type: 'MODULES/ADD', payload: doc })
        _onClose()
    }

    if (!canWrite) {
        return null
    }

    return (
        <React.Fragment>
            <Button outlined fill text={_title} onClick={_onOpen} />

            <Dialog isOpen={isOpen} onClose={_onClose} title={_title}>
                <div className={Classes.DIALOG_BODY}>
                    <Wrapper>
                        <button
                            className="doc"
                            onClick={() => _onCreate('View')}
                        >
                            <Icon icon="control" size={30} />
                            Standard
                        </button>

                        <button
                            className="doc"
                            onClick={() => _onCreate('DataDrivenView')}
                        >
                            <Icon icon="multi-select" size={30} />
                            Data-Driven
                        </button>

                        <button
                            className="doc"
                            onClick={() => _onCreate('MapView')}
                        >
                            <Icon icon="map" size={30} />
                            Map
                        </button>
                    </Wrapper>
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={_onClose}>Close</Button>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    )
}

export default CreateView
