import React from 'react'
import styled from 'styled-components'
import DateFromNow from '../modules/date'

const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

const Label = styled.span`
    font-size: 0.75rem;
    color: #5c7080;
    margin: 4px 0;

    &.revision {
        cursor: pointer;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`

class MetadataView extends React.PureComponent {
    state = {
        idVisible: false
    }

    render() {
        const {
            id,
            updated,
            analytics,
            modules
        } = this.props

        let active = 0
        let removed = 0

        Object.keys(analytics).forEach((moduleId) => {
            // Group analytics totals by module existence
            if (modules[moduleId] || moduleId === id) {
                active += analytics[moduleId]
            }
            else {
                removed += analytics[moduleId]
            }
        })

        const total = active + removed

        return (
            <Body>
                {this._renderRevision()}
                <Wrapper>
                    <Label>updated</Label>
                    <DateFromNow date={updated} prefix="Saved" icon="saved" />
                </Wrapper>
                <Wrapper>
                    <Label>total view impressions</Label>
                    {total.toLocaleString()}
                </Wrapper>
                <Wrapper>
                    <Label>impressions from active views</Label>
                    {active.toLocaleString()}
                </Wrapper>
                <Wrapper>
                    <Label>impressions from removed views</Label>
                    {removed.toLocaleString()}
                </Wrapper>
            </Body>
        )
    }

    _renderRevision() {
        const revision = this.props.rev || '0-none'
        let label = 'revision'
        let value = revision.split('-')[0]

        if (this.state.idVisible) {
            label = 'revision / id'
            value += ' / ' + this.props.id
        }

        return (
            <Wrapper>
                <Label className="revision" onClick={this._toggleId}>
                    {label}
                </Label>
                {value}
            </Wrapper>
        )
    }

    _toggleId = () => {
        this.setState(prevState => ({
            idVisible: !prevState.idVisible
        }))
    }
}

export default MetadataView
