/// Saga state selectors

export const getKeycloak = state => state.keycloak

export const getOrg = state => state.navigation.org

export const getDashboard = state => state.dashboard

export const getEditingModules = state => state.editing

export const getNavigation = state => state.navigation

export const getApiEndpoint = state => state.navigation.api.url

export const getAllModules = state => state.modules

export const getCanWrite = (state) => {
	if (!state.roles || !state.navigation || !state.navigation.org) {
		return false
	}

	if (state.roles.includes('admin')) {
		return true
	}

	return state.roles.includes(state.navigation.org + ':editor')
}
