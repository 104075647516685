import React, { useState } from 'react'
import {
    Dialog,
    Button,
    Classes,
    Intent,
} from '@blueprintjs/core'
import ReactQuill from 'react-quill'
import TurndownService from 'turndown'
import showdown from 'showdown'

import { useContextTools } from 'v2/components/manager/api'

import 'react-quill/dist/quill.snow.css'

const RichTextEditor = ({ initialData, updatePath }) => {
    const [_value, setValue] = useState(initialData)
    const [isOpen, setIsOpen] = useState(false)

    const { updateStyle } = useContextTools()
    const turndownService = new TurndownService()
    const converter = new showdown.Converter()

    const _open = () => setIsOpen(true)
    const _close = () => setIsOpen(false)

    const _save = () => {
        updateStyle(_value, updatePath)
        _close()
    }

    const toolbar = [
        { header: [1, 2, 3, 4, 5, 6, false] },
        'bold',
        'italic',
        'link',
        { list: 'ordered' },
        { list: 'bullet' },
    ]

    return (
        <React.Fragment>
            <Button fill intent="primary" onClick={_open}>
                Edit
            </Button>

            <Dialog
                isOpen={isOpen}
                title="Edit text"
                onOpening={() => setValue(initialData)}
                onClose={_close}
            >
                <div className={Classes.DIALOG_BODY}>
                    <ReactQuill
                        defaultValue={converter.makeHtml(initialData)}
                        onChange={(html) => {
                            setValue(turndownService.turndown(html))
                        }}
                        modules={{ toolbar }}
                        formats={[ 'header', 'bold', 'italic', 'link', 'list', 'bullet' ]}
                    />
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text="Cancel" onClick={_close} />
                        <Button
                            text="Save changes"
                            intent={Intent.PRIMARY}
                            onClick={_save}
                        />
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    )
}

export default RichTextEditor
