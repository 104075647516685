import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    color: rgba(0, 0, 0, 0.9);
    font-size: 0.75rem;

    > strong {
        margin-right: 10px;
    }
`

export const RenderableText = ({ element }) => {
    let label = '[Text]'
    let text = _.get(element, 'data.value', element.defaultValue)

    if (element.style === 'zap__card_youtube') {
        label = '[YouTube]'
        text = 'Please edit in the content section, to the right.'
    }

    else if (element.style === 'zap__card_poll') {
        label = '[Poll]'
        text = 'Please edit in the content section, to the right.'
    }

    return (
        <StyledContainer>
            <strong>
                {label}
            </strong>
            <span>
                {text}
            </span>
        </StyledContainer>
    )
}
