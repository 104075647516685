import React from 'react'
import { Button } from '@blueprintjs/core'
import Heading from '../../../modules/models/title'
import Connected from './connect'

class View extends React.Component {
    state = {
        isOpen: false,
    }
    _handleClose = () => {
        this.setState({ isOpen: false })
    }
    render() {
        const { activePath, id } = this.props
        const { isOpen } = this.state
        return (
            <React.Fragment>
                <Button
                    icon="edit"
                    minimal
                    onClick={() => this.setState({ isOpen: !isOpen })}
                />
                <Connected
                    activePath={activePath}
                    id={id}
                    isOpen={isOpen}
                    handleClose={this._handleClose}
                >
                    {({ data }, updateData) => (
                        <Heading value={data} onChange={updateData} />
                    )}
                </Connected>
            </React.Fragment>
        )
    }
}

View.defaultProps = {
    title: 'Heading',
    description: 'Create a section header.',
    descriptor: {
        type: 'heading',
        data: 'Edit me...',
    },
    iconName: 'pt-icon-widget-header',
}

export default View
