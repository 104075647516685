import React, { useState } from 'react'
import { TextArea } from '@blueprintjs/core'
import { useContextTools } from 'v2/components/manager/api'

const MultiLineEditor = ({ initialData, updatePath }) => {
    const [data, setData] = useState(initialData)
    const { updateStyle } = useContextTools()

    const _onChange = (e) => {
        const value = e.target.value
        setData(value)
        updateStyle(value, updatePath)
    }

    return <TextArea value={data} onChange={_onChange} />
}

export default MultiLineEditor
