import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classname from 'classnames'
import produce from 'immer'
import { useRecoilValue, useRecoilState } from 'recoil'
import _ from 'lodash'
import styled from 'styled-components'
import {
    Dialog,
    Classes,
    Button,
    Intent,
    InputGroup,
    Divider,
    Label,
    Tooltip,
} from '@blueprintjs/core'
import { Document } from 'v2/components/edit/view'
import { documentState, manifestState } from 'v2/components/manager/document'
import { DocumentError } from 'v2/components/edit/error'
import { useDocumentUpdateTools } from 'v2/components/manager/api'

const Wrapper = styled.div`
    overflow: hidden;
    padding: 0 4px;

    .url, .app {
        .selected {
            padding: 0 8px;
            border-radius: 8px;
            border: 2px solid orange;
        }
    }

    .doc {
        margin-right: 4px;
        width: 220px;

        .title {
            margin-top: 20px;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .impressions {
            margin-bottom: 4px;
        }
    }

    .docs {
        width: inherit;
    }

    .from {
        width: 200px;
    }

    .to {
        ul {
            overflow-x: scroll;
            display: inline-flex;
            padding: 0;
            list-style: none;
            width: 100%;

            li {
                padding: 10px;
                margin-right: 10px;
                border-radius: 4px;
                cursor: pointer;

                &.selected {
                    background: #48aff0;
                    &:hover {
                        background: #2b95d6;
                    }
                }

                &.navigation {
                    background: orange;
                }

                &:hover {
                    background: #ebf1f5;
                }
            }
        }
    }
`

const LinkDocs = ({ onLink, currentDoc, navigation, allDocs }) => {
    const [selected, setSelected] = useState(null)
    const docId = _.get(navigation, 'module.id')

    const docs = Object.keys(allDocs).map(key => allDocs[key]).filter(item => (
        item.version === 2
    ))

    return (
        <React.Fragment>
            <h3>Open a View</h3>
            <ul>
                {docs.map((doc) => (
                    <li
                        key={doc._id}
                        onClick={() => setSelected(doc._id)}
                        className={classname({
                            navigation: docId === doc._id,
                            selected: selected === doc._id,
                        })}
                    >
                        <DocumentError doc={doc}>
                            <Document doc={doc} />
                        </DocumentError>
                    </li>
                ))}
            </ul>
            <Button
                intent={Intent.PRIMARY}
                minimal={true}
                disabled={selected === null}
                fill={true}
                text="Link to View"
                onClick={() => {
                    onLink({
                        module: {
                            id: selected,
                        },
                    })
                }}
            />
        </React.Fragment>
    )
}

const LinkWeb = ({ onLink, navigation }) => {
    const initTitle = _.get(navigation, 'url.title')
    const initUrl = _.get(navigation, 'url.url')

    const [uri, setUrl] = useState(initUrl || '')
    const [title, setTitle] = useState(initTitle || '')

    useEffect(() => {
        setUrl(_.get(navigation, 'url.url', ''))
        setTitle(_.get(navigation, 'url.title', ''))
    }, [navigation])

    return (
        <div className={classname({ selected: initTitle !== undefined })}>
            <h3>Open a URL</h3>
            <Label>
                Title
                <InputGroup
                    type="text"
                    maxLength={100}
                    placeholder="UCLA"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </Label>
            <Label>
                URL
                <InputGroup
                    type="text"
                    maxLength={500}
                    placeholder="https://www.ucla.edu"
                    value={uri}
                    onChange={(e) => setUrl(e.target.value)}
                />
            </Label>
            <Button
                intent={Intent.PRIMARY}
                minimal={true}
                disabled={uri === '' || title === ''}
                fill={true}
                text="Link to URL"
                onClick={() => {
                    onLink({ url: { url: uri, title } })
                }}
            />
        </div>
    )
}

LinkWeb.defaultProps = {
    navigation: {
        url: {},
    },
}

const LinkApp = ({ onLink, navigation }) => {
    const initIosUrl = _.get(navigation, 'appLaunch.iosUrl')
    const initAppStoreId = _.get(navigation, 'appLaunch.appStoreId')
    const initAndroidPackage = _.get(navigation, 'appLaunch.androidPackage')

    const [iosUrl, setIosUrl] = useState(initIosUrl || '')
    const [appStoreId, setAppStoreId] = useState(initAppStoreId || '')
    const [androidPackage, setAndroidPackage] = useState(initAndroidPackage || '')

    useEffect(() => {
        setIosUrl(_.get(navigation, 'appLaunch.iosUrl', ''))
        setAppStoreId(_.get(navigation, 'appLaunch.appStoreId', ''))
        setAndroidPackage(_.get(navigation, 'appLaunch.androidPackage', ''))
    }, [navigation])

    const _isAppLinked = () => {
        return [initIosUrl, initAppStoreId, initAndroidPackage].some(x => x !== undefined)
    }

    const _valid = () => {
        return iosUrl || appStoreId || androidPackage
    }

    const _onLink = () => {
        let appLaunch = {}

        if (iosUrl) {
            appLaunch.iosUrl = iosUrl
        }
        if (appStoreId) {
            appLaunch.appStoreId = appStoreId
        }
        if (androidPackage) {
            appLaunch.androidPackage = androidPackage
        }

        onLink({ appLaunch })
    }

    return (
        <div className={classname({ selected: _isAppLinked() })}>
            <h3>Open an App</h3>
            <Label>
                iOS URL
                <InputGroup
                    type="text"
                    maxLength={100}
                    placeholder="example://"
                    value={iosUrl}
                    onChange={(e) => setIosUrl(e.target.value)}
                />
            </Label>
            <Label>
                App Store ID
                <InputGroup
                    type="text"
                    maxLength={100}
                    placeholder="123456789"
                    value={appStoreId}
                    onChange={(e) => setAppStoreId(e.target.value)}
                />
            </Label>
            <Label>
                Android package name
                <InputGroup
                    type="text"
                    maxLength={100}
                    placeholder="com.example.ucla"
                    value={androidPackage}
                    onChange={(e) => setAndroidPackage(e.target.value)}
                />
            </Label>
            <Button
                intent={Intent.PRIMARY}
                minimal={true}
                disabled={!_valid()}
                fill={true}
                text="Link to App"
                onClick={_onLink}
            />
        </div>
    )
}

LinkApp.defaultProps = {
    navigation: {
        appLaunch: {},
    },
}

const Linker = ({ element, tab, allowAppLink }) => {
    const [isOpen, setOpen] = useState(false)
    const currentDoc = useRecoilValue(documentState)
    const [manifest, setManifest] = useRecoilState(manifestState)
    const allDocs = useSelector(state => state.modules)
    const {
        updateElementNavigation,
        deleteElementNavigation,
    } = useDocumentUpdateTools()

    const navigation = tab ? tab.navigation : element.navigation
    const moduleLink = _.get(navigation, 'module.id')
    const urlLink = _.get(navigation, 'url.url')
    const appLink = _.get(navigation, 'appLaunch')
    let appLinkArea = null
    let tooltip
    let icon

    if (moduleLink) {
        if (allDocs[moduleLink]) {
            let title = _.get(allDocs[moduleLink], 'header.title')

            if (!title) {
                title = _.get(allDocs[moduleLink], 'header.hiddenTitle')
            }

            tooltip = 'Links to view "' + title + '"'
            icon = 'document-open'
        }

        else {
            tooltip = 'Links to a missing view!'
            icon = 'warning-sign'
        }
    }

    else if (urlLink) {
        tooltip = 'Links to ' + urlLink
        icon = 'globe-network'
    }

    else if (appLink) {
        tooltip = 'Launches an app'
        icon = 'mobile-phone'
    }

    else {
        tooltip = 'Create a Link'
        icon = 'link'
    }

    const _handleClose = () => {
        setOpen(false)
    }

    const _onLink = (navigation) => {
        if (tab) {
            const nextState = produce(manifest, (draftState) => {
                const index = manifest.body.findIndex(
                    (e) => e.tab.id === tab.tab.id
                )
                draftState.body[index].navigation = navigation
            })
            setManifest(nextState)
        } else {
            updateElementNavigation(element, navigation)
        }

        _handleClose()
    }

    const _onClearLink = () => {
        if (tab) {
            const nextState = produce(manifest, (draftState) => {
                const index = draftState.body.findIndex((e) => e.id === tab.id)
                draftState.body[index].navigation = {}
            })
            setManifest(nextState)
        } else {
            deleteElementNavigation(element)
        }
    }

    if (allowAppLink) {
        appLinkArea = (
            <React.Fragment>
                <div className="app">
                    <LinkApp
                        onLink={_onLink}
                        navigation={navigation}
                    />
                </div>
                <Divider />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Tooltip content={tooltip}>
                <Button
                    active={icon !== 'link'}
                    intent={icon === 'warning-sign' ? Intent.DANGER : null}
                    icon={icon || 'link'}
                    onClick={() => setOpen(true)}
                />
            </Tooltip>
            <Dialog
                className="linker-dialog"
                icon="link"
                onClose={_handleClose}
                title="Linker"
                isOpen={isOpen}
            >
                <div className={Classes.DIALOG_BODY}>
                    <Wrapper>
                        {navigation ? (
                            <React.Fragment>
                                <Button
                                    intent={Intent.PRIMARY}
                                    minimal={true}
                                    fill={true}
                                    text="Clear Link"
                                    onClick={_onClearLink}
                                />
                                <Divider />
                            </React.Fragment>
                        ) : null}

                        <div className="to">
                            <div className="url">
                                <LinkWeb
                                    onLink={_onLink}
                                    navigation={navigation}
                                />
                            </div>
                            <Divider />
                            {appLinkArea}
                            <div className="docs">
                                <LinkDocs
                                    onLink={_onLink}
                                    currentDoc={currentDoc}
                                    navigation={navigation}
                                    allDocs={allDocs}
                                />
                            </div>
                        </div>
                    </Wrapper>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button minimal={true} onClick={_handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    )
}

export default Linker
