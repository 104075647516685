import React from 'react'
import { Tag, Button, InputGroup, Label, Callout } from '@blueprintjs/core'

class Module extends React.Component {
    state = {
        iosScheme: '',
        iosStoreId: '',
        androidPackage: '',
    }

    render() {
        return (
            <Callout title="Launch an app">
                <Label>
                    iOS URL scheme
                    <InputGroup
                        type="text"
                        maxLength={500}
                        placeholder="example"
                        rightElement={<Tag>://</Tag>}
                        value={this.state.iosScheme}
                        onChange={e =>
                            this.setState({
                                iosScheme: e.target.value,
                            })
                        }
                    />
                </Label>
                <Label>
                    App Store ID
                    <InputGroup
                        type="text"
                        maxLength={500}
                        placeholder="123456789"
                        leftElement={<Tag>https://apps.apple.com/app/id</Tag>}
                        value={this.state.iosStoreId}
                        onChange={e =>
                            this.setState({
                                iosStoreId: e.target.value,
                            })
                        }
                    />
                </Label>
                <Label>
                    Android package name
                    <InputGroup
                        type="text"
                        maxLength={500}
                        placeholder="com.example"
                        value={this.state.andoidPackage}
                        onChange={e =>
                            this.setState({
                                androidPackage: e.target.value,
                            })
                        }
                    />
                </Label>
                <Button
                    minimal
                    icon="mobile-phone"
                    disabled={false}
                    fill
                    text="Select this app"
                    onClick={this._submit}
                />
            </Callout>
        )
    }

    _submit = () => {
        this.props.onLink({
            appLaunch: {
                iosUrl: this.state.iosScheme + '://',
                iosStoreUrl: 'https://apps.apple.com/app/id' + this.state.iosStoreId,
                androidUrl: 'android-app://' + this.state.androidPackage
            }
        })
    }
}

export default Module
