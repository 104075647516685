import React from 'react'
import { Callout } from '@blueprintjs/core'

const AppLaunch = ({ iosUrl, iosStoreUrl, androidUrl }) => {
    const pkg = androidUrl.replace('android-app://', '')
    const androidStoreUrl = 'https://play.google.com/store/apps/details?id=' + pkg

    return (
        <Callout icon="mobile-phone" title="App">
            The user&apos;s device will launch an app.<br /><br />

            On iOS, the URL scheme{' '}
            <a href={iosUrl}>
              {iosUrl}
            </a>
            {' '}will be opened if an available app is installed.<br />

            If not, the user will be asked to install{' '}
            <a href={iosStoreUrl} target="_blank">
              {iosStoreUrl}
            </a>
            .<br /><br />

            On Android, the app{' '}
            <a href={androidStoreUrl} target="_blank">
              {pkg}
            </a>
            {' '}will be opened if installed.<br />
            If not, the user will be asked to install it.
        </Callout>
    )
}

export default AppLaunch
