import React, { useState } from 'react'
import {
    Dialog,
    Button,
    Label,
    Menu,
    MenuItem,
    InputGroup,
    Classes,
    Intent,
    Divider,
    TextArea,
} from '@blueprintjs/core'

const TimelineReminderEditor = ({
    isOpen,
    onClose,
    initialData,
    options,
    onChange,
}) => {
    const [value, setValue] = useState(initialData)

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Edit reminder"
            onOpening={() => setValue(initialData)}
        >
            <div className={Classes.DIALOG_BODY}>
                <Label>
                    Type
                    <Menu>
                        {options.map((key) => (
                            <MenuItem
                                key={key}
                                text={key}
                                active={key === value.type}
                                onClick={() =>
                                    setValue({
                                        ...value,
                                        type: key,
                                    })
                                }
                            />
                        ))}
                    </Menu>
                </Label>
                <Divider />
                <Label>
                    Time
                    <InputGroup
                        value={value.time}
                        onChange={(e) => {
                            setValue({ ...value, time: e.target.value })
                        }}
                    />
                </Label>
                <Divider />
                <Label>
                    Title
                    <InputGroup
                        value={value.title}
                        onChange={(e) => {
                            setValue({ ...value, title: e.target.value })
                            console.log(e)
                        }}
                    />
                </Label>
                <Divider />
                <Label>
                    Content
                    <TextArea
                        value={value.text.markdown}
                        fill="true"
                        onChange={(e) => {
                            setValue({
                                ...value,
                                text: {
                                    markdown: e.target.value,
                                },
                            })
                        }}
                        growVertically={true}
                    />
                </Label>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Cancel" onClick={onClose} minimal="true" />
                    <Button
                        text="Save changes"
                        intent={Intent.PRIMARY}
                        minimal="true"
                        onClick={() => {
                            onChange(value)
                            onClose()
                        }}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default TimelineReminderEditor
