import React from 'react'
import { Callout, Tooltip } from '@blueprintjs/core'
import styled from 'styled-components'

const Wrapper = styled.div`
    overflow: scroll;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
`

const Web = ({ url }) => (
    <Wrapper>
        <Callout icon="globe" title="Web link">
            A link to{' '}
            <a href={url.url} target="_blank" rel="noopener noreferrer">
                <Tooltip content={url.url}>{url.title}</Tooltip>
            </a>{' '}
            will be presented to the user in the device. If you do not see a
            preview here it's because the requested URL does not allow embedding in
            an `iframe` for security reasons.
        </Callout>
        <iframe src={url.url} />
    </Wrapper>
)

export default Web
