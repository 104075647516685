import styled from 'styled-components'

export const Body = styled.div`
    display: grid;
    grid-template-columns: 360px 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-column-gap: 20px;
    justify-items: center;
    background: #fff;
    min-heigth: 800px;
    padding: 20px 0 0;
`

export const BodyV2 = styled.div`
    background: #fff;
    min-heigth: 800px;
    padding: 40px 0;
`

export const ContextArea = styled.div`
    position: relative;
    width: 100%;
    background: #5c7080;
    padding: 10px;
`

export const EditingArea = styled.div`
	position: relative;
	padding: 10px;
	background: #5c7080;
	width: 100%;
`

export const ModuleArea = styled.div`
    grid-column: 1 / -1;
    width: 100%;
    margin-top: 10px;
    padding: 10px 20px;
    background: #fff;

    .create {
        width: 200px;
    }
`
