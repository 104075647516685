import React from 'react'
import styled from 'styled-components'
import { Button, Tooltip } from '@blueprintjs/core'
import { useRecoilState } from 'recoil'
import _ from 'lodash'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import produce from 'immer'

import ScreenHeader from 'v2/components/edit/screen/header'
import { EditBackgroundImage } from 'v2/components/edit/menu'
import { RowList } from 'v2/components/edit/screen/dashboard/row'
import { documentState } from 'v2/components/manager/document'
import { pathToID } from 'v2/components/manager/utils'
import {
    useDocumentUpdateTools,
    useDocumentTemplates,
    useContextTools,
} from 'v2/components/manager/api'
import ColorPicker from 'v2/components/widgets/color'
import FooterEditor from 'v2/components/edit/footer'

const StyledDashboard = styled.div`
    .root-doc-attributes {
        width: 100%;
        display: flex;
        align-items: center;

        button {
            margin-right: 20px;
        }
    }

    > .layout {
        margin-top: 10px;

        .editing {
            flex: 1;
            margin-right: 10px;
            /*min-width: 350px;*/
        }

        .empty-body {
            border: 2px dashed #bfccd6;
            padding: 10px;
            height: 150px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
`

const DocumentBody = () => {
    const [doc, setDoc] = useRecoilState(documentState)
    const { deleteFromElementBody, addToElementBody } = useDocumentUpdateTools()
    const {
        createTemplateType,
        duplicateElement,
        isToolTemplate,
    } = useDocumentTemplates()
    const { selectElement } = useContextTools()

    const _onDeleteRow = (index) => {
        selectElement(null)
        deleteFromElementBody(doc, index)
    }

    const _onDuplicateRow = (row) => {
        const duplicate = duplicateElement(row)
        addToElementBody(doc, duplicate)
    }

    const _onAddRow = () => {
        addToElementBody(doc, createTemplateType('LayoutRow'))
    }

    const _onAddDivider = () => {
        addToElementBody(doc, createTemplateType('LayoutDivider'))
    }

    const _onAddLayoutContainer = (element) => {
        addToElementBody(element, createTemplateType('LayoutContainer'))
    }

    const _reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const _onDragEnd = (result) => {
        const { destination, source } = result

        // Check if we got a template a dropped object
        if (isToolTemplate(result.draggableId)) {
            // console.log('we got a template', result)

            if (!destination) {
                return
            }

            const template = createTemplateType(result.draggableId)

            const nextState = produce(doc, (draftState) => {
                // move obj to new location
                const path = pathToID(doc, destination.droppableId)
                const elem = _.get(draftState, path)

                // Add to end, or insert somewhere
                if (destination.index === elem.body.length) {
                    elem.body.push(template)
                } else {
                    elem.body.splice(destination.index, 0, template)
                }

                return draftState
            })

            setDoc(nextState)
            return
        }

        if (!destination) {
            return
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            // console.log('nothing changed', result)
            return
        }

        if (destination.droppableId === 'ROOT') {
            console.log('at ROOT', result)
            const _rows = _reorder(doc.body, source.index, destination.index)

            const newState = produce(doc, (draftState) => {
                draftState.body = _rows
            })
            setDoc(newState)
        } else if (destination.droppableId === source.droppableId) {
            // console.log('same destination', result)
            const nextState = produce(doc, (draftState) => {
                const path = pathToID(doc, destination.droppableId)

                // console.log(path)
                const elem = _.get(draftState, path)

                const _body = _reorder(
                    elem.body,
                    source.index,
                    destination.index
                )

                elem.body = _body
                _.set(draftState, path, elem)

                return draftState
            })

            setDoc(nextState)
        } else {
            // console.log('different destination', result)
            // const { destination, source } = result

            const nextState = produce(doc, (draftState) => {
                // Delete element from source
                let path = pathToID(doc, source.droppableId)
                let elem = _.get(draftState, path)
                // Clone obj
                const _obj = _.clone(elem.body[source.index])
                // delete
                elem.body.splice(source.index, 1)

                // move obj to new location
                path = pathToID(doc, destination.droppableId)
                elem = _.get(draftState, path)

                // Add to end, or insert somewhere
                if (destination.index === elem.body.length) {
                    elem.body.push(_obj)
                } else {
                    elem.body.splice(destination.index, 0, _obj)
                }

                return draftState
            })

            setDoc(nextState)
        }
    }

    return (
        <DragDropContext onDragEnd={_onDragEnd}>
            <StyledDashboard>
                <div className="root-doc-attributes">
                    <ColorPicker
                        initialData={doc.data.backgroundColor}
                        updatePath={'root.data.backgroundColor'}
                    />
                    <EditBackgroundImage element={doc} />
                    <Tooltip content="Creates a row to add content">
                        <Button
                            outlined={true}
                            minimal={true}
                            text="Add Row"
                            onClick={_onAddRow}
                        />
                    </Tooltip>
                    <Tooltip content="Creates a visible divider between rows">
                        <Button
                            outlined={true}
                            minimal={true}
                            text="Add Row Divider"
                            onClick={_onAddDivider}
                        />
                    </Tooltip>
                </div>
                <div className="layout">
                    <div className="editing">
                        <Droppable droppableId="ROOT" type="LAYOUT_ROW">
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {_.isEmpty(doc.body) ? (
                                        <div className="empty-body">
                                            <p>
                                                Your content goes here, but
                                                first you must create a row...
                                            </p>
                                            <Button
                                                minimal={true}
                                                fill={true}
                                                text="Add a layout row"
                                                onClick={_onAddRow}
                                            />
                                        </div>
                                    ) : (
                                        <RowList
                                            onDeleteRow={_onDeleteRow}
                                            onDuplicateRow={_onDuplicateRow}
                                            layoutRows={doc.body}
                                            onAddLayoutContainer={
                                                _onAddLayoutContainer
                                            }
                                            dataDriven={doc.screen.startsWith('DataDriven')}
                                        />
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <div>
                            {_.isEmpty(doc.body) ? null : (
                                <Button
                                    text="Add another row"
                                    outlined={true}
                                    fill={true}
                                    onClick={_onAddRow}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </StyledDashboard>
        </DragDropContext>
    )
}

const ViewEditor = ({
    documentEditTime,
    onDocumentDelete,
    onDocumentSave,
    documentHasEdits,
}) => {
    return (
        <React.Fragment>
            <ScreenHeader
                documentEditTime={documentEditTime}
                onDocumentDelete={onDocumentDelete}
                onDocumentSave={onDocumentSave}
                documentHasEdits={documentHasEdits}
            />
            <DocumentBody />
            <FooterEditor />
        </React.Fragment>
    )
}

export default ViewEditor
