import _ from 'lodash'

const rolesGrantList = [
    {
        role: 'admin',
        resource: 'dashboard',
        action: 'create:any',
        attributes: '*',
    },
    {
        role: 'editor',
        resource: 'dashboard',
        action: 'read:any',
        attributes: '*',
    },
    {
        role: 'editor',
        resource: 'dashboard',
        action: 'create:any',
        attributes: '*',
    },
    {
        role: 'editor',
        resource: 'modules',
        action: 'read:any',
        attributes: '*',
    },
    {
        role: 'editor',
        resource: 'modules',
        action: 'create:any',
        attributes: '*',
    },
]

export const grantsFromRoles = roles => {
    const grants = roles.map(role => {
        return _.filter(rolesGrantList, { role })
    })

    const out = _.flatten(_.compact(grants))
    return out
}
