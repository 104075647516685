import React, { useState } from 'react'
import styled from 'styled-components'
import { Tabs, Tab } from '@blueprintjs/core'

import {
    StyledZapButton,
    StyledZapText,
    StyledZapRichText,
    StyledZapImageFull,
    StyledZapImageBanner,
    StyledZapImageTitleTopButton,
    StyledZapImageTitleBottomButton,
    RenderZapElementBody,
    StyledZapIconTitleBottomButton,
    StyledZapListItem,
    StyledZapListItemImage,
    StyledZapCard,
    StyledZapCardLeftImage,
    StyledZapCardNoImage,
    StyledZapCardEvent,
    StyledZapCardPromo,
    StyledZapCardPoll,
    StyledZapCardYoutube,
} from 'v2/components/widgets/styles'
import { zapStyles } from 'v2/components/widgets/definitions'

const styleRenderers = {
    StyledZapButton,
    StyledZapText,
    StyledZapRichText,
    StyledZapImageFull,
    StyledZapImageBanner,
    StyledZapImageTitleTopButton,
    StyledZapImageTitleBottomButton,
    StyledZapIconTitleBottomButton,
    StyledZapListItem,
    StyledZapListItemImage,
    StyledZapCard,
    StyledZapCardLeftImage,
    StyledZapCardNoImage,
    StyledZapCardEvent,
    StyledZapCardPromo,
    StyledZapCardPoll,
    StyledZapCardYoutube,
}

const Wrapper = styled.div`
    padding: 10px;
    padding-top: 0;
    margin-bottom: 10px;

    ul {
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 8px;
            margin-bottom: 4px;
            padding: 2px 4px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 2px solid transparent;
            border-radius: 2px;
            cursor: pointer;
            min-width: 75px;

            &:hover {
                border-color: #ced9e0;
            }

            &.active {
                border-color: #48aff0;
            }
        }
    }
`

const typeFilters = {
    buttons: (k) => k.includes('__button') || k.includes('__icon'),
    text: (k) => k.includes('__text'),
    images: (k) => k.includes('__image'),
    lists: (k) => k.includes('__list'),
    cards: (k) => k.includes('__card'),
}

const BrowserPanel = ({ category, selectedType, onSelectType }) => {
    const widgets = Object.keys(zapStyles).filter(typeFilters[category])

    return (
        <ul>
            {widgets.map((key) => {
                const style = zapStyles[key]
                const Render = styleRenderers[style.renderer]
                let previewStyle = {}

                if (key.includes('__card')) {
                    previewStyle.maxWidth = '240px'
                }

                return (
                    <li
                        key={key}
                        className={key === selectedType ? 'active' : null}
                        onClick={() => onSelectType(key)}
                    >
                        <Render {...style.defaultData} style={previewStyle}>
                            <RenderZapElementBody
                                elem={style}
                                renderer={style.renderer}
                            />
                        </Render>
                    </li>
                )
            })}
        </ul>
    )
}

const WidgetBrowser = ({ defaultType, onSelectStyle }) => {
    const [ type, setType ] = useState(defaultType)
    let selectedTab

    const _selectType = (t) => {
        setType(t)
        onSelectStyle(zapStyles[t])
    }

    const tabs = ['Buttons', 'Text', 'Images', 'Lists', 'Cards'].map((category) => {
        const panel = (
            <BrowserPanel
                category={category.toLowerCase()}
                selectedType={type}
                onSelectType={_selectType}
            />
        )

        return (
            <Tab
                key={category.toLowerCase()}
                id={category.toLowerCase()}
                title={category}
                panel={panel}
            />
        )
    })

    if (type) {
        Object.keys(typeFilters).forEach((category) => {
            if (typeFilters[category](type)) {
                selectedTab = category.toLowerCase()
            }
        })
    }

    return (
        <Wrapper>
            <Tabs defaultSelectedTabId={selectedTab}>
                {tabs}
            </Tabs>
        </Wrapper>
    )
}

export default WidgetBrowser
