import React from 'react'
import { Callout } from '@blueprintjs/core'

const Phone = ({ phone }) => (
    <Callout icon="phone" title="Phone number">
        The user&apos;s device will dial this number: {phone}
    </Callout>
)

export default Phone
