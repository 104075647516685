import React from 'react'
import _ from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Portal } from '@blueprintjs/core'
import { Map, ZoomControl, Overlay, Draggable } from 'pigeon-maps'
import produce from 'immer'

import { documentState, mapState, scaleState } from 'v2/components/manager/document'
import materialIcons from 'materialIcons'

const MapView = () => {
    const [doc, setDoc] = useRecoilState(documentState)
    const [map, setMap] = useRecoilState(mapState)
    const scale = useRecoilValue(scaleState)
    const locations = _.get(doc, 'data.locations', [])
    const categories = _.get(doc, 'data.categories', [])

    const _updateMap = ({ center, zoom, bounds }) => {
        setMap({
            latitude: center[0],
            longitude: center[1],
            zoom,
            bounds: bounds.ne.reverse().concat(bounds.sw.reverse()),
        })
    }

    const _updateAnchor = (id, coordinates) => {
        const nextState = produce(doc, (draftState) => {
            const [latitude, longitude] = coordinates

            draftState.data.locations.forEach((location) => {
                if (location.id === id) {
                    location.coordinates = {
                        latitude,
                        longitude,
                    }
                }
            })

            return draftState
        })

        setDoc(nextState)
    }

    const _setActive = (id) => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.locations.forEach((location) => {
                location.active = (location.id === id)
            })

            return draftState
        })

        setDoc(nextState)
    }

    let visibleCategories = {}
    categories.forEach((category) => {
        if (!category.hidden) {
            visibleCategories[category.id] = true
        }
    })

    const sortedLocations = locations.filter(location => (
        visibleCategories[location.category] && !location.active
    )).concat(locations.filter(location => (
        visibleCategories[location.category] && location.active
    )))

    const overlays = sortedLocations.map(location => {
        const { latitude, longitude } = location.coordinates
        const category = categories.find(c => c.id === location.category)
        const activeIcon = materialIcons.find(icon => icon.value === category.icon)

        const icon = (
            <span
                className="material-icons"
                style={{
                    padding: '2px',
                    border: '1px solid ' + category.color,
                    borderRadius: '15px',
                    backgroundColor: location.active ? category.color : 'white',
                    color: location.active ? 'white' : category.color,
                    cursor: location.editable ? null : 'pointer',
                    boxShadow: location.active ? ('0 0 3px 3px ' + category.color) : null,
                }}
                onClick={() => !location.active && _setActive(location.id)}
            >
                {activeIcon.label}
            </span>
        )

        if (!location.editable) {
            return (
                <Overlay
                    key={location.id}
                    anchor={[latitude, longitude]}
                    offset={[15, 15]}
                >
                    {icon}
                </Overlay>
            )
        }

        else {
            return (
                <Draggable
                    key={location.id}
                    anchor={[latitude, longitude]}
                    offset={[15, 15]}
                    onDragEnd={(coordinates) => _updateAnchor(location.id, coordinates)}
                >
                    {icon}
                </Draggable>
            )
        }
    })

    return (
        <Portal>
            <div style={{
                position: 'absolute',
                left: (26 * scale + 289) + 'px',
                top: (106 * scale + 64) + 'px',
                width: (375 * scale) + 'px',
                zIndex: 10,
             }}>
                <Map
                    height={632 * scale}
                    center={[map.latitude, map.longitude]}
                    zoom={map.zoom}
                    onBoundsChanged={_updateMap}
                >
                    <ZoomControl />
                    {overlays}
                </Map>
            </div>
        </Portal>
    )
}

export default MapView
