import React from 'react'
import { RecoilRoot, atom } from 'recoil'

const DocumentManager = ({ id, children }) => {
    return <RecoilRoot>{children}</RecoilRoot>
}

const documentState = atom({
    key: '__@@document@@__',
    default: {},
})

const loaderState = atom({
    key: '__@@navigation@@__',
    default: {},
})

const contextState = atom({
    key: '__@@context@@__',
    default: {},
})

const panelStackState = atom({
    key: '__@@panelstack@@__',
    default: [],
})

const manifestState = atom({
    key: '__@@manifest@@__',
    default: {
        _id: '',
        _rev: '',
        body: [],
        data: {},
    },
})

const mapState = atom({
    key: '__@@map@@__',
    default: {
        latitude: 34.068734,
        longitude: -118.445236,
        zoom: 13,
    },
})

const scaleState = atom({
    key: '__@@scale@@__',
    default: 1,
})

export {
    DocumentManager,
    documentState,
    loaderState,
    contextState,
    panelStackState,
    manifestState,
    mapState,
    scaleState,
}
