// Module utitly functions

export const isValidDocument = (navigation, modules) => {
    if (!navigation || !modules) {
        return false
    }

    return (
        navigation.module &&
        navigation.module.id &&
        modules[navigation.module.id]
    )
}

export const isValidUrl = navigation => {
    return (
        navigation &&
        navigation.url &&
        navigation.url.title &&
        navigation.url.url
    )
}

export const isModule = module => {
    return module && module.id
}

export const getModuleHeader = (navigation, modules) => {
    if (isValidDocument(navigation, modules)) {
        const module = modules[navigation.module.id]
        return module.header
    }

    if (isValidUrl(navigation)) {
        return { title: navigation.url.title }
    }

    return { title: 'Untitled' }
}

export const moduleIsLoaded = module => {
    return module && module.body !== undefined && module.body !== null
}

export function humanFileSize(size) {
    var i = Math.floor(Math.log(size) / Math.log(1024))
    return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
    )
}
