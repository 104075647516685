import React from 'react'
import moment from 'moment'
import {
    Icon,
} from '@blueprintjs/core'

export default class DateFromNow extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            dateString: null,
        }
    }

    componentDidMount() {
        const { date } = this.props
        if (date) {
            this.setState({
                dateString: moment(date).fromNow(),
            })

            this.interval = setInterval(() => {
                this.setState({
                    dateString: moment(date).fromNow(),
                })
            }, 1000)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.date) {
            this.setState({
                dateString: moment(nextProps.date).fromNow(),
            })

            if (this.interval) {
                clearInterval(this.interval)
            }

            this.interval = setInterval(() => {
                this.setState({
                    dateString: moment(nextProps.date).fromNow(),
                })
            }, 1000)
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    render() {
        const { prefix, icon } = this.props
        const { dateString } = this.state
        if (dateString) {
            return (
                <div>
                    {icon ? <Icon icon={icon} /> : null} {prefix}{' '}
                    <strong>{this.state.dateString}</strong>
                </div>
            )
        }
        return <div>(unknown)</div>
    }
}

DateFromNow.defaultProps = {
    prefix: '',
    icon: null,
}
