import React, { useState } from 'react'
import { Popover, Button, Icon, Position } from '@blueprintjs/core'
import { SwatchesPicker } from 'react-color'
import styled from 'styled-components'
import { useContextTools } from 'v2/components/manager/api'

const Wrapper = styled.div`
    padding: 4px;
`

const StyledColorPreview = styled.div`
    display: flex;
    justify-content: center;
    span,
    strong {
        margin-right: 4px;
    }
`

const ColorPicker = ({ initialData, updatePath, onUpdateManifest }) => {
    const [color, setColor] = useState({ hex: initialData })

    const { updateStyle, updateRootStyle } = useContextTools()

    const _onChange = (color) => {
        setColor(color)

        if (onUpdateManifest) {
            return onUpdateManifest(color.hex)
        }

        if (updatePath.includes('root.')) {
            updateRootStyle(color.hex, updatePath.replace('root.', ''))
        } else {
            updateStyle(color.hex, updatePath)
        }
    }

    return (
        <Popover
            position={Position.BOTTOM}
            target={
                <Button minimal={true} small={true}>
                    <StyledColorPreview>
                        <Icon
                            icon="full-circle"
                            color={
                                initialData === 'transparent'
                                    ? null
                                    : initialData
                            }
                        />
                        <span>{initialData}</span>
                        <Icon icon="arrow-right" />
                        <Icon icon="full-circle" color={color.hex} />
                        <strong>{color.hex}</strong>
                    </StyledColorPreview>
                </Button>
            }
            content={
                <React.Fragment>
                    <Wrapper>
                        <Button
                            text="Transparent"
                            fill="true"
                            minimal="true"
                            icon={color === 'transparent' ? 'tick' : null}
                            active={color === 'transparent'}
                            onClick={() => {
                                setColor('transparent')
                                _onChange({ hex: 'transparent' })
                                // onChange('transparent')
                            }}
                        />
                    </Wrapper>
                    <SwatchesPicker
                        color={color}
                        onChangeComplete={_onChange}
                    />
                </React.Fragment>
            }
        />
    )
}

export default ColorPicker
