import React from 'react'
import { Icon } from '@blueprintjs/core'

class LockedOverlay extends React.PureComponent {
    render() {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 100,
                width: '100%',
                height: '100%',
                backgroundColor: '#888888',
                opacity: 0.5,
                cursor: 'not-allowed',
            }}>
                <Icon icon="lock" color="black" iconSize={50} />
            </div>
        )
    }
}

export default LockedOverlay
