import React from 'react'
import { FileInput } from '@blueprintjs/core'
import styled from 'styled-components'
import { humanFileSize } from '../../../utils'

const Body = styled.div`
    display: flex;
    flex-direction: column;
`

class Model extends React.Component {
    state = {
        image: null,
        name: 'Choose file...',
        file: null,
        src: '',
    }

    _prepareImage(img) {
        let reader = new FileReader()
        reader.onloadend = () => {
            this.setState({
                image: reader.result,
                name: img.name,
                file: img,
            })
            this.props.updateImage({
                base64: reader.result,
                // file: img,
                name: img.name,
            })
        }
        reader.readAsDataURL(img)
    }

    data() {
        const { file } = this.state

        if (file) {
            let data = new FormData()
            data.append('file', this.state.file)
            return data
        }

        return file
    }

    render() {
        return (
            <Body className="image-upload" style={this.props.style}>
                <FileInput
                    onInputChange={event => {
                        if (event.target.files.length) {
                            if (event.target.files[0].size / 1024 / 1024 < 1) {
                                this._prepareImage(event.target.files[0])
                            } else {
                                alert(
                                    `The uploaded file (${humanFileSize(
                                        event.target.files[0].size
                                    )}) exceeds our 1MB file limit.  Please use a smaller image to keep your app size optimized.`
                                )
                            }
                        }
                    }}
                />
            </Body>
        )
    }
}

Model.defaultProps = {
    style: {},
}

export default Model
