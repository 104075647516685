import React, { useState } from 'react'
import {
    Menu,
    MenuItem,
    Button,
    Tooltip,
    FormGroup,
    InputGroup,
    TextArea,
    Popover,
    Position,
    Intent,
} from '@blueprintjs/core'
import styled from 'styled-components'

import { EditMenuPopover, EditMenu } from 'v2/components/edit/menu'
import materialIcons from 'materialIcons'

const StyledRow = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding: 6px;
    border: 2px solid #aebecd;
    border-radius: 2px;
    background-color: #e1e7ec;
    font-size: 16px;

    &.active {
        border-color: #ffd102;
        box-shadow: 0 0 0 3px rgba(255, 209, 0, 0.25);
    }

    > .left {
        margin-right: 4px;
    }

    .body {
        flex: 1;

        > .content {
            display: flex;
            width: 100%;
            min-height: 50px;
            border-radius: 4px;

            > .empty-row {
                padding-left: 10px;
                padding-top: 8px;
                font-size: 14px;
                color: #5c7080;
            }
        }
    }
`

function DraggableLocation({
    location,
    categories,
    onDeleteLocation,
    onUpdateLocation,
    onAddressSearch,
    onAddressError,
}) {
    const [oldCoords, setOldCoords] = useState(null)
    const [address, setAddress] = useState(location.address)
    let categoryIcons = {}
    let category = categories.find(c => c.id === location.category)
    let controls = null

    if (!category) {
        category = categories[0]
    }

    categories.forEach((c) => {
        const activeIcon = materialIcons.find(icon => (
            icon.value === c.icon
        ))

        categoryIcons[c.id] = activeIcon.label
    })

    const _unlock = () => {
        onUpdateLocation(location.id, { editable: true, active: true })
        setOldCoords(location.coordinates)
    }

    const _confirm = () => {
        onUpdateLocation(location.id, { editable: false, address, active: true })
        setOldCoords(null)
    }

    const _cancel = () => {
        onUpdateLocation(location.id, { editable: false, coordinates: oldCoords, active: true })
        setOldCoords(null)
        setAddress(location.address)
    }

    const _updateAddress = (event) => {
        setAddress(event.currentTarget.value)
    }

    const _updateTitle = (event) => {
        onUpdateLocation(location.id, { title: event.currentTarget.value })
    }

    const _updateDescription = (event) => {
        onUpdateLocation(location.id, { description: event.currentTarget.value })
    }

    const _setActive = () => {
        onUpdateLocation(location.id, { active: true })
    }

    const _search = async () => {
        const result = await onAddressSearch(address)

        if (result) {
            onUpdateLocation(location.id, {
                active: true,
                coordinates: {
                    latitude: result.latitude,
                    longitude: result.longitude,
                },
            })
        }

        else {
            _setActive()
            onAddressError()
        }
    }

    const searchButton = (
        <Tooltip content="Update the marker to a new address">
            <Button
                minimal
                icon="locate"
                intent={Intent.WARNING}
                disabled={oldCoords === null || !address}
                onClick={_search}
            />
        </Tooltip>
    )

    const categoryMenu = (locationId) => {
        const items = categories.map((c) => {
            const icon = (
                <span className="material-icons">
                    {categoryIcons[c.id]}
                </span>
            )

            return (
                <MenuItem
                    key={c.id}
                    icon={icon}
                    text={c.name}
                    style={{ color: c.color }}
                    onClick={() => onUpdateLocation(locationId, { category: c.id })}
                />
            )
        })

        return (
            <Menu>
                {items}
            </Menu>
        )
    }

    if (oldCoords === null) {
        controls = (
            <React.Fragment>
                <Button icon="lock" onClick={_unlock} />
                Click the lock to make changes.
            </React.Fragment>
        )
    }

    else {
        controls = (
            <React.Fragment>
                <Button text="Confirm" onClick={_confirm} />
                <Button text="Cancel" onClick={_cancel} />
            </React.Fragment>
        )
    }

    return (
        <StyledRow
            className={location.active ? 'active' : null}
            onMouseUp={_setActive}
        >
            <div className="left">
                <EditMenuPopover>
                    <EditMenu>
                        <Button
                            minimal
                            text="Delete Location"
                            onClick={() => onDeleteLocation(location.id)}
                        />
                    </EditMenu>
                    <Tooltip content="Configure location">
                        <Button
                            minimal
                            className="row-button"
                            icon="cog"
                        />
                    </Tooltip>
                </EditMenuPopover>
            </div>

            <div className="body">
                <div style={{ display: 'block' }}>
                    <InputGroup
                        fill
                        rightElement={searchButton}
                        disabled={oldCoords === null}
                        value={address}
                        onChange={_updateAddress}
                    />
                    {controls}
                    <hr style={{ width: '100%' }} />

                    <FormGroup
                        label="Title"
                        labelFor="title"
                    >
                        <InputGroup
                            id="title"
                            defaultValue={location.title}
                            onChange={_updateTitle}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Description"
                        labelFor="description"
                    >
                        <TextArea
                            id="description"
                            defaultValue={location.description}
                            onChange={_updateDescription}
                        />
                    </FormGroup>

                    <FormGroup label="Category">
                        <Popover
                            content={categoryMenu(location.id)}
                            position={Position.RIGHT_TOP}
                        >
                            <Button
                                text={categoryIcons[category.id]}
                                className="material-icons"
                                style={{ color: category.color }}
                            />
                        </Popover>
                    </FormGroup>
                </div>
            </div>
        </StyledRow>
    )
}

const LocationList = React.memo(function LocationList({
    locations,
    categories,
    onDeleteLocation,
    onUpdateLocation,
    onAddressSearch,
    onAddressError,
}) {
    return locations.map((location, i) => {
        return (
            <DraggableLocation
                key={location.id}
                location={location}
                categories={categories}
                onDeleteLocation={onDeleteLocation}
                onUpdateLocation={onUpdateLocation}
                onAddressSearch={onAddressSearch}
                onAddressError={onAddressError}
            />
        )
    })
})

export { LocationList }
