import React, { useState, useRef, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import produce from 'immer'
import {
    Dialog,
    Button,
    Classes,
    Intent,
    InputGroup,
    Label,
    FormGroup,
    Divider,
    Callout,
    Tooltip,
} from '@blueprintjs/core'
import uuid from 'uuid/v4'

import { manifestState } from 'v2/components/manager/document'
import { ImageUploader } from 'v2/components/widgets/image'
import Linker from 'v2/components/edit/link'
import ColorPicker from 'v2/components/widgets/color'
import saveDocument from 'v2/components/edit/actions/save'
import { revisionPrettyPrint } from 'v2/components/edit/header'
import { getCanWrite } from 'store/selectors'

const Validator = require('jsonschema').Validator

const ImageViewer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
    }
`

const StyledFooter = styled.div`
    background: #e1e7ec;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;

    .color-editors {
        margin-bottom: 10px;
    }

    .tabs {
        display: flex;
    }

    .tab {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        padding: 4px;
        border: 1px dashed #aebecd;
        border-radius: 4px;
        min-height: 100px;

        margin-right: 4px;

        img {
            max-width: 40px;
            max-height: 40px;
            margin-bottom: 10px;
        }

        .title {
            font-weight: bold;
        }

        &.enabled {
            border: 2px solid #aebecd;
        }
        &:last-child {
            margin-right: 0px;
        }
        .edit-area {
            width: 100%;
        }
    }
`

const FooterTab = ({ title, image }) => {
    let _renderImage = null
    if (image.base64 && image.base64 !== '') {
        _renderImage = <img src={image.base64} alt="Icon" />
    }
    return (
        <React.Fragment>
            {_renderImage}
            <span className="title">{title}</span>
        </React.Fragment>
    )
}

const FooterEditor = () => {
    const token = useSelector((state) => state.keycloak.token)
    const canWrite = useSelector(getCanWrite)
    const [manifest, setManifest] = useRecoilState(manifestState)
    const firstRender = useRef(true)

    useLayoutEffect(() => {
        // Hack to ignore manifest "changes" on initial render
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        saveDocument(token, manifest, (res) => {
            const nextState = produce(manifest, (draftState) => {
                draftState._rev = res.rev
                draftState.updated = new Date()
                return draftState
            })

            setManifest(nextState)
        })
    }, [manifest.body, manifest.data])

    // Only allow 4 elements in footer
    const footerItems = [0, 1, 2, 3]

    const _onSave = (index, tab) => {
        const nextState = produce(manifest, (draftState) => {
            draftState.body[index].tab = tab
        })

        setManifest(nextState)
    }

    const _onCreate = (tab) => {
        const nextState = produce(manifest, (draftState) => {
            draftState.body.push({ tab })
        })
        setManifest(nextState)
    }

    const _onDelete = (index) => {
        const nextState = produce(manifest, (draftState) => {
            draftState.body.splice(index, 1)
        })
        setManifest(nextState)
    }

    const _onUpdateBgColor = (color) => {
        const nextState = produce(manifest, (draftState) => {
            draftState.data.backgroundColor = color
        })
        setManifest(nextState)
    }

    const _onUpdateTextColor = (color) => {
        const nextState = produce(manifest, (draftState) => {
            draftState.data.textColor = color
        })
        setManifest(nextState)
    }

    const _onUpdateHighlightColor = (color) => {
        const nextState = produce(manifest, (draftState) => {
            draftState.data.highlightColor = color
        })
        setManifest(nextState)
    }

    if (!canWrite) {
        return null
    }

    return (
        <StyledFooter>
            <div className="color-editors">
                <div>
                    background color{' '}
                    <ColorPicker
                        initialData={manifest.data.backgroundColor}
                        onUpdateManifest={_onUpdateBgColor}
                    />
                </div>
                <div>
                    text color{' '}
                    <ColorPicker
                        initialData={manifest.data.textColor}
                        onUpdateManifest={_onUpdateTextColor}
                    />
                </div>
                <div>
                    highlight color{' '}
                    <ColorPicker
                        initialData={manifest.data.highlightColor}
                        onUpdateManifest={_onUpdateHighlightColor}
                    />
                </div>
            </div>
            <Divider />
            <div className="tabs">
                {footerItems.map((f) => {
                    if (manifest.body[f]) {
                        return (
                            <div className="tab enabled" key={f}>
                                <FooterTab
                                    title={manifest.body[f].tab.title}
                                    image={manifest.body[f].tab.image}
                                />

                                <div className="edit-area">
                                    <Tooltip content="Edit Tab">
                                        <TabDialog
                                            initialData={manifest.body[f].tab}
                                            onSave={(data) => _onSave(f, data)}
                                        />
                                    </Tooltip>
                                    <Linker tab={manifest.body[f]} />
                                    <Tooltip content="Remove Tab">
                                        <Button
                                            icon="trash"
                                            onClick={() => _onDelete(f)}
                                            minimal={true}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    }

                    return (
                        <div className="tab" key={f}>
                            <span>empty</span>
                            <Tooltip content="Create Tab">
                                <TabDialog
                                    initialData={{
                                        id: uuid(),
                                        title: 'Untitled',
                                        image: { base64: '' },
                                    }}
                                    onSave={_onCreate}
                                />
                            </Tooltip>
                        </div>
                    )
                })}
            </div>
            <div>
                App manifest for <strong>{manifest.app}</strong> version{' '}
                <strong>{revisionPrettyPrint(manifest._rev)}</strong>
            </div>
        </StyledFooter>
    )
}

export const TabDialog = ({ initialData, onSave }) => {
    const schema = {
        id: '/DocHeader',
        type: 'object',
        properties: {
            type: {
                type: 'string',
            },
            title: {
                type: 'string',
                minLength: 0,
                maxLenght: 40,
            },
            image: {
                type: 'object',
                properties: {
                    base64: {
                        type: 'string',
                    },
                },
            },
        },
    }

    const errorMap = {
        'instance.title': 'Header Title',
    }

    const [isOpen, setOpen] = useState(false)

    const [data, setValue] = useState(initialData)
    const [errors, setErrors] = useState([])

    const onClose = () => setOpen(false)

    return (
        <React.Fragment>
            <Button icon="edit" onClick={() => setOpen(true)} minimal={true} />
            <Dialog
                isOpen={isOpen}
                onClose={onClose}
                title="Edit Tab"
                onOpening={() => {
                    setValue(initialData)
                    setErrors([])
                }}
            >
                <div className={Classes.DIALOG_BODY}>
                    {errors.length > 0 ? (
                        <Callout
                            title="There was an error"
                            intent={Intent.WARNING}
                        >
                            <ul>
                                {errors.map((err, k) => (
                                    <li key={k}>
                                        <div>
                                            <strong>
                                                {errorMap[err.property]}
                                            </strong>
                                        </div>
                                        <div>{err.stack}</div>
                                    </li>
                                ))}
                            </ul>
                        </Callout>
                    ) : null}
                    <FormGroup
                        intent={Intent.PRIMARY}
                        label="Title"
                        helperText="Title of tab"
                        labelFor="text-input-title"
                    >
                        <InputGroup
                            id="text-input-title"
                            value={data.title}
                            onChange={(e) => {
                                const title = e.target.value
                                setValue({ ...data, title })
                            }}
                        />
                    </FormGroup>
                    <Divider />
                    <Label>Tab Icon</Label>
                    {data.image.base64 !== '' ? (
                        <ImageViewer>
                            <img src={data.image.base64} alt="Tab Icon" />
                            <Button
                                onClick={() =>
                                    setValue({ ...data, image: { base64: '' } })
                                }
                                text="Clear image"
                                icon="trash"
                            />
                        </ImageViewer>
                    ) : null}
                    <ImageUploader
                        onChange={(base64) =>
                            setValue({ ...data, image: { base64 } })
                        }
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            text="Cancel"
                            onClick={onClose}
                            minimal="true"
                        />
                        <Button
                            text="Save changes"
                            intent={Intent.PRIMARY}
                            minimal="true"
                            onClick={() => {
                                const v = new Validator()
                                const res = v.validate(data, schema)

                                setErrors(res.errors)

                                if (res.valid) {
                                    onSave(data)
                                    onClose()
                                }
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    )
}

export default FooterEditor
