import React from 'react'
import { connect } from 'react-redux'
import GoogleFontLoader from 'react-google-font-loader'

import { DocumentManager } from 'v2/components/manager/document'
import ManifestLoader from 'v2/components/manager/manifest'
import { AllDocuments } from 'v2/components/edit/view'
import DocumentPreview from 'v2/components/preview/document'
import DocumentEditor from 'v2/components/edit/document'
import ContextTools from 'v2/components/edit/tools'
import { googleFontList } from 'v2/components/widgets/gfonts'
import Toaster from '../../components/toaster'

const Main = ({ navigation }) => {
    const { messages, org } = navigation

    return (
        <DocumentManager>
            <Toaster messages={messages} />
            <ManifestLoader>
                <GoogleFontLoader fonts={googleFontList} />
                <AllDocuments org={org} />
                <DocumentPreview />
                <DocumentEditor />
                <ContextTools />
            </ManifestLoader>
        </DocumentManager>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        navigation: state.navigation,
    }
}

export default connect(mapStateToProps)(Main)
