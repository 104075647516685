import React, { useState } from 'react'
import { Checkbox } from '@blueprintjs/core'

import { useContextTools } from 'v2/components/manager/api'

const BoxShadowEditor = ({ initialData, updatePath }) => {
    const [ data, setData] = useState(initialData)
    const { updateStyle } = useContextTools()

    const _onChange = (e) => {
        // Hardcode this for now, maybe allow options in the future
        const value = e.target.checked ? '0 4 8 #00000029' : null
        setData(value)
        updateStyle(value, updatePath)
    }

    return (
        <Checkbox
            label="Enabled"
            defaultChecked={!!initialData}
            onChange={_onChange}
        />
    )
}

export default BoxShadowEditor
