import _ from 'lodash'

const _pathToID = (item, id, path) => {
    if (_.isEmpty(item)) {
        return null
    }

    if (item.id === id) {
        return path
    }
    if (_.isEmpty(item.body)) {
        return null
    }

    path.push('body')
    for (var i = 0; i < item.body.length; i++) {
        path.push(`[${i}]`)
        const res = _pathToID(item.body[i], id, [...path])
        if (res != null) {
            return res
        }
        path.pop()
    }
    return null
}

export const pathToID = (editable, id) => {
    const path = []
    path.push('body')

    for (var i = 0; i < editable.body.length; i++) {
        path.push(`[${i}]`)
        const _path = _pathToID(editable.body[i], id, [...path])

        if (!_.isEmpty(_path)) {
            return _path.join('.')
        }
        path.pop()
    }
}

export const pathToIDArray = (path) => {
    const paths = path.split('.')
    paths.pop()
    return paths.join('.')
}
