import React, { useState, useEffect } from 'react'
import { Spinner, Callout } from '@blueprintjs/core'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { manifestState } from 'v2/components/manager/document'
import config from '../../../config'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px;

    > .spacer {
        margin-bottom: 40px;
    }
`

const ManifestLoader = ({ children }) => {
    const [isLoading, setLoading] = useState(true)
    const { org } = useSelector((state) => state.navigation)

    const [, setManifest] = useRecoilState(manifestState)

    useEffect(() => {
        if (org) {
            const url = `${config.nonSocketsApi.url}/apps/${org}/manifest`

            fetch(url)
                .then((res) => res.json())
                .then((payload) => {
                    setManifest(payload)
                    setLoading(false)
                })
        }
    }, [org])

    if (isLoading) {
        return (
            <Wrapper>
                <Spinner />
                <div className="spacer" />
                <Callout>
                    Trying to load the app manifest. If this is taking too long,
                    ask an administrator to check that there is an app manifest
                    for <strong>{org}</strong> org.
                </Callout>
            </Wrapper>
        )
    }

    return <React.Fragment>{children}</React.Fragment>
}

export default ManifestLoader
