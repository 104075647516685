/* eslint-disable */
import React from 'react'
import { Icon } from '@blueprintjs/core'
import styled from 'styled-components'

const defaultStyle = {
    // maxWidth: '100px',
    // maxHeight: '100px',
    margin: '20px',
}

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
`
const IconWrapper = styled.div`
    padding: 10px;
    width: 100px;
    display: flex;
    align-items: center;
    align-content: center;
`

const Model = ({ url, base64, style = defaultStyle, ...rest }) => {
    let image = base64 || url || null

    if (image) {
        return (
            <ImageWrapper>
                <img style={style} src={image} />
            </ImageWrapper>
        )
    }
    return (
        <IconWrapper>
            <Icon icon="media" color="#5C7080" iconSize={36} />
        </IconWrapper>
    )
}

export default Model
