import React from 'react'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

const StyledContainer = styled.div`
    display: flex;
    color: rgba(0, 0, 0, 0.9);
    font-size: 0.75rem;

    > strong {
        margin-right: 10px;
    }

    > span {
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow-y: hidden;

        h1, h2, h3, p {
            margin: 0;
        }
    }
`

export const RenderableRichText = ({ element }) => {
    const text = _.get(element, 'data.value', element.defaultValue)

    return (
        <StyledContainer>
            <strong>
                [Rich Text]
            </strong>
            <span>
                <ReactMarkdown skipHtml children={text} />
            </span>
        </StyledContainer>
    )
}
