import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@blueprintjs/core'

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`
const Loading = () => (
    <LoadingContainer>
        <Spinner size={Spinner.SIZE_STANDARD} />
    </LoadingContainer>
)

export default Loading
