import React from 'react'
import { connect } from 'react-redux'
import { Intent, Dialog, Button, Classes, Callout } from '@blueprintjs/core'

class View extends React.Component {
    state = {
        isOpen: false,
    }
    _handleClose = () => {
        this.setState({ isOpen: false })
    }
    render() {
        const { preview, id, rev } = this.props
        const { deleteModule, onDelete } = this.props
        const { isOpen } = this.state

        let isEditing = false
        if (preview.navigation && preview.navigation.module) {
            isEditing = id === preview.navigation.module.id
        }

        return (
            <React.Fragment>
                <Button
                    icon="trash"
                    text="Delete document"
                    minimal
                    intent={Intent.DANGER}
                    onClick={() => this.setState({ isOpen: true })}
                />
                <Dialog
                    isOpen={isOpen}
                    usePortal={true}
                    lazy={true}
                    title={`Delete view?`}
                    onClose={this._handleClose}
                >
                    <div className={Classes.DIALOG_BODY}>
                        {isEditing ? (
                            <Callout intent={Intent.WARNING}>
                                You are currently editing this view. You cannot
                                delete it.
                            </Callout>
                        ) : (
                            `Are you sure you want to delete this document?  The data will be lost.`
                        )}
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button minimal onClick={this._handleClose}>
                                Cancel
                            </Button>
                            {isEditing ? null : (
                                <Button
                                    intent={Intent.DANGER}
                                    minimal
                                    onClick={() => {
                                        // Close ourselves
                                        this._handleClose()
                                        // Close the preview
                                        onDelete()
                                        // Do delete...
                                        deleteModule(id, rev)
                                    }}
                                    text="Delete"
                                />
                            )}
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    preview: state.preview,
})

const mapDispatchToProps = dispatch => ({
    deleteModule: (id, rev) =>
        dispatch({ type: 'MODULES/DELETE', payload: { id, rev } }),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(View)
