import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useRecoilState } from 'recoil'
import { socket } from 'store/socket'
import RenderLoading from 'v2/components/preview/render/loading'
import View from 'v2/components/preview/render/view'
import MapView from 'v2/components/preview/render/map'
import RenderError from 'v2/components/preview/render/error'
import { documentState, mapState } from 'v2/components/manager/document'
import ModuleRenderer from '../../../components/document/render'

const renderers = {
    Dashboard: View,
    View: View,
    DataDrivenDashboard: View,
    DataDrivenView: View,
    MapView: MapView,
}

const DocumentLoader = ({ load, ...props }) => {
    // console.log('document loader', load)
    const [isLoading, setLoading] = useState(true)
    const token = useSelector((state) => state.keycloak.token)

    const [doc, setDocument] = useRecoilState(documentState)
    const [map, setMap] = useRecoilState(mapState)

    useEffect(() => {
        setLoading(true)
        socket.emit('v2/GET_SINGLE_DOC', {
            id: load,
            token: token,
        })
    }, [])

    useEffect(() => {
        const listener = (payload) => {
            console.log('--------received reply', payload)
            setDocument(payload)
            setLoading(false)

            if (payload.screen === 'MapView') {
                const mapCenter = _.get(payload, 'data.map.center', {
                    latitude: 34.068734,
                    longitude: -118.445236,
                })

                setMap({
                    latitude: mapCenter.latitude,
                    longitude: mapCenter.longitude,
                    zoom: _.get(payload, 'data.map.zoom', 13),
                })
            }
        }

        // Listen to document reply...
        socket.on('v2/SET_SINGLE_DOC', listener)

        // @note: Memory leak fixed: remove socket listener after we're unmounted
        // or we'll see warnings about trying to setState of
        // umounted component.
        return () => socket.off('v2/SET_SINGLE_DOC', listener)
    }, [])

    if (isLoading || _.isEmpty(doc)) {
        return <RenderLoading />
    }

    if (doc.version === 2) {
        const View = renderers[doc.screen]

        if (View === undefined) {
            return (
                <div>
                    <div>There is no defined renderer for {doc.screen}</div>
                    <pre>{JSON.stringify(doc, null, 2)}</pre>
                </div>
            )
        }

        return (
            <RenderError>
                <View {...props} id={load} />
            </RenderError>
        )
    }

    return <ModuleRenderer module={{ id: doc._id }} />
}

export default DocumentLoader
