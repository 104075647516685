import React from 'react'
import { connect } from 'react-redux'
import { PanelStack, Callout, Intent } from '@blueprintjs/core'
import { AccessControl } from 'accesscontrol'
import _ from 'lodash'
import { Redirect } from '@reach/router'
import Toaster from '../toaster'
import DevicePreview from '../device/index'
import DashboardPreviewPanel from '../dashboard/preview/panel'
import Dashboard from '../dashboard/index'
import { Body, BodyV2, EditingArea, ContextArea, ModuleArea } from './styled'
import DashboardLinkContext from '../dashboard/context'
import DashboardEdit from '../dashboard/reorder'
import DocumentEdit from '../document/edit/index'
import DocumentLinkContext from '../document/context'
import AllDocumentView from '../document/all'
import Footer from './footer'
import { grantsFromRoles } from '../../roles'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true })
        // You can also log the error to an error reporting service
        console.error(error, info)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>
        }
        return this.props.children
    }
}

class IndexPage extends React.Component {
    constructor(props) {
        super(props)

        this.initialDevicePanel = {
            component: DashboardPreviewPanel,
            props: {
                parent: { type: 'root', itemPath: 0 },
                navigation: {},
                render: {},
            },
            title: 'Dashboard',
        }

        this.state = {
            devicePanelStack: [this.initialDevicePanel],
        }

        this.ref = React.createRef()

        // User roles
        const rolesGrantList = grantsFromRoles(
            props.keycloak.tokenParsed.realm_access.roles
        )
        this.ac = new AccessControl(rolesGrantList)
        this.ac.grant(props.keycloak.tokenParsed.realm_access.roles)
    }

    addToDevicePanelStack = (newPanel) => {
        const { setPreviewPanel } = this.props
        setPreviewPanel(newPanel.props)
        this.setState((state) => ({
            devicePanelStack: [newPanel, ...state.devicePanelStack],
        }))
    }

    removeFromDevicePanelStack = (_lastPanel) => {
        // In this example, the last panel is always the one closed.
        // Using `this.props.closePanel()` is one way to violate this.
        // console.log(_lastPanel)
        const { setPreviewPanel } = this.props

        this.setState((state) => {
            const _state = state.devicePanelStack.slice(1)
            let props = _state[0].props

            // If the previous panel is empty, we can't use it directly.
            // Instead, grab the parent ID from the module we just popped.
            if (_.isEmpty(props.navigation)) {
                const popped = state.devicePanelStack[0]
                const parentId = _.get(popped, 'props.parent.id')

                if (parentId) {
                    props.navigation = {
                        module: {
                            id: parentId,
                        },
                    }
                }
            }

            setPreviewPanel(props)
            return {
                devicePanelStack: _state,
            }
        })
    }

    _renderContext() {
        const {
            dashboard,
            preview: { parent },
        } = this.props
        switch (parent.type) {
            case 'root':
                return <Dashboard layoutLocked={dashboard.layoutLocked} />
            case 'dashboard':
                return (
                    <ErrorBoundary>
                        <DashboardLinkContext dashboard={dashboard} />
                    </ErrorBoundary>
                )
            case 'module':
                return (
                    <ErrorBoundary>
                        <DocumentLinkContext />
                    </ErrorBoundary>
                )
            default:
                return (
                    <Callout intent={Intent.DANGER}>
                        unsupported context
                    </Callout>
                )
        }
    }

    _renderEditing() {
        const {
            preview: { parent, navigation },
        } = this.props
        switch (parent.type) {
            case 'root':
                return <DashboardEdit />
            case 'dashboard':
            case 'module':
                return <DocumentEdit navigation={navigation} />
            default:
                return <div>unsupported edit</div>
        }
    }

    render() {
        const {
            navigation,
            modules,
            editing,
            getModule,
            addNewModule,
            analytics,
        } = this.props
        const { messages, org } = navigation

        let editPermission = { granted: false }
        try {
            editPermission = this.ac.can('editor').createAny('dashboard')
        } catch (e) {
            // Permission not found
            console.warn(e)
        }

        return (
            <React.Fragment>
                {!editPermission.granted ? (
                    <Callout title="Read only mode" icon="warning-sign">
                        You are viewing the site in read only mode. You wil only
                        be able to view the app preview. If you require editing
                        permissions contact Zap support and request an{' '}
                        <strong>editor</strong> role.
                    </Callout>
                ) : null}
                <Body role="main">
                    <Toaster messages={messages} />
                    {editPermission.granted ? (
                        <ContextArea>{this._renderContext()}</ContextArea>
                    ) : (
                        <div></div>
                    )}
                    <DevicePreview>
                        <PanelStack
                            ref={this.ref}
                            className="docs-panel-stack-example"
                            initialPanel={this.state.devicePanelStack[0]}
                            onOpen={this.addToDevicePanelStack}
                            onClose={this.removeFromDevicePanelStack}
                        />
                    </DevicePreview>
                    {editPermission.granted ? (
                        <EditingArea>{this._renderEditing()}</EditingArea>
                    ) : (
                        <div></div>
                    )}
                    {editPermission.granted ? (
                        <ModuleArea>
                            <h3>View management</h3>
                            <div className="create"></div>
                            <h3>
                                All available views{' '}
                                <small>({Object.keys(modules).length})</small>
                            </h3>
                            <AllDocumentView
                                modules={modules}
                                editing={editing}
                                getModule={getModule}
                                analytics={analytics}
                            />
                        </ModuleArea>
                    ) : null}
                </Body>
                <Footer />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        keycloak: state.keycloak,
        navigation: state.navigation,
        editing: state.editing,
        modules: state.modules,
        dashboard: state.dashboard,
        preview: state.preview,
        analytics: state.analytics,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewModule: (module) =>
            dispatch({ type: 'MODULES/ADD', payload: module }),
        getModule: (id) => {
            dispatch({ type: 'MODULES/GET', payload: id })
        },
        setPreviewPanel: (panel) => {
            dispatch({
                type: 'PREVIEW_SET_MODULE',
                payload: panel,
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
