import React from 'react'
import { connect } from 'react-redux'
import { Icon } from '@blueprintjs/core'
import _ from 'lodash'
import {
    Item,
    NoImage,
    Footer,
    Items,
    Image,
} from './styled'
import Document from '../../document/index'
import { RenderBody } from '../../document/renderer/body'
import { RenderHeader } from '../../modules/header'
import { getModuleHeader, isValidDocument } from '../../../utils'
import styled from 'styled-components'

const DashboardButton = styled.button`
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 14px;
`

const InlineName = styled.div`
    font-weight: 500;
    font-size: 24px;
    text-align: left;
    margin-left: 8px;
`

class Panel extends React.PureComponent {
    render() {
        let style = {}
        const items = this.props.dashboard.items.map((item, key) => (
            this._renderItem(item, key)
        ))

        // Normally the CSS class is the name of the layout
        let layoutClass = this.props.dashboard.layout

        // But for app-specific layouts, the class is the org
        if (layoutClass === 'app-specific') {
            layoutClass = this.props.dashboard.org
        }

        // If any items have a background property, set it as the bg
        const background = this.props.dashboard.items.find(item => item.background)
        if (background && background.image && background.image.base64) {
            style.backgroundImage = 'url(' + background.image.base64 + ')'
        }

        return (
            <div>
                <Items className={layoutClass} style={style}>
                    {items}
                </Items>
                {this._renderFooter(layoutClass)}
            </div>
        )
    }

    _renderItem(item, key) {
        if (item.background) {
            return null
        }

        let className = 'dashboard-item'

        if (item.theme) {
            className += ' ' + item.theme
        }

        if (item.nonEditable) {
            className += ' non-editable'

            return (
                <Item key={key} className={className}>
                    {this._getItemContent(item, key)}
                </Item>
            )
        }

        else {
            return (
                <Item key={key} className={className}>
                    <DashboardButton
                        onClick={() => {
                            const header = getModuleHeader(
                                item.navigation,
                                this.props.modules
                            )
                            const title = <RenderHeader header={header} />

                            this.props.openPanel({
                                component: Document,
                                props: {
                                    parent: {
                                        type: 'dashboard',
                                        itemPath: key,
                                    },
                                    navigation: item.navigation,
                                    render: {
                                        selectedTabId: 0,
                                    }
                                },
                                title,
                            })
                        }}
                    >
                        {this._getItemContent(item, key)}
                    </DashboardButton>
                </Item>
            )
        }
    }

    _renderFooter(layoutClass) {
        const footer = this.props.dashboard.footer

        // Custom footer image
        if (footer && footer.image && footer.image.base64) {
            return (
                <Footer className={'footer ' + layoutClass}>
                    <img src={footer.image.base64} />
                </Footer>
            )
        }

        else if (footer && Array.isArray(footer)) {
            const items = footer.map((item, i) => (
                <div
                    key={i}
                    className={_.isEmpty(item.navigation) ? 'icon disabled' : 'icon'}
                    onClick={() => this._navigateTo(item)}
                >
                    <img src={item.image.base64} /><br />
                    {item.name}
                </div>
            ))
            return (
                <Footer className={'footer custom ' + layoutClass}>
                    {items}
                </Footer>
            )
        }

        else {
            return (
                <Footer className="footer">
                    <div className="icon" />
                    <div className="icon" />
                    <div className="icon" />
                    <div className="icon" />
                </Footer>
            )
        }
    }

    _getItemContent(item, key) {
        // For inline modules, render content here
        if (
            isValidDocument(item.navigation, this.props.modules) &&
            item.navigation.inline
        ) {
            const module = this.props.modules[item.navigation.module.id]

            return (
                <React.Fragment>
                    <InlineName>
                        {item.name}
                    </InlineName>
                    <RenderBody module={module} body={module.body} inline />
                </React.Fragment>
            )
        }

        else if (item.image.base64) {
            return (
                <React.Fragment>
                    <Image>
                        <img src={item.image.base64} alt='Dashboard Icon' />
                    </Image>
                    {item.name}
                </React.Fragment>
            )
        }

        else {
            return (
                <React.Fragment>
                    <NoImage>
                        <Icon icon="media" iconSize={90} />
                    </NoImage>
                    {item.name}
                </React.Fragment>
            )
        }
    }

    _navigateTo(item) {
        if (_.isEmpty(item.navigation)) {
            return
        }

        const header = getModuleHeader(item.navigation, this.props.modules)
        const title = (
            <RenderHeader header={header} />
        )

        this.props.openPanel({
            component: Document,
            props: {
                parent: {
                    type: 'dashboard',
                },
                navigation: item.navigation,
                render: {
                    selectedTabId: 0,
                },
            },
            title,
        })
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        dashboard: state.dashboard,
        modules: state.modules,
    }
}

const mapDispatchToProps = dispatch => ({
    loadModule: payload => {
        dispatch({ type: 'MODULES/EDIT', ...payload })
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Panel)
