import React, { useState } from 'react'
import _ from 'lodash'
import { useRecoilState } from 'recoil'
import {
    Button,
    Dialog,
    InputGroup,
    Tooltip,
    Intent,
    Alert,
    Checkbox,
    Popover,
    Position,
    Menu,
    MenuItem,
} from '@blueprintjs/core'
import styled from 'styled-components'
import uuid from 'uuid/v4'
import produce from 'immer'

import config from 'config'
import { documentState, mapState } from 'v2/components/manager/document'
import ScreenHeader from 'v2/components/edit/screen/header'
import { CategoryList } from 'v2/components/edit/screen/dashboard/category'
import { LocationList } from 'v2/components/edit/screen/dashboard/location'
import materialIcons from 'materialIcons'

const CategoryFilters = styled.div`
    > button {
        margin: 0 15px 15px 0;
    }

    .bp3-checkbox {
        margin-bottom: 0;

        .bp3-control-indicator {
            margin-bottom: 20px;
        }
    }
`

const CategoriesDialog = ({ isOpen, onClose }) => {
    const [doc, setDoc] = useRecoilState(documentState)
    let categories = doc.data.categories
    let usedCategories = {}

    doc.data.locations.forEach((location) => {
        usedCategories[location.category] = true
    })

    const _onAddCategory = () => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.categories.unshift({
                id: uuid(),
                name: '',
                icon: 0xe3ab,
                color: '#525252',
            })

            return draftState
        })

        setDoc(nextState)
    }

    const _onDeleteCategory = (id) => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.categories = doc.data.categories.filter(
                category => category.id !== id
            )

            return draftState
        })

        setDoc(nextState)
    }

    const _onUpdateCategory = (id, updates) => {
        const nextState = produce(doc, (draftState) => {
            if (updates.hasOwnProperty('icon')) {
                updates.icon = parseInt(updates.icon, 10)
            }

            draftState.data.categories.forEach((category) => {
                if (category.id === id) {
                    Object.keys(updates).forEach((key) => {
                        category[key] = updates[key]
                    })
                }
            })

            return draftState
        })

        setDoc(nextState)
    }

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Categories"
        >
            <div style={{ padding: '15px 15px 0 15px' }}>
                <Button
                    fill
                    text="Add Category"
                    onClick={_onAddCategory}
                />
                <CategoryList
                    categories={categories}
                    usedCategories={usedCategories}
                    onAddCategory={_onAddCategory}
                    onDeleteCategory={_onDeleteCategory}
                    onUpdateCategory={_onUpdateCategory}
                />
            </div>
        </Dialog>
    )
}

const DocumentBody = () => {
    const [isDialogOpen, setDialogOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [doc, setDoc] = useRecoilState(documentState)
    const [map, setMap] = useRecoilState(mapState)
    const [alertOpen, setAlertOpen] = useState(false)
    let categories = doc.data.categories
    let categoryIcons = {}

    categories.forEach((category) => {
        const activeIcon = materialIcons.find(icon => (
            icon.value === category.icon
        ))

        categoryIcons[category.id] = activeIcon.label
    })

    const _saveMap = () => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.map = {
                center: {
                    latitude: map.latitude,
                    longitude: map.longitude,
                },
                zoom: map.zoom,
            }

            return draftState
        })

        setDoc(nextState)
    }

    const _resetMap = () => {
        const mapCenter = _.get(doc, 'data.map.center', {
            latitude: 34.068734,
            longitude: -118.445236,
        })

        setMap({
            latitude: mapCenter.latitude,
            longitude: mapCenter.longitude,
            zoom: _.get(doc, 'data.map.zoom', 13),
        })
    }

    const _onDeleteLocation = (id) => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.locations = doc.data.locations.filter(
                location => location.id !== id
            )

            return draftState
        })

        setDoc(nextState)
    }

    const _onUpdateLocation = (id, updates) => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.locations.forEach((location) => {
                if (location.id === id) {
                    Object.keys(updates).forEach((key) => {
                        location[key] = updates[key]
                    })
                }

                else if (updates.hasOwnProperty('active')) {
                    location.active = false
                }
            })

            return draftState
        })

        setDoc(nextState)
    }

    const _onToggleCategory = (id) => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.categories.forEach((category) => {
                if (category.id === id) {
                    category.hidden = !category.hidden
                }
            })

            return draftState
        })

        setDoc(nextState)
    }

    const _onAddressSearch = async (address) => {
        const coords = address.split(',')

        // If address looks like a lat/long, just use it as-is
        if (coords.length === 2) {
            const searchLat = parseFloat(coords[0], 10)
            const searchLong = parseFloat(coords[1], 10)

            if (_.isFinite(searchLat) && _.isFinite(searchLong)) {
                return {
                    latitude: searchLat,
                    longitude: searchLong,
                }
            }
        }

        // Otherwise, do a lookup
        let url = 'https://nominatim.openstreetmap.org/search?'
        url += 'format=json&limit=1&email='
        url += config.email + '&q=' + encodeURIComponent(address)
        url += '&viewbox=' + map.bounds.join(',')

        try {
            const response = await fetch(url)

            if (response.ok) {
                const json = await response.json()

                if (json.length > 0) {
                    const lat = parseFloat(json[0].lat, 10)
                    const long = parseFloat(json[0].lon, 10)

                    setMap({
                        latitude: lat,
                        longitude: long,
                        zoom: map.zoom
                    })

                    return {
                        latitude: lat,
                        longitude: long,
                    }
                }
            }
        }

        catch (err) {
        }

        return null
    }

    const _onAddressError = () => {
        setAlertOpen(true)
    }

    const _updateSearch = (event) => {
        setSearch(event.currentTarget.value)
    }

    const _search = async (categoryId) => {
        let result

        if (!categoryId) {
            if (categories.length === 1) {
                categoryId = categories[0].id
            }
            else {
                return
            }
        }

        if (search) {
            result = await _onAddressSearch(search)

            if (!result) {
                _onAddressError()
                return
            }
        }

        const nextState = produce(doc, (draftState) => {
            if (!draftState.data.locations) {
                draftState.data.locations = []
            }

            draftState.data.locations.forEach((location) => {
                location.active = false
            })

            draftState.data.locations.unshift({
                id: uuid(),
                address: search || (map.latitude + ', ' + map.longitude),
                title: '',
                description: '',
                category: categoryId,
                active: true,
                coordinates: search ? {
                    latitude: result.latitude,
                    longitude: result.longitude,
                } : {
                    latitude: map.latitude,
                    longitude: map.longitude,
                },
            })

            return draftState
        })

        setDoc(nextState)
        setSearch('')
    }

    const _checkAll = () => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.categories.forEach((category) => {
                category.hidden = false
            })

            return draftState
        })

        setDoc(nextState)
    }

    const _uncheckAll = () => {
        const nextState = produce(doc, (draftState) => {
            draftState.data.categories.forEach((category) => {
                category.hidden = true
            })

            return draftState
        })

        setDoc(nextState)
    }

    const categoryMenuItems = categories.map((category) => {
        const icon = (
            <span style={{ color: category.color }}>
                {categoryIcons[category.id]}
            </span>
        )

        return (
            <MenuItem
                key={category.id}
                text={icon}
                onClick={() => _search(category.id)}
            />
        )
    })

    let searchButton = (
        <Tooltip content={'Place a marker at ' + (search ? 'an address' : 'the map center')}>
            <Button
                minimal
                icon={search ? 'locate' : 'add'}
                intent={Intent.WARNING}
                onClick={() => _search()}
            />
        </Tooltip>
    )

    if (categories.length > 1) {
        const menu = (
            <Menu
                className="material-icons"
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(7, 1fr)',
                }}
            >
                {categoryMenuItems}
            </Menu>
        )

        searchButton = (
            <Popover
                content={menu}
                position={Position.TOP_RIGHT}
            >
                {searchButton}
            </Popover>
        )
    }

    const categoryFilters = categories.map(category => (
        <Checkbox
            key={category.id}
            label={categoryIcons[category.id]}
            checked={!category.hidden}
            onChange={(event) => _onToggleCategory(category.id)}
            className="material-icons"
            style={{ color: category.color }}
            inline
        />
    ))

    let visibleCategories = {}
    categories.forEach((category) => {
        if (!category.hidden) {
            visibleCategories[category.id] = true
        }
    })

    const visibleLocations = (doc.data.locations || []).filter(location => (
        visibleCategories[location.category]
    ))

    return (
        <React.Fragment>
            <div style={{ width: '100%' }}>
                <Button fill intent="primary" onClick={_saveMap}>
                    Save current map view as the new default
                </Button>
                <Button
                    fill
                    style={{ marginTop: '10px' }}
                    onClick={_resetMap}
                >
                    Reset map view to the current default
                </Button>
                <Button
                    fill
                    intent="warning"
                    style={{ marginTop: '10px' }}
                    onClick={() => setDialogOpen(true)}
                >
                    Edit categories
                </Button>
                <CategoriesDialog
                    isOpen={isDialogOpen}
                    onClose={() => setDialogOpen(false)}
                />
            </div>
            <hr style={{ width: '100%' }} />

            <h3 style={{ textAlign: 'center' }}>
                Visible Categories
            </h3>
            <CategoryFilters>
                <Button text="Show all" onClick={_checkAll} />
                <Button text="Hide all" onClick={_uncheckAll} />
                {categoryFilters}
            </CategoryFilters>
            <hr style={{ width: '100%' }} />

            <div style={{ width: '100%' }}>
                <h3 style={{ textAlign: 'center' }}>
                    Locations
                </h3>

                <div style={{ flex: 1, marginRight: '10px' }}>
                    <Alert
                        canEscapeKeyCancel
                        canOutsideClickCancel
                        isOpen={alertOpen}
                        onClose={() => setAlertOpen(false)}
                    >
                        Unknown address.
                    </Alert>
                    <InputGroup
                        fill
                        placeholder="Enter an address (optional)"
                        rightElement={searchButton}
                        value={search}
                        onChange={_updateSearch}
                    />
                    <LocationList
                        locations={visibleLocations}
                        categories={categories}
                        onDeleteLocation={_onDeleteLocation}
                        onUpdateLocation={_onUpdateLocation}
                        onAddressSearch={_onAddressSearch}
                        onAddressError={_onAddressError}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const MapViewEditor = ({
    documentEditTime,
    onDocumentDelete,
    onDocumentSave,
    documentHasEdits,
}) => {
    return (
        <React.Fragment>
            <ScreenHeader
                documentEditTime={documentEditTime}
                onDocumentDelete={onDocumentDelete}
                onDocumentSave={onDocumentSave}
                documentHasEdits={documentHasEdits}
            />
            <DocumentBody />
        </React.Fragment>
    )
}

export default MapViewEditor
