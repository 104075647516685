import React from 'react'
import { connect } from 'react-redux'
import { Callout } from '@blueprintjs/core'
import moment from 'moment'

import Toaster from '../toaster'

class View extends React.Component {
  componentDidMount() {
    this.props.fetchVersion()
  }

  render() {
    const { keycloak, navigation, version } = this.props
    const authorDateFormatted = moment(version.authorDate).format('LLL')

    if (!keycloak.tokenParsed.realm_access.roles.includes('admin')) {
      return (
        <div style={{ padding: '10px' }}>
          You are not allowed here.
        </div>
      )
    }

    return (
      <div style={{ padding: '10px' }}>
        <Toaster messages={navigation.messages} />

        <Callout title="Server version">
          Revision: <code>{version.revision}</code><br />
          Committed: {authorDateFormatted}
        </Callout>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  keycloak: state.keycloak,
  navigation: state.navigation,
  version: state.version,
})

const mapDispatchToProps = dispatch => ({
  fetchVersion: () => dispatch({ type: 'VERSION/FETCH' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
