import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Icon, Popover, Button, Intent, Callout, Divider } from '@blueprintjs/core'
import get from 'lodash/get'
import { Parent as DashboardParent } from '../../dashboard/context'
import { Parent as ModuleParent } from '../../document/context'
import { ModuleHeader } from '../header'

const Body = styled.div`
    display: flex;
    align-items: center;

    .parents {
        padding: 4px;
        display: flex;
        flex-direction: column;
    }
    .header {
        padding: 4px;
        flex-grow: 1;
    }
    .arrow {
        align-items: center;
    }

    .highlight {
        border: 1px solid #ced9e0;
        background: #e1e8ed;
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2px;
    }
`

const PopoverContent = styled.div`
    padding: 20px;
    max-width: 450px;
    pre {
        overflow: scroll;
    }
`

const View = ({ modules, dashboard, linkedModule, moduleId, removeParent }) => {
    const parents = get(modules, `${moduleId}.metadata.parents`)
    return (
        <Body>
            {parents && parents.length ? (
                <React.Fragment>
                    <div className="parents">
                        {parents.map((parent, key) => {

                            switch (parent.type) {
                                case 'dashboard':
                                    return (
                                        <div key={key} className="highlight">
                                            <DashboardParent
                                                parent={parent}
                                                dashboard={dashboard}
                                                compact
                                            />
                                        </div>
                                    )
                                case 'module':
                                    if (modules[parent.id]) {
                                        return (
                                            <div key={key} className="highlight">
                                                <ModuleHeader
                                                    header={
                                                        modules[parent.id].header
                                                    }
                                                />
                                                <ModuleParent
                                                    key={key}
                                                    parent={parent}
                                                    modules={modules}
                                                    compact
                                                />
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <Popover
                                                key={key}
                                                content={
                                                    <PopoverContent>
                                                        <Callout intent={Intent.WARNING} >
                                                            There seems to be a problem with this document's parent.
                                                            The following module was not found in the database.  It's possible the
                                                            document was deleted and did not properly unlinked.
                                                            <pre>
                                                                {JSON.stringify(parent, null, 2)}
                                                            </pre>
                                                        </Callout>
                                                        <div>
                                                            You can correct this problem by unlinking the parent module.
                                                            Doing this will also remove this warning sign.
                                                            <Divider />
                                                            <Button
                                                                text='Unlink parent document'
                                                                fill='true'
                                                                minimal='true'
                                                                onClick={() => {
                                                                    removeParent(moduleId, parent)
                                                                }}
                                                            />
                                                        </div>
                                                    </PopoverContent>
                                                }
                                                target={<Button text='Parent' intent={Intent.WARNING} icon="warning-sign" minimal='true' />}
                                            />

                                        )
                                    }

                                default:
                                    return <div>unable to render parent</div>
                            }
                        })}
                    </div>
                    <div className="arrow">
                        <Icon
                            icon="arrow-right"
                            color="#182026"
                            iconSize={20}
                        />
                    </div>
                </React.Fragment>
            ) : null}
            {linkedModule ? <div className="header">{linkedModule}</div> : null}
        </Body>
    )
}

const mapStateToProps = state => ({
    modules: state.modules,
    dashboard: state.dashboard,
})

const mapDispatchToProps = dispatch => ({
    removeParent: (moduleId, parent) => dispatch({
        type: 'MODULES/UNLINK',
        payload: {
            moduleId,
            parent
        }
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
