import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
    Button,
    Intent,
    Icon,
    Tooltip,
    Position,
    Popover,
    Menu,
} from '@blueprintjs/core'
import _ from 'lodash'
import uuid from 'uuid/v4'
import { useManager } from 'v2/components/manager/store'
import {
    // activeObject,
    pathToID,
    pathToIDArray,
} from 'v2/components/manager/utils'
import {
    TimelineContent,
    TimelineTypeEdit,
    TextContent,
    NoneSelected,
    TimelineTypeAdd,
} from 'v2/components/edit/screen/timeline/render'
import {
    ReminderData,
    ReminderTypeEdit,
    ReminderAdd,
    ReminderTypeAdd,
} from 'v2/components/edit/screen/timeline/reminders'
import PanelHeader from 'v2/components/edit/header'

// Temporary hack: this function is missing from v2/components/manager/utils!
const activeObject = {
    type: 'TextContent',
}

const Fixtures = styled.div`
    .zap-btn {
        border: 0;
        padding: 10px;
        border-radius: 20px;
        background: #313131;

        /*margin-left: -20px;*/
        margin-right: 10px;
        display: inline-block;
    }

    width: 100%;
    padding-left: 10%;
    padding-top: 10px;

    .scroller {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .edit-box {
        background: #ffffff;
        box-shadow: 1px 2px 0 0 rgba(0, 0, 0, 0.5);
        border-radius: 11px;
        padding: 6px 8px 10px 12px;
        margin-right: 10px;
        margin-bottom: 10px;

        border: 2px solid #fff;

        .descriptor {
            display: flex;
            .label {
                flex: 1;
            }
        }

        &.active {
            /*border: 2px solid #4a90e2;
            box-shadow: 0 0;*/
            background: #313131;
            color: #fff;
        }
    }

    .reminders,
    .timelines {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        font-size: 16px;
        font-weight: 300;

        .descriptor {
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 500;
            /*margin-bottom: 5px;*/

            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .type {
            font-family: monospace;
            font-size: 13px;
            font-weight: bold;
        }

        .bp3-button.add {
            width: 350px;
            background: #313131;
            color: #fff;
            border-radius: 20px;
        }
    }

    .reminders {
        margin-bottom: 10px;
        .edit-box {
            width: 125px;
        }
    }

    .timelines {
        .edit-box {
            width: 150px;
        }
        .title {
            margin-top: 5px;
        }
        .count {
            font-size: 12px;
            span {
                font-weight: bold;
            }
        }
    }
`

const Editors = styled.div`
    width: 80%;

    .editable-box {
        padding: 20px 30px;
        background: #ffffff;
        border: 3px solid #fff;
        border-radius: 11px;

        .descriptor {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            margin-right: -10px;
            margin-top: -5px;

            .label {
                flex: 1;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 12px;
            }
        }

        .text {
            font-family: monospace;
            background: #eee;
            padding: 4px 8px;
            border-radius: 4px;
            /*overflow-x: scroll;*/
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }

    .timeline-heading {
        margin-bottom: 10px;

        &.active {
            border: 3px solid #4a90e2;
        }

        .title {
            font-size: 24px;
            font-weight: 200;
            margin-bottom: 10px;
        }
        .meta {
            display: flex;
        }
        .date,
        .reminders {
            font-size: 12px;
            font-weight: 400;

            span {
                display: inline-block;
                width: 30px;
                text-align: right;
                font-size: 16px;
                margin-right: 4px;
                font-weight: 500;
            }
        }
    }

    .ReminderDataRender,
    .TextContent {
        border: 3px solid #4a90e2;

        .content {
            overflow: hidden;
            font-family: monospace;
        }
    }

    .ReminderDataRender {
        .type {
            font-weight: bold;
            margin-bottom: 6px;
        }
        .time {
            font-weight: bold;
            margin-bottom: 6px;
        }
    }

    .actions {
        margin-bottom: 20px;

        .zap-btn {
            border: 0;
            color: #fff;

            padding: 10px 25px;
            border-radius: 20px;
            background: #313131;

            /*margin-left: -20px;*/
            margin-right: 10px;
            display: inline-block;
        }

        .bp3-icon {
            color: #fff;
        }
    }
`

const activeRenderers = {
    TextContent,
    ReminderData,
    TimelineContent,
    NoneSelected,
}

const TimelineViewerEditor = ({
    documentEditTime,
    onDocumentDelete,
    onDocumentSave,
    documentHasEdits,
}) => {
    const { state, dispatch } = useManager()
    const token = useSelector((state) => state.keycloak.token)

    const { editable, active } = state

    const reminders = editable.body.filter((b) => b.type === 'Reminder')
    const timelines = editable.body.filter((b) => b.type === 'Timeline')

    // Active path to render
    // const activePath = active.ids.filter(a => a != t.id)
    // Active item to render
    const activeItem = activeObject(editable, active.id, [
        'TextContent',
        'TimelineContent',
        'ReminderData',
    ])

    const ActiveRender = activeRenderers[activeItem.type]

    const _onReminderTypeEdit = (current, update) => {
        // console.log('reminder item', item)
        // Rename the type
        dispatch({
            type: 'editable/EDIT_DATA',
            payload: {
                id: update.id,
                data: update.data,
            },
        })

        const { type } = update.data

        // Rename all the ReminderData that uses the type.
        timelines.forEach((tl) => {
            const content = tl.body.filter((t) => t.type === 'TimelineContent')
            content.forEach((c) => {
                const reminders = c.body.filter(
                    (t) => t.type === 'ReminderData'
                )

                reminders.forEach((r) => {
                    if (r.data.type === current.data.type) {
                        dispatch({
                            type: 'editable/EDIT_DATA',
                            payload: {
                                id: r.id,
                                data: {
                                    ...r.data,
                                    type,
                                },
                            },
                        })
                    }
                })
            })
        })
    }

    const _onReminderTypeClone = (elem) => {
        const clone = _.cloneDeep(elem)
        clone.id = uuid()
        clone.data.type = `${elem.data.type}-copy`
        dispatch({
            type: 'editable/DUPLICATE',
            payload: { id: elem.id, clone },
        })
    }

    const _onReminderTypeDelete = (elem) => {
        dispatch({
            type: 'editable/DELETE',
            payload: { id: elem.id },
        })

        timelines.forEach((tl) => {
            const content = tl.body.filter((t) => t.type === 'TimelineContent')
            content.forEach((c) => {
                const reminders = c.body.filter(
                    (t) => t.type === 'ReminderData'
                )

                reminders.forEach((r) => {
                    if (r.data.type === elem.data.type) {
                        dispatch({
                            type: 'editable/DELETE',
                            payload: {
                                id: r.id,
                            },
                        })
                    }
                })
            })
        })
    }

    const _onTimelineTypeDuplicate = (elem) => {
        const clone = _.cloneDeep(elem)
        clone.id = uuid()
        clone.data.type = `${elem.data.type}-copy`
        clone.data.title = `${elem.data.title}-copy`

        clone.body = elem.body.map((e) => {
            return {
                ...e,
                id: uuid(),
                body: e.body.map((b) => {
                    return {
                        ...b,
                        id: uuid(),
                    }
                }),
            }
        })

        dispatch({
            type: 'editable/DUPLICATE',
            payload: { id: elem.id, clone },
        })
    }

    return (
        <>
            <PanelHeader
                documentEditTime={documentEditTime}
                onDocumentDelete={onDocumentDelete}
                onDocumentSave={onDocumentSave}
                documentHasEdits={documentHasEdits}
                onHeaderUpdate={(header) => {
                    dispatch({
                        type: 'editable/UPDATE_HEADER',
                        payload: {
                            header,
                        },
                    })
                }}
            />
            <Fixtures>
                <div className="reminders">
                    <ReminderTypeAdd
                        reminders={reminders}
                        onReminderAdd={(item) => {
                            dispatch({
                                type: 'editable/ADD_TO_PATH',
                                payload: {
                                    item,
                                    path: 'body',
                                },
                            })
                        }}
                    />
                    <div className="scroller">
                        {reminders.map((r) => {
                            return (
                                <ReminderTypeEdit
                                    key={r.id}
                                    reminder={r}
                                    reminders={reminders}
                                    onEdit={(edit) =>
                                        _onReminderTypeEdit(r, edit)
                                    }
                                    onDuplicate={() => _onReminderTypeClone(r)}
                                    onDelete={() => _onReminderTypeDelete(r)}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className="timelines">
                    <TimelineTypeAdd
                        timelines={timelines}
                        onTimelineAdd={(item) => {
                            dispatch({
                                type: 'editable/ADD_TO_PATH',
                                payload: {
                                    item,
                                    path: 'body',
                                },
                            })
                        }}
                    />
                    <div className="scroller">
                        {timelines.map((t) => {
                            return (
                                <TimelineTypeEdit
                                    active={active.ids.includes(t.id)}
                                    key={t.id}
                                    timeline={t}
                                    timelines={timelines}
                                    seriesCount={t.body.length}
                                    onEdit={(item) => {
                                        dispatch({
                                            type: 'editable/EDIT_DATA',
                                            payload: {
                                                id: item.id,
                                                data: item.data,
                                            },
                                        })
                                    }}
                                    onDuplicate={() =>
                                        _onTimelineTypeDuplicate(t)
                                    }
                                    onDelete={() => {
                                        dispatch({
                                            type: 'editable/DELETE',
                                            payload: { id: t.id },
                                        })
                                    }}
                                    onAddSeries={(item) => {
                                        dispatch({
                                            type: 'editable/ADD_TO_BODY',
                                            payload: { id: t.id, item },
                                        })
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>
            </Fixtures>
            <Editors>
                {active.ids.map((id) => {
                    const activeItem = activeObject(editable, id, [
                        'TimelineContent',
                    ])
                    const View = activeRenderers[activeItem.type]
                    return (
                        <View
                            key={id}
                            {...activeItem}
                            onChange={(data) => {
                                dispatch({
                                    type: 'editable/EDIT_DATA',
                                    payload: { id, data },
                                })
                                // console.log(data)
                            }}
                            onAddContent={(item) => {
                                // console.log(id, item)
                                dispatch({
                                    type: 'editable/ADD_TO_BODY',
                                    payload: { id: id, item },
                                })
                            }}
                            onDelete={() => {
                                dispatch({
                                    type: 'editable/DELETE',
                                    payload: {
                                        id,
                                    },
                                })
                                dispatch({
                                    type: 'CLEAR_ACTIVE_ID',
                                })
                            }}
                            options={reminders.map((r) => r.data.type)}
                        />
                    )
                })}
                <ActiveRender
                    activeID={active.id}
                    {...activeItem}
                    onChange={(data) => {
                        dispatch({
                            type: 'editable/EDIT_DATA',
                            payload: { id: active.id, data },
                        })
                    }}
                    onAddContent={(item) => {
                        // console.log('from activerender', active.id, item)
                        dispatch({
                            type: 'editable/ADD_TO_BODY',
                            payload: { id: active.id, item },
                        })
                    }}
                    onDelete={() => {
                        dispatch({
                            type: 'editable/DELETE',
                            payload: {
                                id: active.id,
                            },
                        })
                        dispatch({
                            type: 'CLEAR_ACTIVE_ID',
                        })
                    }}
                    options={reminders.map((r) => r.data.type)}
                />
            </Editors>
        </>
    )
}

export default TimelineViewerEditor
