import React from 'react'
import styled from 'styled-components'
import { Divider } from '@blueprintjs/core'
import ImageDisplay from '../models/image-display'
import ImageUpload from '../models/image-upload'

const Body = styled.div`
    display: flex;
    flex-direction: column;
`

const Model = ({ image, width = null, onChange }) => (
    <Body>
        <ImageDisplay {...image} style={{ maxWidth: '100%' }} />
        <Divider />
        Upload
        <ImageUpload
            style={{ width: '100%' }}
            {...image}
            updateImage={image => onChange(image)}
        />
    </Body>
)

export default Model
