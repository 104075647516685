import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Classes } from '@blueprintjs/core'
import Linker from './index'

class Module extends Component {
    moduleDidLink = () => {
        this.props.handleClose()
    }

    render() {
        const { isOpen, handleClose, parent } = this.props

        return (
            <Dialog
                autoFocus={true}
                isOpen={isOpen}
                usePortal={true}
                lazy={true}
                title={`Update view`}
                onClose={handleClose}
            >
                <div className={Classes.DIALOG_BODY}>
                    <Linker
                        parent={parent}
                        moduleDidLink={this.moduleDidLink}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button minimal onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Dialog>
        )
    }
}

Module.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    parent: PropTypes.object.isRequired,
}

export default Module
