import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const StyledContainer = styled.div`
    color: rgba(0, 0, 0, 0.9);
    font-size: 0.75rem;
    display: flex;
    align-items: center;

    strong {
        margin-right: 10px;
    }
    img {
        max-height: 24px;
        max-width: 100px;
    }
`

export const RenderableImage = ({ element }) => {
    const base64 = _.get(element, 'data.image.base64')
    const src = _.get(element, 'data.image.url', base64)

    return (
        <StyledContainer>
            <strong>[Image]</strong>
            {src ? <img src={src} alt="Zap preview image" /> : <span>n/a</span>}
        </StyledContainer>
    )
}
