import React from 'react'
import styled from 'styled-components'
import classname from 'classnames'
import { Icon, ContextMenu, Menu, Intent } from '@blueprintjs/core'
import { Draggable } from 'react-beautiful-dnd'
import {
    useDocumentUpdateTools,
    useContextTools,
} from 'v2/components/manager/api'
import { RenderableText } from 'v2/components/edit/containers/text'
import { RenderableRichText } from 'v2/components/edit/containers/richText'
import { RenderableImage } from 'v2/components/edit/containers/image'
import Linker from 'v2/components/edit/link'

export const LayoutIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    </svg>
)

const StyledLayoutContainer = styled.div`
    flex: 1;
    color: #ced9e0;
    display: flex;

    padding: 4px;

    background: rgba(255, 255, 255, 0.99);
    border: 1px solid #aebecd;
    border-radius: 3px;

    margin-right: 10px;

    cursor: pointer;

    &.selected {
        border-color: #ffd102;
        box-shadow: 0 0 0 3px rgba(255, 209, 0, 0.25);
        /*box-shadow: 0 0 0 3px rgba(255, 209, 0, 0.29);*/
    }

    &:hover {
        border-color: #66a8dc;
    }

    &:last-child {
        margin-right: 0;
    }

    .drag-handle {
        color: #000;
        text-align: center;
        margin-bottom: 4px;
    }
    .layout-button {
        border: 0;
        padding: 0;
        background: transparent;
        cursor: pointer;
    }

    > .left {
        margin-right: 10px;
    }

    > .body {
        width: 100%;
    }

    .heading {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
    }
    .renderable {
    }
`

const Unsupported = () => <div>unsupported renderer</div>

const renderers = {
    Text: RenderableText,
    RichText: RenderableRichText,
    Image: RenderableImage,
    zap__card_youtube: RenderableText,
    zap__card_poll: RenderableText,
}

const RenderableItem = ({ element, onDelete }) => {
    const RenderType = renderers[element.type] ?? renderers[element.style] ?? Unsupported

    return (
        <div className={classname('renderable', element.type)}>
            <RenderType element={element} />
        </div>
    )
}

const LayoutContainerRender = ({
    element,
    index,
    onDeleteContainer,
    onDuplicate,
}) => {
    const { deleteFromElementBody } = useDocumentUpdateTools()
    const { isSelected, selectElement } = useContextTools()
    let linker
    let body

    const _onDeleteRenderable = (index) => {
        deleteFromElementBody(element, index)
    }

    const _onLayoutSelect = () => {
        const previewMate = document.getElementById('preview-' + element.id)

        if (previewMate && previewMate.scrollIntoViewIfNeeded) {
            previewMate.scrollIntoViewIfNeeded()
        }

        selectElement(element)
    }

    const _onContextMenu = (e) => {
        e.preventDefault()
        const menu = (
            <Menu>
                <Menu.Item
                    icon="duplicate"
                    text="Duplicate"
                    onClick={onDuplicate}
                />
                <Menu.Divider />
                <Menu.Item
                    icon="trash"
                    intent={Intent.DANGER}
                    text="Delete"
                    onClick={onDeleteContainer}
                />
            </Menu>
        )

        ContextMenu.show(menu, { left: e.clientX, top: e.clientY }, () => {
            // menu was closed; callback optional
        })
    }

    if (['zap__card_youtube', 'zap__card_poll'].includes(element.style)) {
        body = (
            <RenderableItem
                element={element}
                index={0}
                onDelete={() => _onDeleteRenderable(0)}
            />
        )
    }

    else {
        linker = (
            <Linker element={element} allowAppLink />
        )

        body = element.body.map((elem, index) => (
            <RenderableItem
                key={index}
                element={elem}
                index={index}
                onDelete={() => _onDeleteRenderable(index)}
            />
        ))
    }

    return (
        <Draggable draggableId={element.id} index={index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <StyledLayoutContainer
                        id={'editor-' + element.id}
                        onClick={_onLayoutSelect}
                        className={classname({ selected: isSelected(element) })}
                    >
                        <div className="left">
                            <div
                                className="drag-handle"
                                {...provided.dragHandleProps}
                            >
                                <Icon icon="drag-handle-vertical" />
                            </div>
                            {linker}
                        </div>
                        <div className="body" onContextMenu={_onContextMenu}>
                            {body}
                        </div>
                    </StyledLayoutContainer>
                </div>
            )}
        </Draggable>
    )
}

export { LayoutContainerRender }
