import React from 'react'
import {
    Button,
    Tooltip,
    Icon,
} from '@blueprintjs/core'
import _ from 'lodash'
import classname from 'classnames'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { LayoutContainerRender } from 'v2/components/edit/containers/layout'
import {
    useDocumentUpdateTools,
    useDocumentTemplates,
    useContextTools,
} from 'v2/components/manager/api'
import {
    EditMenuPopover,
    EditMenu,
    EditMenuDivider,
    EditPadding,
    EditScroll,
} from 'v2/components/edit/menu'
import Feed from 'v2/components/edit/feed'

const StyledRow = styled.div`
    width: 100%;

    padding: 6px;

    background: #e1e7ec;
    border: 2px solid #aebecd;
    border-radius: 2px;

    font-size: 16px;
    margin-bottom: 10px;

    display: flex;

    > .left {
        margin-right: 4px;

        .drag-handle {
            text-align: center;
        }
    }

    .body {
        flex: 1;

        > .content {
            display: flex;

            width: 100%;
            min-height: 50px;

            border-radius: 4px;

            &.isDragging {
                background: #d8e1e8;

                .empty-row {
                    display: none;
                }
            }

            > [data-react-beautiful-dnd-drag-handle='1'],
            [data-react-beautiful-dnd-draggable] {
                flex: 1;
            }

            [data-react-beautiful-dnd-draggable] {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }

            > .empty-row {
                font-size: 14px;
                color: #5c7080;

                padding-left: 10px;
                padding-top: 8px;
            }
        }
    }
`

const StyledDivider = styled(StyledRow)`
    .body {
        display: flex;
        justify-content: center;
        align-items: center;

        .divider {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            color: #5c7080;
            font-size: 12px;
            font-weight: bold;

            span {
                display: inline-block;
                margin: 10px;
            }
            .line {
                width: 35%;
                border-bottom: 1px solid #5c7080;
            }
        }
    }
`

const DraggableDivider = ({ row, index, onDeleteRow }) => {
    return (
        <Draggable draggableId={row.id} index={index}>
            {(provided) => (
                <StyledDivider
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <div className="left">
                        <div
                            className="drag-handle"
                            {...provided.dragHandleProps}
                        >
                            <Tooltip content="Drag row">
                                <Icon icon="drag-handle-vertical" />
                            </Tooltip>
                        </div>
                        <EditMenuPopover>
                            <EditMenu>
                                <EditPadding element={row} />
                                <EditMenuDivider />
                                <Button
                                    minimal={true}
                                    text="Delete Divider"
                                    onClick={() => {
                                        onDeleteRow(index)
                                    }}
                                />
                            </EditMenu>
                            <Tooltip content="Configure row">
                                <Button
                                    className="row-button"
                                    minimal={true}
                                    icon="cog"
                                />
                            </Tooltip>
                        </EditMenuPopover>
                    </div>
                    <div className="body">
                        <div className="divider">
                            <span className="line" />
                            <span>divider</span>
                            <span className="line" />
                        </div>
                    </div>
                </StyledDivider>
            )}
        </Draggable>
    )
}

function DraggableRow({
    row,
    index,
    onAddContainer,
    onDeleteRow,
    onDuplicateRow,
    dataDriven,
}) {
    const containers = row.body.filter((r) => r.type === 'LayoutContainer')

    const { deleteFromElementBody, addToElementBody } = useDocumentUpdateTools()
    const { duplicateElement } = useDocumentTemplates()
    const { selectElement } = useContextTools()

    const _onDeleteContainer = (index) => {
        deleteFromElementBody(row, index)
    }

    const _onDuplicate = (container) => {
        const duplicate = duplicateElement(container)
        addToElementBody(row, duplicate)
    }

    const _renderContainers = containers.map((element, index) => (
        <LayoutContainerRender
            key={element.id}
            element={element}
            index={index}
            onDeleteContainer={() => _onDeleteContainer(index)}
            onDuplicate={() => _onDuplicate(element)}
        />
    ))

    const _selectFirstContainer = (event) => {
        const firstId = _.get(containers, '[0].id')

        // Only intercept clicks that don't have meaning elsewhere
        if (!event.target.classList.contains('row-body') && event.target.getAttribute('data-react-beautiful-dnd-draggable') !== '0') {
            return
        }

        if (firstId) {
            const previewMate = document.getElementById('preview-' + firstId)

            if (previewMate && previewMate.scrollIntoViewIfNeeded) {
                previewMate.scrollIntoViewIfNeeded()
            }

            selectElement(containers[0])
        }
    }

    return (
        <Draggable draggableId={row.id} index={index}>
            {(provided) => (
                <StyledRow
                    className={classname({ empty: containers.length === 0 })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    onClick={_selectFirstContainer}
                >
                    <div className="left">
                        <div
                            className="drag-handle"
                            {...provided.dragHandleProps}
                        >
                            <Tooltip content="Drag row">
                                <Icon icon="drag-handle-vertical" />
                            </Tooltip>
                        </div>
                        <EditMenuPopover>
                            <EditMenu>
                                <EditPadding element={row} />
                                <EditMenuDivider />
                                <EditScroll element={row} />
                                <EditMenuDivider />
                                <Button
                                    minimal={true}
                                    text="Add Container"
                                    onClick={() => onAddContainer(row)}
                                />
                                <Button
                                    minimal={true}
                                    text="Duplicate Row"
                                    onClick={() => onDuplicateRow(row)}
                                />
                                <EditMenuDivider />
                                <Button
                                    minimal={true}
                                    text="Delete Row"
                                    onClick={() => {
                                        onDeleteRow(index)
                                    }}
                                />
                            </EditMenu>
                            <Tooltip content="Configure row">
                                <Button
                                    className="row-button"
                                    minimal={true}
                                    icon="cog"
                                />
                            </Tooltip>
                        </EditMenuPopover>
                        {dataDriven ? (
                            <React.Fragment>
                                <br />
                                <Tooltip content="Set Data Source">
                                    <Feed row={row} />
                                </Tooltip>
                            </React.Fragment>
                        ) : null}
                    </div>
                    <div className="body row-body">
                        <Droppable
                            droppableId={row.id}
                            direction="horizontal"
                            type="LAYOUT_CONTAINER"
                        >
                            {(provided, snapshot) => (
                                <div
                                    className={classname('content', {
                                        isDragging: snapshot.isDraggingOver,
                                    })}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {_.isEmpty(_renderContainers) ? (
                                        <div className={classname('empty-row')}>
                                            Add elements to this row using the{' '}
                                            <Icon icon="cog" /> icon
                                        </div>
                                    ) : (
                                        _renderContainers
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </StyledRow>
            )}
        </Draggable>
    )
}

const RowList = React.memo(function RowList({
    layoutRows,
    onAddLayoutContainer,
    onDeleteRow,
    onDuplicateRow,
    dataDriven,
}) {
    return layoutRows.map((row, index) => {
        if (row.type === 'LayoutDivider') {
            return (
                <DraggableDivider
                    key={row.id}
                    row={row}
                    index={index}
                    onDeleteRow={onDeleteRow}
                />
            )
        }
        return (
            <DraggableRow
                row={row}
                index={index}
                key={row.id}
                onDuplicateRow={onDuplicateRow}
                onDeleteRow={onDeleteRow}
                onAddContainer={onAddLayoutContainer}
                dataDriven={dataDriven}
            />
        )
    })
})

export { RowList }
