import React, { useState } from 'react'
import { NumericInput } from '@blueprintjs/core'
import styled from 'styled-components'

import { useContextTools } from 'v2/components/manager/api'

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    > span {
        margin-right: 10px;
    }
`

const NumberEditor = ({ initialData, updatePath }) => {
    const [ value, setValue ] = useState(initialData)
    const { updateStyle } = useContextTools()

    const _onChange = (num, str) => {
        if (!isNaN(num) && num >= 0) {
            setValue(Math.floor(num))
            updateStyle(num, updatePath)
        }
    }

    return (
        <Wrapper>
            <NumericInput
                small
                min={0}
                max={Infinity}
                value={value}
                onValueChange={_onChange}
            />
        </Wrapper>
    )
}

export default NumberEditor
