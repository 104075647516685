import React, {createRef} from 'react'
import { connect } from 'react-redux'
import {
    Tab,
    Tabs,
    Button,
    Dialog,
    Intent,
    Classes,
    InputGroup,
    Callout,
    Label,
    NumericInput,
} from '@blueprintjs/core'
import mapboxgl from 'mapbox-gl'
import styled from 'styled-components'
import 'mapbox-gl/dist/mapbox-gl.css'
import BodyEdit from './body'

const TabWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    label {
        margin-right: 10px;
    }
`
const TabEdit = styled.div`
    padding: 8px;
    margin-right: 2px;
    border: 1px solid #ebf1f5;
    background: #f5f8fa;

    display: flex;
`

const Coordinates = styled.pre`
    background: rgba(0,0,0,0.5);
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    padding:5px 10px;
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
`

const Map = styled.div`
    position:absolute; top:0; bottom:0; width:100%;
    height: 300px;
`

const token =
    'pk.eyJ1IjoiYWxyb21hbiIsImEiOiJjam45czZ0YmUxczZpM3dsZHlzcG95emp1In0.qpQrXNzUYQNebydy-69d-w'

class NearbyMapRender extends React.Component {
    constructor(props) {
        super(props)
        const { lat, lon } = props.location
        const text = 'Longitude: ' + lon + '  Latitude: ' + lat

        this.state = {
            zoom: 13,
            coordinates: text,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('---- i was updated')
        // console.log(prevProps)
        // console.log(this.props)
        const { lon, lat } = this.props.location

        this.mapbox.setCenter([ lon, lat ])
        this.marker.setLngLat([ lon, lat ])
    }

    componentDidMount() {

        const { lat, lon } = this.props.location
        const markerPosition = [lon, lat]

        mapboxgl.accessToken = token
        this.mapbox = new mapboxgl.Map({
            container: this.map,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: markerPosition,
            zoom: 14,
        })
        this.marker = new mapboxgl.Marker({
            draggable: true
        })
            .setLngLat(markerPosition)
            .addTo(this.mapbox)

        this.marker.on('dragend', () => {
            const lngLat = this.marker.getLngLat()
            // coordinates.style.display = 'block'
            const text = 'Longitude: ' + lngLat.lng + '  Latitude: ' + lngLat.lat
            this.props.onMarkerUpdate({lat: lngLat.lat, lon: lngLat.lng})
            this.setState({
                coordinates: text
            })
        })
        const nav = new mapboxgl.NavigationControl()
        this.mapbox.addControl(nav, 'top-left')
    }

    render() {
        // Map has to have proper css
        // https://github.com/mapbox/mapbox-gl-js/issues/3073
        return (
            <div style={{height: 320, position: 'relative'}} >
                <div ref={ref => this.map = ref} style={{position: 'absolute', width: '100%', top:0, bottom:0}} />
                <Coordinates>{this.state.coordinates}</Coordinates>
            </div>
        )
    }
}

class NearbyEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            tabs: props.tabs,
        }
    }
    saveTabs() {
        const { onTabSave, moduleId } = this.props
        const path = `body.nearby`
        const data = this.state.tabs
        onTabSave(path, data, moduleId)
    }
    render() {
        const { tabs } = this.state
        return (
            <React.Fragment>
                <Callout>
                    Edit the location name and add a lat/lon GPS coordinate. The
                    lat/lon coordinate will be used to render a map.
                </Callout>
                <TabWrapper>
                    <React.Fragment>
                        {tabs.map((tab, index) => (
                            <TabEdit key={index}>
                                <Label>
                                    Location title
                                    <InputGroup
                                        id="location-input"
                                        placeholder="Location target"
                                        value={tab.title}
                                        onChange={e => {
                                            const tab = tabs[index]
                                            tab.title = e.target.value
                                            let _tabs = [...tabs]
                                            _tabs.splice(index, 1, tab)
                                            this.setState({ tabs: _tabs })
                                        }}
                                    />
                                </Label>
                                <Label>
                                    Latitude
                                    <InputGroup
                                        id="lon-input"
                                        placeholder="Latitude"
                                        value={tab.location.lat}
                                        onChange={e => {
                                            const tab = tabs[index]
                                            tab.location.lat = e.target.value
                                            let _tabs = [...tabs]
                                            _tabs.splice(index, 1, tab)
                                            this.setState({ tabs: _tabs })
                                        }}
                                    />
                                </Label>
                                <Label>
                                    Longitude
                                    <InputGroup
                                        id="lat-input"
                                        placeholder="Longitude"
                                        value={tab.location.lon}
                                        onChange={e => {
                                            const tab = tabs[index]
                                            tab.location.lon = e.target.value
                                            let _tabs = [...tabs]
                                            _tabs.splice(index, 1, tab)
                                            this.setState({ tabs: _tabs })
                                        }}
                                    />
                                </Label>
                                {index >= 1 ? (
                                    <Button
                                        icon="delete"
                                        minimal
                                        onClick={() => {
                                            let _tabs = [...tabs]
                                            _tabs.splice(index, 1)
                                            this.setState({ tabs: _tabs })
                                        }}
                                    />
                                ) : null}
                            </TabEdit>
                        ))}
                    </React.Fragment>
                    <Button
                        text="add location"
                        icon="add"
                        minimal
                        onClick={() => {
                            let _tabs = [...tabs]
                            _tabs.push({
                                title: 'UCLA',
                                location: { lat: 34.069336, lon: -118.447166 },
                                body: [],
                            })
                            this.setState({ tabs: _tabs })
                        }}
                    />
                </TabWrapper>
            </React.Fragment>
        )
    }
}

class NearbyModuleEdit extends React.Component {
    state = {
        isOpen: false,
        tabs: [],
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        const {
            preview,
            module: { body, _id },
            setRenderTabId,
            updateTabs,
        } = this.props
        const { isOpen } = this.state

        let selectedTabId =
            preview.render && preview.render.selectedTabId
                ? preview.render.selectedTabId
                : 0

        return (
            <React.Fragment>
                <Tabs
                    selectedTabId={selectedTabId}
                    onChange={setRenderTabId}
                    renderActiveTabPanelOnly={true}
                >
                    {body.nearby.map((tab, index) => (
                        <Tab
                            key={index}
                            id={index}
                            title={tab.title}
                            panel={
                                <>
                                    <Callout title="Location">
                                        <div>Drag the map and move the marker to update position.</div>
                                        <NearbyMapRender
                                            location={tab.location}
                                            onMarkerUpdate={(location) => {
                                                const path = `body.nearby[${index}].location`
                                                updateTabs(path, location, _id)
                                            }}
                                        />
                                        Use a <strong>list</strong> to add
                                        location search keywords. The search
                                        results of the keywords will render as
                                        pins on a mapview in the app.
                                        <br />
                                        <br /> In the <strong>list</strong>, you
                                        can use the title for a display name,
                                        and the description for an actual query.
                                        If there is no description, the title
                                        will be used as the query.
                                    </Callout>
                                    <BodyEdit
                                        body={tab.body}
                                        id={_id}
                                        editPath={`body.nearby[${selectedTabId}].body`}
                                    />
                                </>
                            }
                        />
                    ))}
                    <Tabs.Expander />
                    <Button
                        text="Edit locations"
                        minimal
                        icon="exclude-row"
                        onClick={() => this.setState({ isOpen: true })}
                    />
                </Tabs>
                <Dialog
                    isOpen={isOpen}
                    title="Edit nearby locations"
                    onClose={this.handleClose}
                    style={{ width: 800 }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <NearbyEditor
                            ref={ref => (this.tabEditor = ref)}
                            tabs={body.nearby}
                            selectedTabId={selectedTabId}
                            onTabSave={updateTabs}
                            moduleId={_id}
                        />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button minimal onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <Button
                                minimal
                                intent={Intent.PRIMARY}
                                onClick={() => {
                                    this.handleClose()
                                    this.tabEditor.saveTabs()
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    module: state.modules[ownProps.id],
    preview: state.preview,
})

const mapDispatchToProps = dispatch => {
    return {
        setRenderTabId: id =>
            dispatch({
                type: 'PREVIEW_SET_TAB',
                payload: id,
            }),
        updateTabs: (activePath, data, id) =>
            dispatch({
                type: 'UPDATE_MODULE',
                payload: {
                    path: activePath,
                    data,
                    moduleId: id,
                },
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NearbyModuleEdit)
