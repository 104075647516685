import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { Button, Divider, Intent } from '@blueprintjs/core'
import Title from '../../../modules/models/title'
import Icon from '../../../modules/models/icon'
import Connected from './connect'

class View extends React.Component {
    state = {
        isOpen: false,
    }
    _handleClose = () => {
        this.setState({ isOpen: false })
    }
    render() {
        const {
            activePath,
            id,
            button: { text, icon },
        } = this.props
        const { isOpen } = this.state
        return (
            <React.Fragment>
                <Button
                    icon={icon}
                    text={text}
                    minimal
                    onClick={() => this.setState({ isOpen: !isOpen })}
                />
                <Connected
                    activePath={activePath}
                    id={id}
                    isOpen={isOpen}
                    handleClose={this._handleClose}
                >
                    {({ data }, updateData) => (
                        <React.Fragment>
                            <h4>(optional) image</h4>
                            <Icon
                                image={data.image}
                                onChange={image =>
                                    updateData({ ...data, image })
                                }
                            />
                            {!isEmpty(data.image.base64) ? (
                                <>
                                    <Divider />
                                    <Button
                                        text="Clear current image"
                                        minimal="true"
                                        intent={Intent.WARNING}
                                        fill="true"
                                        onClick={() => {
                                            updateData({...data, image: {base64: ''}})
                                        }}
                                    />
                                </>
                            ) : null}
                            <Divider />
                            <h4>Title</h4>
                            <Title
                                value={data.title}
                                onChange={title =>
                                    updateData({ ...data, title })
                                }
                            />
                        </React.Fragment>
                    )}
                </Connected>
            </React.Fragment>
        )
    }
}

View.defaultProps = {
    title: 'Header',
    description: 'none',
    button: {
        text: '',
        icon: 'edit',
    },
}

export default View
