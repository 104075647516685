import React, { useRef, useEffect } from 'react'
import { PanelStack } from '@blueprintjs/core'
import _ from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import produce from 'immer'
import DevicePreview from 'v2/components/device/iphone'
import DocumentLoader from 'v2/components/preview/loader'
import DocumentHeader from 'v2/components/preview/header'
import { panelStackState, loaderState, scaleState } from 'v2/components/manager/document'
import RootView from 'v2/components/preview/root'
import FooterView from 'v2/components/preview/render/footer'

const DocumentPreview = () => {
    const ref = useRef()
    const { load, clear, header } = useRecoilValue(loaderState)
    const [ stack, setStack ] = useRecoilState(panelStackState)
    const [ scale, setScale ] = useRecoilState(scaleState)

    useEffect(() => {
        if (load) {
            _addToDevicePanelStack({
                component: DocumentLoader,
                props: {
                    load,
                },
                title: <DocumentHeader header={header} load={load} />,
            })
        }

        if (clear) {
            setStack([])
        }
    }, [load, clear])

    useEffect(() => {
        const handleResize = () => {
            if (ref?.current?.clientHeight) {
                setScale(Math.min(ref?.current?.clientHeight / 912, 1))
            }
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const _addToDevicePanelStack = (newPanel) => {
        const nextState = produce(stack, (draftState) => {
            draftState.push(newPanel)
        })
        setStack(nextState)
    }

    const _removeFromDevicePanelStack = (_lastPanel) => {
        const nextState = produce(stack, (draftState) => {
            draftState.pop()
        })
        setStack(nextState)
    }

    return (
        <div ref={ref} style={{ width: (450 * scale) + 'px' }}>
            <div style={{
                position: 'absolute',
                left: (200 * scale + 85) + 'px',
                top: (450 * scale - 380) + 'px',
                transform: 'scale(' + scale + ')'
            }}>
                <DevicePreview className="device">
                    {_.isEmpty(stack) ? (
                        <RootView />
                    ) : (
                        <PanelStack
                            className="docs-panel-stack-example"
                            stack={stack}
                            onOpen={_addToDevicePanelStack}
                            onClose={_removeFromDevicePanelStack}
                        />
                    )}
                    <FooterView />
                </DevicePreview>
            </div>
        </div>
    )
}

export default DocumentPreview
