import React, { useState, useCallback } from 'react'
import GoogleFontLoader from 'react-google-font-loader'
import { MenuItem, Button } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import styled from 'styled-components'

import { useContextTools } from 'v2/components/manager/api'

export const googleFontList = [
    {
        font: 'Nunito',
        weights: [200, 300, 400, 500, 600, 700, 800, 900],
    },
    {
        font: 'Roboto',
        weights: [200, 300, 400, 500, 600, 700, 800, 900],
    },
    {
        font: 'Open Sans',
        weights: [300, 400, 500, 600, 700, 800],
    },
    {
        font: 'Lato',
        weights: [300, 400, 500, 600, 700, 800],
    },
    {
        font: 'Raleway',
        weights: [300, 400, 500, 600, 700, 800],
    },
    {
        font: 'Anton',
        weights: [400],
    },
    {
        font: 'Abril Fatface',
        weights: [400],
    },
    {
        font: 'Permanent Marker',
        weights: [400],
    },
    {
        font: 'Monda',
        weights: [400, 700],
    },
    {
        font: 'Orbitron',
        weights: [400, 500, 600, 700, 800, 900],
    },
]

// OpenType font names (https://learn.microsoft.com/en-us/typography/opentype/spec/os2#usweightclass)
const fontNames = {
    100: 'Thin',
    200: 'Extra-light',
    300: 'Light',
    400: 'Normal',
    500: 'Medium',
    600: 'Semi-bold',
    700: 'Bold',
    800: 'Extra-bold',
    900: 'Black',
}

const StyledWrapper = styled.div`
    display: flex;
`

const FontEditor = ({ initialFont, initialWeight, updatePath }) => {
    const { updateStyle } = useContextTools()
    const [data, setData] = useState({
        family: initialFont,
        weight: initialWeight,
    })

    const _onFontSelect = (item) => {
        googleFontList.filter((f) => f.font === data.family)

        setData({
            ...data,
            family: item.font,
        })
        updateStyle(item.font, `${updatePath}.fontFamily`)
        // console.log(`${updatePath}.fontFamily`)
    }

    const _onWeightSelect = (weight) => {
        setData({
            ...data,
            weight,
        })
        updateStyle(weight, `${updatePath}.fontWeight`)
    }

    const _itemPredicate = useCallback(
        (query, item, _index, exactMatch) => {
            const normalizedTitle = item.font.toLowerCase()
            const normalizedQuery = query.toLowerCase()

            if (exactMatch) {
                return normalizedTitle === normalizedQuery
            } else {
                return normalizedTitle.indexOf(normalizedQuery) >= 0
            }
        },

        []
    )

    const _itemRender = useCallback(
        (item, { handleClick, modifiers, query }) => {
            if (!modifiers.matchesPredicate) {
                return null
            }

            return (
                <MenuItem
                    style={{
                        fontFamily: item.font,
                        fontWeight: item.weight,
                    }}
                    active={modifiers.active}
                    disabled={modifiers.disabled}
                    key={item.font}
                    onClick={handleClick}
                    text={item.font}
                    shouldDismissPopover={false}
                />
            )
        },

        []
    )

    const _weightRender = (fontFamily) => {
        return (item, { handleClick, modifiers, query }) => {
            if (!modifiers.matchesPredicate) {
                return null
            }

            return (
                <MenuItem
                    style={{
                        fontFamily,
                        fontWeight: item,
                    }}
                    active={modifiers.active}
                    disabled={modifiers.disabled}
                    key={item}
                    onClick={handleClick}
                    text={`${item} (${fontNames[item]})`}
                    shouldDismissPopover={false}
                />
            )
        }
    }

    const [activeFont] = googleFontList.filter((f) => f.font === data.family)

    return (
        <StyledWrapper>
            <GoogleFontLoader fonts={googleFontList} />
            <Select
                filterable={false}
                items={googleFontList}
                itemPredicate={_itemPredicate}
                itemRenderer={_itemRender}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={_onFontSelect}
            >
                <Button
                    style={{
                        fontFamily: data.family,
                        fontWeight: data.weight,
                    }}
                    minimal={true}
                    text={data.family}
                    rightIcon="double-caret-vertical"
                />
            </Select>
            <div className="weights">
                <Select
                    filterable={false}
                    items={activeFont.weights}
                    itemRenderer={_weightRender(data.family)}
                    onItemSelect={_onWeightSelect}
                >
                    <Button
                        style={{
                            fontFamily: data.family,
                            fontWeight: data.weight,
                        }}
                        minimal={true}
                        text={data.weight}
                        rightIcon="double-caret-vertical"
                    />
                </Select>
            </div>
        </StyledWrapper>
    )
}

FontEditor.defaultProps = {
    initialFont: 'Nunito',
    intialWeight: 500,
}

export default FontEditor
