import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import { Callout, Spinner } from '@blueprintjs/core'
import _ from 'lodash'
import produce from 'immer'
import { useRecoilState } from 'recoil'

import TimelineViewer from 'v2/components/edit/screen/timeline'
import ViewEditor from 'v2/components/edit/screen/view'
import MapViewEditor from 'v2/components/edit/screen/map'
import saveDocument from 'v2/components/edit/actions/save'
import { documentState } from 'v2/components/manager/document'
import { PreviewRender } from '../../../components/document/all'
import { socket } from 'store/socket'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    max-height: calc(100vh - 130px);
    overflow-y: scroll;
    color: #000;
    padding: 10px;

    &.v1 {
        padding: 0 10px;
    }

    &.no-screen {
        padding: 0 10px;
    }
`

// Makes this take up the full layout of the dialog
const GlobalStyle = createGlobalStyle`
  .bp3-dialog-body {
    div[role="main"] {
        margin: -20px;
    }
  }

  .edit-menu-popover.bp3-popover {
        background-color: transparent;
        box-shadow: 0 0 0 0;
        .bp3-popover-content {
            background-color: transparent;
        }
  }
`

const editors = {
    TimelineViewer: TimelineViewer,
    Dashboard: ViewEditor,
    View: ViewEditor,
    MapView: MapViewEditor,
    DataDrivenDashboard: ViewEditor,
    DataDrivenView: ViewEditor,
}

const DocumentEditor = () => {
    const dispatch = useDispatch()
    const [doc, setDoc] = useRecoilState(documentState)

    const [isLoading, setLoading] = useState(true)
    const [hasEdits, setHasEdits] = useState(false)
    const [editTime, setEditTime] = useState(0)

    const token = useSelector((state) => state.keycloak.token)
    const locks = useSelector((state) => state.locks)

    useEffect(() => {
        if (_.isEmpty(doc)) {
            return
        }

        if (isLoading) {
            setLoading(false)
            return
        }

        setHasEdits(true)
        setEditTime(Date.now())
        const nextState = produce(doc, (draftState) => {
            draftState.updated = new Date()
            return draftState
        })
        setDoc(nextState)
    }, [doc.body, doc.data, doc.header])

    const _onDocumentDelete = () => {
        dispatch({
            type: 'MODULES/DELETE',
            payload: {
                id: doc._id,
                rev: doc._rev,
            },
        })
    }

    const _onDocumentSave = () => {
        setHasEdits(false)
        setEditTime(0)

        saveDocument(token, doc, (res) => {
            const nextState = produce(doc, (draftState) => {
                draftState._rev = res.rev
                draftState.updated = new Date()
                return draftState
            })

            setDoc(nextState)

            // Propagate the result back to modules.
            dispatch({ type: 'REFRESH_MODULE', payload: nextState })
        })
    }

    if (_.isEmpty(doc)) {
        return (
            <Container className="empty">
                <Callout>Nothing to edit here...</Callout>
            </Container>
        )
    }

    if (doc.version === undefined || doc.version === 1) {
        return (
            <Container className="v1">
                <Callout title="Version 1 document">
                    <p>
                        This is an older format document. You can edit it, but
                        you will not be able to make new links from it.
                    </p>
                    <PreviewRender module={doc} />
                </Callout>
            </Container>
        )
    }

    if (doc.lock) {
        const otherLock = Object.values(locks).find(lock => (
            lock.moduleId === doc._id && lock.socketId === doc.lock.socketId
        ))

        if (otherLock) {
            return (
                <Container className="empty">
                    <Callout intent="primary" title="Please Wait">
                        {otherLock.owner.name} ({otherLock.owner.email})
                        is currently editing this view.
                        <Spinner />
                    </Callout>
                </Container>
            )
        }

        else {
            return (
                <Container className="empty">
                    <Callout intent="primary" title="The Wait Is Over!">
                        Please navigate to a different view, then come back,
                        to open this view for editing.
                    </Callout>
                </Container>
            )
        }
    }

    else if (!Object.values(locks).find(lock => (
        lock.moduleId === doc._id && lock.socketId === socket.id
    ))) {
        return (
            <Container className="empty">
                <Callout intent="danger" title="Oh No!">
                    Your changes have been lost due to inactivity.
                    Please navigate to a different view, then come back.
                </Callout>
            </Container>
        )
    }

    const ScreenEditor = editors[doc.screen]
    const queue = Object.values(locks).find(lock => (
        lock.queuedModuleId === doc._id
    ))
    let callout

    if (ScreenEditor === undefined) {
        return (
            <Container className="no-screen">
                <Callout>
                    Editor for <strong>{doc.screen}</strong> not supported
                </Callout>
            </Container>
        )
    }

    if (queue) {
        callout = (
            <Callout intent="warning" title="User Waiting">
                {queue.owner.name} ({queue.owner.email})
                is waiting to edit this view.
            </Callout>
        )
    }

    return (
        <Container>
            {callout}
            <GlobalStyle />
            <ScreenEditor
                documentEditTime={editTime}
                documentHasEdits={hasEdits}
                onDocumentSave={_onDocumentSave}
                onDocumentDelete={_onDocumentDelete}
            />
        </Container>
    )
}

export default DocumentEditor
