import React, { Component } from 'react'
import { Button, Icon, Divider, Intent } from '@blueprintjs/core'
import styled from 'styled-components'
import EditItem from './edit'

const Content = styled.div`
    width: 100%;
    display: flex;
    padding: 4px;

    border: 1px solid #ced9e0;
    background: #f5f8fa;

    border-radius: 4px;
    .title {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 8px;
    }
`

const Image = styled.img`
    height: 40px;
`

class Item extends Component {
    state = {
        isOpen: false,
    }

    handleOpen = () => {
        this.setState(state => ({ isOpen: !state.isOpen }))
    }
    handleClose = () => {
        this.setState({ isOpen: false })
    }
    render() {
        const { onDelete, item, onChange } = this.props
        const { isOpen } = this.state
        return (
            <Content>
                <Button icon="edit" minimal onClick={this.handleOpen} />
                <Divider />
                <div className="image">
                    {item.image && item.image.base64 ? (
                        <Image src={item.image.base64} />
                    ) : (
                        <Icon icon="media" iconSize={50} color="#5C7080" />
                    )}
                </div>
                <div className="title">
                    <div>{item.name}</div>
                </div>
                <Divider />
                <Button
                    minimal
                    icon="trash"
                    onClick={onDelete}
                    intent={Intent.DANGER}
                />
                <EditItem
                    item={item}
                    isOpen={isOpen}
                    handleClose={this.handleClose}
                    handleSave={update => {
                        onChange(update)
                        this.setState({ isOpen: false })
                    }}
                />
            </Content>
        )
    }
}

export default Item
