import React from 'react'
import { Icon } from '@blueprintjs/core'
import styled from 'styled-components'

const Wrapper = styled.div`
    padding: 10px;
    margin-top: 50px;

    > .root {
        margin: 20px 40px;
        text-align: center;
        button {
            border: 0;
            padding: 0;
            background: transparent;
            cursor: pointer;
        }
    }

    .doc {
        .title {
            margin-top: 20px;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .impressions {
            margin-bottom: 4px;
        }
    }

    > p {
        text-align: center;
    }

    > ul {
        padding: 0;
        margin-top: 20px;
        display: flex;
        overflow-x: scroll;
        list-style: none;

        > li {
            margin-right: 10px;
            min-width: 200px;
            border: 2px solid transparent;
            cursor: pointer;
            padding: 4px;
            border-radius: 3px;

            &:hover {
                border-color: #bfccd6;
            }
        }
    }
`

const RootView = () => {
    return (
        <Wrapper>
            <p>
                <Icon icon="tree" />
            </p>
            <p>
                Link a tab to a starting view. If there is only a single tab,
                the app will render without a tab footer.
            </p>
        </Wrapper>
    )
}

export default RootView
