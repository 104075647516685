/* eslint-disable */
import {
    takeEvery,
    takeLatest,
    call,
    all,
    select,
    put,
    take,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
    getKeycloak,
    getOrg,
    getDashboard,
    getEditingModules,
    getNavigation,
    getApiEndpoint,
    getAllModules,
} from './selectors'
// import { Validate, Api } from './api'
import { emit } from './socket'
import { isModule, moduleIsLoaded } from '../utils'
import get from 'lodash/get'
import config from '../config'

function assert(condition, message) {
    if (!condition) {
        throw new Error(message || 'Assertion failed')
    }
}

const withAuth = (keycloak) => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${keycloak.token}`,
    },
})

function* ensureModuleIsLoaded(action) {
    const { navigation } = action
    const modules = yield select(getAllModules)

    if (
        isModule(navigation.module) &&
        !moduleIsLoaded(modules[navigation.module.id])
    ) {
        yield put({
            type: 'MODULES/GET',
            payload: navigation.module.id,
        })
        // And wait for payload..
        yield take('@@SOCKET/REFRESH_MODULE')
    }
}

function* handleParentUnlink(action) {
    const { moduleId, parent } = action.payload
    const modules = yield select(getAllModules)

    if (!moduleIsLoaded(modules[moduleId])) {
        yield put({
            type: 'MODULES/GET',
            payload: moduleId,
        })
        // And wait for payload..
        yield take('@@SOCKET/REFRESH_MODULE')
    }
    yield put({
        type: 'MODULE_REMOVE_PARENT',
        payload: {
            moduleId,
            parent,
        },
    })
    yield call(delay, 1000)
    yield call(saveModule, { payload: moduleId })
}

function* handleParentLink(action) {
    const { navigation, parent, oldNavigation } = action
    const modules = yield select(getAllModules)

    // Remove parent from the previous link...
    if (isModule(oldNavigation.module)) {
        yield put({
            type: 'MODULE_REMOVE_PARENT',
            payload: {
                moduleId: oldNavigation.module.id,
                parent,
            },
        })
        yield call(delay, 1000)
        yield call(saveModule, { payload: oldNavigation.module.id })
    }

    yield put({
        type: 'MODULE_ADD_PARENT',
        payload: {
            moduleId: navigation.module.id,
            parent,
        },
    })
    yield call(delay, 1000)
    yield call(saveModule, { payload: navigation.module.id })
}

function* linkModule(action) {
    const { type, navigation, path, parent } = action.payload
    const dashboard = yield select(getDashboard)

    // If we're loading a module, make sure its `body` exists
    // before we try to render it.
    yield call(ensureModuleIsLoaded, { navigation })

    switch (type) {
        case 'DASHBOARD':
            const previousLink = { ...get(dashboard, `items[${path}]`) }

            yield put({
                type: 'DASHBOARD_LINK',
                payload: {
                    navigation,
                    path,
                },
            })
            // Auto save when you link...
            yield call(saveDashboard)

            // Save the parent metadata
            if (isModule(navigation.module)) {
                yield call(handleParentLink, {
                    navigation,
                    parent,
                    oldNavigation: previousLink.navigation,
                })
            }

            break
        case 'MODULE':
            yield put({
                type: 'MODULE_LINK',
                payload: {
                    navigation,
                    path: path,
                },
            })
            // Auto save module when you link...
            yield call(saveModule, { payload: parent.id })

            // Save the parent metadata
            if (isModule(navigation.module)) {
                yield call(handleParentLink, {
                    navigation,
                    parent,
                    oldNavigation: {},
                })
            }
            break
        default:
            break
    }
}

function* bcastUpdateDashboard(action) {
    const dashboard = yield select(getDashboard)
    const { payload } = action
    if (dashboard._id === payload._id) {
        yield put({
            type: 'SET_DASHBOARD',
            payload,
        })
    }
}

function* fetchAllModules() {
    try {
        const org = yield select(getOrg)
        const keycloak = yield select(getKeycloak)

        yield emit('MODULES/GETALL', {
            org: org,
            token: keycloak.token,
        })
    } catch (err) {
        console.error(err)
    }
}

function* fetchDashboard() {
    try {
        const keycloak = yield select(getKeycloak)
        const org = yield select(getOrg)
        // const api = yield select(getApiEndpoint)

        yield emit('DASHBOARD/GET', {
            org: org,
            token: keycloak.token,
        })
    } catch (err) {
        console.log(err)
        /// @todo Need better logic here.
        yield put({
            type: 'NAVIGATION_SET_MESSAGE',
            payload: {
                message: 'Error fetching dashboard',
                intent: 'WARNING',
            },
        })
        // yield put({ type: 'DASHBOARD/CREATE' })
    }
}

function* saveDashboard() {
    const keycloak = yield select(getKeycloak)
    const dashboard = yield select(getDashboard)

    // add a timestamp
    dashboard.updated = new Date()

    emit('DASHBOARD/SAVE', {
        payload: dashboard,
        token: keycloak.token,
    })
}

function* createDashboard() {
    try {
        const keycloak = yield select(getKeycloak)
        const org = yield select(getOrg)
        const dashboard = yield select(getDashboard)
        const api = yield select(getApiEndpoint)

        dashboard.org = org

        const request = yield call(fetch, `${api}/dashboards/?org=${org}`, {
            method: 'POST',
            body: JSON.stringify(dashboard),
            ...withAuth(keycloak),
        })

        const json = yield request.json()
        yield put({ type: 'SET_DASHBOARD', payload: json })
        yield put({
            type: 'NAVIGATION_SET_MESSAGE',
            payload: {
                message: 'Dashboard was successfully created.',
                intent: 'SUCCESS',
            },
        })
    } catch (err) {
        console.log(err)
        yield put({
            type: 'NAVIGATION_SET_MESSAGE',
            payload: {
                message: 'Error creating the dashboard.',
                intent: 'DANGER',
            },
        })
    }
}

function* updateOrg(action) {
    const newOrg = action.payload
    const org = yield select(getOrg)

    if (org === newOrg) {
        return
    }

    yield put({
        type: 'NAVIGATION_SET_ORG',
        payload: newOrg,
    })

    yield put({ type: 'CLEAR_ORG' })
    yield put({ type: 'ANALYTICS/GETALL' })
    yield put({ type: 'CLEAR_EDITING_TABS' })
    // yield put({
    //     type: 'NAVIGATION_SET_TAB',
    //     payload: 'dashboard',
    // })

    yield put({
        type: 'NAVIGATION_SET_MESSAGE',
        payload: {
            message: `Changed organization to "${newOrg}"`,
            intent: 'PRIMARY',
            icon: 'diagram-tree',
        },
    })
}

function* closeModule(action) {
    yield put({
        type: 'REMOVE_EDITING_MODULE',
        payload: {
            id: action.payload,
        },
    })
    yield put({
        type: 'NAVIGATION_SET_TAB',
        payload: 'modules',
    })
}

function* saveModule(action) {
    try {
        const modules = yield select(getAllModules)
        const keycloak = yield select(getKeycloak)

        const module = modules[action.payload]

        assert(
            moduleIsLoaded(module) === true,
            `Module appears to have an empty body, data was lost`
        )

        // Save timestamp
        module.updated = new Date()

        emit('MODULES/SAVE', {
            token: keycloak.token,
            payload: module,
        })

        // Validate and try to fix body errors.
        // const result = Validate.module(editing)
        // if (result.hasError) {
        //     console.error(result.errors)
        //     // try to recover from error
        //     const fix = result.errors
        //         .filter(err =>
        //             err.message.includes('is not of a type(s) object')
        //         )
        //         .map(err => {
        //             console.log('attempting to recover form error', err)
        //             const body = editing.body.filter(o => o !== null)
        //             editing.body = body
        //         })

        //     if (fix.length === 0) {
        //         console.error('Validation error while trying to save module')
        //         throw new Error(result.errors)
        //     }
        // }

        // // When `_id` is undefined, it means we have a new document,
        // // so we POST
        // const method = editing._id === undefined ? 'POST' : 'PUT'
        // const request = yield call(fetch, `${api}/modules`, {
        //     method: method,
        //     body: JSON.stringify(editing),
        //     ...withAuth(keycloak),
        // })

        // yield put({
        //     type: 'NAVIGATION_SET_MESSAGE',
        //     payload: {
        //         message: 'Module was updated.',
        //         intent: 'SUCCESS',
        //     },
        // })

        // const json = yield request.json()
        // yield put({ type: 'MODULES/GET', payload: json.id })

        // if (editing._id === undefined) {
        //     yield put({ type: 'MODULES/GETALL' })
        // }
    } catch (err) {
        console.error(err)
        yield put({
            type: 'NAVIGATION_SET_MESSAGE',
            payload: {
                message: `saveModule: Error saving module`,
                intent: 'DANGER',
            },
        })
    }
}

function* editModule(action) {
    // const editing = yield select(getEditingModules)

    // const modules = editing.filter(mod => mod.id == action.payload)
    // if (modules.length) {
    //     console.log('already editing module')
    //     return
    // }

    yield put({
        type: 'MODULES/GET',
        payload: action.id,
    })
}

function* fetchModule(action) {
    try {
        if (action.payload === undefined) {
            console.error(action)
            throw new Error('You need to specify a module ID')
        }

        const keycloak = yield select(getKeycloak)

        yield emit('MODULES/GETONE', {
            id: action.payload,
            token: keycloak.token,
        })
    } catch (err) {
        console.error(err)
        yield put({
            type: 'NAVIGATION_SET_MESSAGE',
            payload: {
                message: `fetchModule: Error fetching module`,
                intent: 'DANGER',
            },
        })
    }
}

function* addNewModule(action, dispatch) {
    const keycloak = yield select(getKeycloak)
    yield emit(
        'MODULES/CREATE',
        {
            token: keycloak.token,
            payload: action.payload,
        },
        action.cb
    )
    // yield put({
    //     type: 'UNSET_MODULE',
    // })

    // yield put({
    //     type: 'NAVIGATION_SET_TAB',
    //     payload: 'editing',
    // })
}

function* createdNewModule(action) {
    const { _id } = action.payload
    yield put({
        type: 'CREATE_MODULE',
        payload: {
            ...action.payload,
        },
    })
}

function* deleteModule(action) {
    try {
        const { id, rev } = action.payload
        const keycloak = yield select(getKeycloak)
        emit('MODULES/DELETE', {
            token: keycloak.token,
            payload: {
                id,
                rev,
            },
        })

        // const api = yield select(getApiEndpoint)

        // const request = yield call(fetch, `${api}/modules/${id}/${rev}`, {
        //     method: 'DELETE',
        //     ...withAuth(keycloak),
        // })

        // yield put({ type: 'DASHBOARD/GET' })
        // yield put({ type: 'MODULES/GETALL' })
        // yield put({
        //     type: 'UNSET_MODULE',
        // })
        // yield put({
        //     type: 'NAVIGATION_SET_TAB',
        //     payload: 'modules',
        // })

        // yield put({
        //     type: 'NAVIGATION_SET_MESSAGE',
        //     payload: {
        //         message: 'Module was deleted.',
        //         intent: 'SUCCESS',
        //     },
        // })
    } catch (err) {
        console.error(err)
        yield put({
            type: 'NAVIGATION_SET_MESSAGE',
            payload: {
                message: `deleteModule: Error deleting module`,
                intent: 'DANGER',
            },
        })
    }
}

function* addTag(action) {
    const { payload } = action
    yield put({
        type: 'DASHBOARD_ADD_TAG',
        payload,
    })
    yield put({
        type: 'MODULE_ADD_TAG',
        payload,
    })
    yield call(saveDashboard)
    yield call(saveModule, { payload: payload.moduleId })
}

function* removeTag(action) {
    const { payload } = action
    yield put({
        type: 'MODULE_REMOVE_TAG',
        payload,
    })
    yield call(saveModule, { payload: payload.moduleId })
}

function* admin_runParentLinker() {
    try {
        const org = yield select(getOrg)
        const keycloak = yield select(getKeycloak)

        yield emit('ADMIN/SCRIPT/PARENTLINK', {
            org: org,
            token: keycloak.token,
        })
    } catch (err) {
        console.error(err)
    }
}

function* updateMetadata(action) {
    const { moduleId } = action.payload

    yield put({
        type: 'MODULES/GET',
        payload: moduleId,
    })
    // And wait for payload..
    yield take('@@SOCKET/REFRESH_MODULE')
    yield put({ type: 'MODULE_UPDATE_METADATA', payload: action.payload })
    yield call(saveModule, { payload: moduleId })
}

function* fetchVersion() {
    const api = config.nonSocketsApi.url
    const request = yield call(fetch, `${api}/version`)
    const json = yield request.json()
    yield put({ type: 'SET_VERSION', payload: json })
}

function* fetchOrgs() {
    const api = config.nonSocketsApi.url
    const request = yield call(fetch, `${api}/orgs`)
    const json = yield request.json()
    yield put({ type: 'NAVIGATION_SET_ORGS', payload: json })
}

function* fetchAnalytics() {
    try {
        const keycloak = yield select(getKeycloak)
        const org = yield select(getOrg)

        yield emit('ANALYTICS/GETALL', {
            org: org,
            token: keycloak.token,
        })
    } catch (err) {
        console.log(err)
        yield put({
            type: 'NAVIGATION_SET_MESSAGE',
            payload: {
                message: 'Error fetching analytics',
                intent: 'WARNING',
            },
        })
    }
}

export default function* rootSaga() {
    yield all([
        yield takeEvery('MODULES/GETALL', fetchAllModules),
        yield takeEvery('MODULES/GET', fetchModule),
        yield takeEvery('DASHBOARD/GET', fetchDashboard),
        yield takeEvery('MODULES/EDIT', editModule),
        yield takeEvery('MODULES/CLOSE', closeModule),
        yield takeEvery('DASHBOARD/SAVE', saveDashboard),
        yield takeEvery('DASHBOARD/CREATE', createDashboard),
        yield takeEvery('UPDATE/ORG', updateOrg),
        yield takeLatest('MODULES/SAVE', saveModule),
        yield takeEvery('MODULES/ADD', addNewModule),
        yield takeEvery('@@SOCKET/MODULES/CREATED', createdNewModule),
        yield takeEvery('MODULES/DELETE', deleteModule),
        yield takeEvery(
            '@@SOCKET/BROADCAST/DASHBOARD_UPDATE',
            bcastUpdateDashboard
        ),
        yield takeEvery('MODULES/LINK', linkModule),
        yield takeEvery('MODULES/UNLINK', handleParentUnlink),
        yield takeEvery('TAG/ADD', addTag),
        yield takeEvery('TAG/REMOVE', removeTag),
        yield takeEvery('SCRIPT/PARENT_LINKER', admin_runParentLinker),
        yield takeEvery('MODULES/UPDATEMETADATA', updateMetadata),
        yield takeEvery('VERSION/FETCH', fetchVersion),
        yield takeEvery('ORGS/FETCH', fetchOrgs),
        yield takeEvery('ANALYTICS/GETALL', fetchAnalytics),
    ])
}
