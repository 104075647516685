import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import produce from 'immer'
import { useRecoilState } from 'recoil'
import { manifestState, panelStackState } from 'v2/components/manager/document'
import DocumentHeader from 'v2/components/preview/header'
import DocumentLoader from 'v2/components/preview/loader'
import WebLoader from 'v2/components/preview/web'

const StyledTabs = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px; /* controlls tab height */
    border-top: 4px solid #000;

    background: #ccc;
    z-index: 100;

    display: flex;
    justify-content: space-around;

    .single-tab {
        padding: 20px;
    }

    .tab {
        padding: 14px;
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        img {
            max-width: 40px;
            max-height: 40px;
            margin-bottom: 10px;
        }

        .title {
            font-weight: bold;
        }

        .target {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;

            cursor: pointer;
            border-radius: 3px;

            &:hover {
                background: rgba(102, 168, 220, 0.25);
            }

            &.selected {
                border: 2px solid rgba(102, 168, 220, 0.75);
            }
        }
    }
`

const FooterTab = ({ title, image }) => {
    let _renderImage = null
    const src = image.url || image.base64

    if (src) {
        _renderImage = <img src={src} alt="Icon" />
    }

    return (
        <React.Fragment>
            {_renderImage}
            <span className="title">{title}</span>
        </React.Fragment>
    )
}

const FooterView = () => {
    const [stack, setStack] = useRecoilState(panelStackState)
    const [manifest] = useRecoilState(manifestState)

    const _onNavigate = (navigation) => {
        console.log(navigation)
        if (!navigation) {
            return
        }

        const load = _.get(navigation, 'module.id')

        const newPanel = navigation.url
            ? {
                  component: WebLoader,
                  props: { url: navigation.url },
                  title: <DocumentHeader header={{}} />,
              }
            : {
                  component: DocumentLoader,
                  props: {
                      load,
                  },
                  title: <DocumentHeader header={{}} load={load} />,
              }
        const nextState = produce(stack, (draftState) => {
            draftState.push(newPanel)
        })
        setStack(nextState)
    }

    if (manifest.body.length === 0) {
        return <StyledTabs>There are no tabs to render</StyledTabs>
    }

    if (manifest.body.length === 1) {
        return (
            <StyledTabs>
                <div className="single-tab">
                    A single tab will not render in the app. The View associated
                    with the tab will display as a standalone view.
                </div>
            </StyledTabs>
        )
    }

    return (
        <StyledTabs
            style={{
                background: manifest.data.backgroundColor,
                color: manifest.data.textColor,
                borderColor: manifest.data.highlightColor,
            }}
        >
            {manifest.body.map((b, k) => (
                <div className="tab" key={k}>
                    <FooterTab title={b.tab.title} image={b.tab.image} />
                    <div
                        className="target"
                        onDoubleClick={() => _onNavigate(b.navigation)}
                    />
                </div>
            ))}
        </StyledTabs>
    )
}

export default FooterView
