// Sorta secret config
// $ cp config.sample.js config.js
// (Do not do this at OIT, config.js is managed by Chef!)
// The API is used by the client, so must be publicly visible.

const config = {
  api: {
    url: 'https://zap.ucla.edu'
  },
  nonSocketsApi: {
    url: 'https://zap.ucla.edu/api'
  },
  opt: {
    url: 'https://onlinepoll.ucla.edu'
  },
  email: 'mobilizelabs@oarc.ucla.edu',
}

module.exports = config
