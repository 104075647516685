import React from 'react'
import oarcLogo from '../landing/assets/oarc-logo.png'

const Footer = () => (
    <footer id="footer-stripe">
        <div
            className="footer-bottom"
            style={{ marginBottom: 0, marginTop: 0 }}
        >
            <div className="left">
                <a href="https://oarc.ucla.edu/">
                    <img
                        className="oarc-logo"
                        src={oarcLogo}
                        alt="UCLA Office of Advanced Research Computing"
                    />
                </a>
            </div>
        </div>
        <div className="copyright">
            &copy; {new Date().getFullYear()} UC Regents. All rights reserved.
        </div>
    </footer>
)

export default Footer
