import React from 'react'
import uclamade from './assets/ucla-made.png'
import oarcLogo from './assets/oarc-logo.png'

const Footer = () => (
    <footer id="footer-stripe">
        <div className="footer-top">
            <div className="left" />
            <div className="right">
                <h2>Let's Get Started</h2>
                <p>
                    <span className="contact">Contact: </span>
                    <a
                        className="contact-email"
                        href="mailto:mobilizelabs@oarc.ucla.edu"
                    >
                        mobilizelabs@oarc.ucla.edu
                    </a>
                </p>
                <p>
                    <a
                        className="demo-btn"
                        href="mailto:mobilizelabs@oarc.ucla.edu?Subject=ZAP%20Demo%20Request"
                    >
                        Request a Demo
                    </a>
                </p>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="left">
                <a href="https://oarc.ucla.edu/">
                    <img
                        className="oarc-logo"
                        src={oarcLogo}
                        alt="UCLA Office of Advanced Research Computing"
                    />
                </a>
            </div>
            <div className="right">
                <img className="ucla-made" src={uclamade} alt="UCLA Made" />
            </div>
        </div>
        <div className="copyright">
            &copy; {new Date().getFullYear()} UC Regents. All rights reserved.
        </div>
    </footer>
)

export default Footer
