import React from 'react'
import styled from 'styled-components'
import DocumentPreview from 'v2/components/preview/document'
import DocumentEditor from 'v2/components/edit/document'
import { DocumentManager } from 'v2/components/manager/document'
import { AllDocuments } from 'v2/components/edit/view'

const Wrapper = styled.div`
    display: flex;
    .doc-preview {
        width: 470px;
        padding: 20px;
    }

    .doc-edit {
        flex: 1;
    }

    .doc-all {
    }
`

// Demo dev: 414c007a33410a091e82b48295006a1d

const DocumentViewManager = ({ onDelete }) => {
    return (
        <DocumentManager>
            <Wrapper>
                <AllDocuments className="doc-all" />
                <DocumentPreview className="doc-preview" />
                <DocumentEditor className="doc-edit" />
            </Wrapper>
        </DocumentManager>
    )
}

DocumentViewManager.defaultProps = {
    header: {
        title: 'Untitled document',
        image: {},
    },
}

export default DocumentViewManager
