import React from 'react'
import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'
import _ from 'lodash'

const HeaderWrapper = styled.div`
    display: flex;
    padding: 4px;
    border: 1px solid #ced9e0;
    background: #fff;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    div {
        padding: 0 4px;
    }

    img {
        width: 30px;
    }

    .title {
        display: block;
        font-weight: bold;
    }
`
const RenderHeaderWrapper = styled.div`
    display: flex;
    align-items: center;

    // Hide the icon when it's the 'back' icon
    .bp3-panel-stack-header-back & {
        .image {
            display: none;
        }
    }
    img {
        width: 24px;
    }
    .image {
        margin-right: 4px;
    }
    .title {
        display: block;
        font-weight: bold;
    }
`

export const RenderHeader = ({ header }) => {
    const image = _.get(header, 'image.base64')
    const title = _.get(header, 'title')
    return (
        <RenderHeaderWrapper>
            <div className="image">
                {image ? (
                    <img
                        src={image}
                        alt={header.image.name || `Header image preview`}
                    />
                ) : null}
            </div>
            <div className="title">{title}</div>
        </RenderHeaderWrapper>
    )
}

RenderHeader.defaultProps = {
    header: {
        image: {},
        title: '',
    },
}

// @todo consolidate headers, this header draws a stylized border, so create a prop for that.
export const ModuleHeader = ({ header }) => {
    const image = _.get(header, 'image.base64')
    const title = _.get(header, 'title')

    return (
        <HeaderWrapper>
            <div className="image">
                {image ? (
                    <img
                        src={image}
                        alt={header.image.name || `Header image preview`}
                    />
                ) : (
                    <Icon icon="media" color="#5C7080" />
                )}
            </div>
            <div className="title">{title}</div>
        </HeaderWrapper>
    )
}

ModuleHeader.defaultProps = {
    header: {
        image: {},
        title: '',
    },
}
