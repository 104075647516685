import React from 'react'
import { Button, Divider } from '@blueprintjs/core'
import Title from '../../../modules/models/title'
import ImageDisplay from '../../../modules/models/image-display'
import ImageUpload from '../../../modules/models/image-upload'
import Connected from './connect'

class ButtonView extends React.Component {
    state = {
        isOpen: false,
    }
    _handleClose = () => {
        this.setState({ isOpen: false })
    }
    render() {
        const { activePath, id } = this.props
        const { isOpen } = this.state
        return (
            <React.Fragment>
                <Button
                    icon="edit"
                    minimal
                    onClick={() => this.setState({ isOpen: !isOpen })}
                />
                <Connected
                    activePath={activePath}
                    id={id}
                    isOpen={isOpen}
                    handleClose={this._handleClose}
                >
                    {({ data }, updateData) => (
                        <>
                            <ImageDisplay
                                {...data.image}
                                style={{ maxWidth: '100%' }}
                            />
                            <Divider />
                            <h3>Upload an image.</h3>
                            <ImageUpload
                                style={{ width: '100%' }}
                                {...data.image}
                                updateImage={image =>
                                    updateData({ ...data, image })
                                }
                            />
                            <Title
                                value={data.title}
                                onChange={title =>
                                    updateData({ ...data, title })
                                }
                            />
                        </>
                    )}
                </Connected>
            </React.Fragment>
        )
    }
}

ButtonView.defaultProps = {
    title: 'Button',
    description: 'Create a button.',
    descriptor: {
        type: 'button',
        data: {
            title: 'Button title',
            image: {},
        },
        navigation: {},
    },
    iconName: 'pt-icon-widget-header',
}

export default ButtonView
