import { combineReducers } from 'redux'
import _ from 'lodash'

import { pathToID, pathToIDArray } from 'v2/components/manager/utils'

const stack = (state = [], action) => {
    switch (action.type) {
        case 'stack/PUSH': {
            const _state = [action.payload, ...state]
            return _state
        }
        case 'stack/POP': {
            const _state = state.slice(-1)
            // _state.slice(-1)
            return _state
        }
        default:
            return state
    }
}

const editable = (state = {}, action) => {
    switch (action.type) {
        case 'editable/ADD':
            const { doc } = action.payload
            const _state = doc
            return _state

        case 'editable/DUPLICATE': {
            const _state = _.clone(state)
            const { id, clone } = action.payload
            const path = pathToID(_state, id)

            const _path = pathToIDArray(path)
            const _old = _.get(_state, _path)

            const _new = _old.concat([clone])
            _.set(_state, _path, _new)

            return _state
        }

        case 'editable/DELETE': {
            const _state = _.cloneDeep(state)
            const { id } = action.payload
            if (!id) {
                return state
            }

            const path = pathToID(_state, id)
            const _path = path.split('.')
            _path.pop()
            const __path = _path.join('.')
            const _arr = _.reject(_.get(_state, __path), ['id', id])
            _.set(_state, __path, _arr)

            return _state
        }
        case 'editable/ADD_TO_BODY': {
            const _state = _.cloneDeep(state)
            const { id, item } = action.payload
            const path = pathToID(_state, id)
            const _bodyPath = `${path}.body`
            const _oldBodyArr = _.get(_state, _bodyPath)

            const _newBodyArr = _oldBodyArr.concat([item])
            _.set(_state, _bodyPath, _newBodyArr)
            return _state
        }
        case 'editable/ADD_TO_PATH': {
            const _state = _.clone(state)
            const { item, path } = action.payload
            const _arr = _.clone(_.get(_state, path))
            _arr.push(item)
            _.set(_state, path, _arr)
            return _state
        }
        case 'editable/EDIT_DATA': {
            const _state = _.cloneDeep(state)
            const { id, data } = action.payload

            const path = pathToID(_state, id)

            const elem = _.get(_state, path)

            elem.data = data
            _.set(_state, path, elem)
            return _state
        }
        case 'editable/EDIT_DATA_BATCH': {
            const _state = _.cloneDeep(state)
            const { elements } = action.payload

            elements.forEach((item) => {
                const path = pathToID(_state, item.id)

                const elem = _.get(_state, path)

                elem.data = item.data
                _.set(_state, path, elem)
            })

            return _state
        }
        case 'editable/UPDATE_HEADER': {
            const _state = _.clone(state)
            const { header } = action.payload
            _.set(_state, 'header', header)
            return _state
        }

        default:
            return state
    }
}

const initialActiveState = { id: '', ids: [] }
const active = (state = initialActiveState, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_ID':
            const { id, ids } = action.payload
            return { id, ids }
        case 'CLEAR_ACTIVE_ID': {
            return initialActiveState
        }
        default:
            return state
    }
}

export default combineReducers({
    active,
    editable,
    stack,
})
