import React from 'react'
import mllogo from './assets/ml-logo.png'
import phoneshell from './assets/phone-shell.png'
import loadingposter from './assets/loading-poster.png'
import loadingposterfinal from './assets/loading-poster-final.png'
import zapdemovideo from './assets/zap-demo-v20180905.mp4'
import msgicon from './assets/msgicon.png'
import usersettings from './assets/usersettings.png'
import alert from './assets/alert.png'
import Footer from './footer'

class LandingPage extends React.Component {
    state = {
        isMenuVisible: false,
    }

    render() {
        return (
            <>
            <div role="main" id="landing-page">
                <div id="hero-stripe">
                    <p className="whitebg">
                        <strong>A powerful way to build your own app</strong>
                    </p>
                    <p className="start-button">
                        <a href="mailto:mobilizelabs@oarc.ucla.edu?Subject=ZAP%20Get%20Started%20Request">
                            Let's get started
                        </a>
                    </p>
                </div>

                <div id="mobilizelabs-signature-stripe">
                    <div className="ml-sig-center">
                        <p>
                            <img
                                className="ml-logo"
                                src={mllogo}
                                alt="Created by UCLA MobilizeLabs"
                            />
                            <br />
                            An innovative team of design thinking technologists
                        </p>
                    </div>
                    <div className="ml-sig-left" />
                    <div className="ml-sig-right" />
                </div>

                <div id="personalized-experience-stripe">
                    <h2>Create a Personalized Mobile Experience</h2>
                    <p>
                        ZAP lets you create, refresh and manage your mobile
                        content in real-time with ease
                    </p>
                    <div className="pex">
                        <div className="pex-center">
                            <div className="phone-shell">
                                <div className="phone-shell-inner">
                                    <div className="phone-shell-bg">
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            height="620"
                                            width="283"
                                            src={phoneshell}
                                        />
                                    </div>
                                </div>
                                <div className="phone-screen">
                                    <div className="phone-screen-inner">
                                        {/* TODO: add video*/}
                                        <video
                                            controls
                                            loop
                                            autoPlay
                                            muted
                                            playsInline
                                            id="video-1"
                                            poster={loadingposterfinal}
                                            height="585"
                                            width="270"
                                        >
                                            <source
                                                src={zapdemovideo}
                                                type="video/mp4"
                                            />
                                        </video>
                                        <img
                                            src={loadingposter}
                                            alt="video poster"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pex-left">
                            <ul>
                                <li id="config-dash">Configurable Dashboard</li>
                                <li id="rich-temp">Rich Templates</li>
                                <li id="dyn-cm">Dynamic Content Management</li>
                            </ul>
                        </div>
                        <div className="pex-right">
                            <ul>
                                <li id="map-int">Map Integration</li>
                                <li id="starter-kit">App Design Starter Kit</li>
                                <li id="easy-clone">Easily Clone Views</li>
                            </ul>
                        </div>
                    </div>
                    <p>
                        Leverage modern messaging solutions for your iOS and
                        Android users
                    </p>
                </div>

                <div id="extend-messaging-stripe">
                    <div id="extend">
                        <h2>Extend Your App with Custom Features</h2>
                        <ul>
                            <li id="notifications">
                                Notifications <br />
                                <span>(Local and Push)</span>
                            </li>
                            <li id="timelines">Timelines</li>
                            <li id="embed-videos">Embedded Videos</li>
                            <li id="cal-int">Calendar Integrations</li>
                            <li id="vr">VR</li>
                        </ul>
                    </div>

                    <div id="mlmessaging">
                        <div id="mlmessaging-left">
                            <h2>Engage with Mobilize Labs Messaging</h2>
                            <p>
                                Modern messaging increases app engagement with
                                real time communications.
                            </p>
                            <div id="msgicon">
                                <img alt="" src={msgicon} />
                            </div>
                        </div>
                        <div id="mlmessaging-right">
                            <div id="usersettings">
                                <img alt="" src={usersettings} />
                            </div>
                            <div id="alert">
                                <img alt="" src={alert} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </>
        )
    }
}

export default LandingPage
