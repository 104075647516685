import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Card, Divider, Callout } from '@blueprintjs/core'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Item from './item'

const defatulNewItem = {
    hidden: false,
    image: {},
    navigation: {},
    name: 'Rename me',
}

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? '#D8E1E8' : '#EBF1F5',

    // styles we need to apply on draggables
    ...draggableStyle,
})

const getListStyle = isDraggingOver => ({
    borderColor: isDraggingOver ? '#BFCCD6' : '#fff',
    padding: 8,
})

const DashboardList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px dashed #fff;
    border-radius: 4px;
`

const ItemWrapper = styled.li`
    margin: 0 0 10px 0;
    background-color: #ebf1f5;
`

class DashboardButtonEdit extends PureComponent {
    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const { dashboard, reorderDashboard } = this.props

        const _result = Array.from(dashboard.items)
        const [removed] = _result.splice(result.source.index, 1)
        _result.splice(result.destination.index, 0, removed)

        reorderDashboard(_result)
    }

    render() {
        const {
            dashboard,
            deleteItem,
            addItem,
            updateDashboardItem,
        } = this.props
        // const _update = throttle(updateDashboardItem, 2000)

        return (
            <Card>
                <Callout title="Edit the dashboard buttons">
                    You can edit the title and add images to the dashboard
                    button. You can also add/delete items and change the display
                    order by drag and drop.
                </Callout>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <DashboardList
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {dashboard.items.map((item, index) => (
                                    <Draggable
                                        key={`${item.name}-${index}`}
                                        draggableId={`${item.name}-${index}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <ItemWrapper
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                            >
                                                <Item
                                                    item={item}
                                                    index={index}
                                                    onChange={obj => {
                                                        updateDashboardItem(
                                                            obj,
                                                            index
                                                        )
                                                    }}
                                                    onDelete={() =>
                                                        deleteItem(index)
                                                    }
                                                />
                                            </ItemWrapper>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </DashboardList>
                        )}
                    </Droppable>
                </DragDropContext>
                <Divider />
                <Button
                    minimal
                    fill
                    icon="add-to-artifact"
                    onClick={() => addItem(defatulNewItem)}
                >
                    Add a dashboard item
                </Button>
            </Card>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        dashboard: state.dashboard,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateDashboardItem: (item, index) => {
            dispatch({
                type: 'DASHBOARD_UPDATE_ITEM',
                item,
                index,
            })
        },
        reorderDashboard: items =>
            dispatch({
                type: 'DASHBOARD_REORDER',
                payload: {
                    items,
                },
            }),
        addItem: item =>
            dispatch({
                type: 'DASHBOARD_ADD_ITEM',
                payload: {
                    item,
                },
            }),
        deleteItem: index =>
            dispatch({
                type: 'DASHBOARD_REMOVE_ITEM',
                payload: index,
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardButtonEdit)
