import React from 'react'
import { connect } from 'react-redux'
import {
    Tab,
    Tabs,
    Button,
    Dialog,
    Intent,
    Classes,
    InputGroup,
    Callout,
    Divider
} from '@blueprintjs/core'
import styled from 'styled-components'
import BodyEdit from './body'

const TabWrapper = styled.div`
    display: flex;
    margin: 10px 0;
`
const TabEdit = styled.div`
    padding: 8px;
    margin-right: 2px;
    border: 1px solid #ebf1f5;
    background: #f5f8fa;

    display: flex;
`

class TabEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            tabs: props.tabs,
        }
    }
    saveTabs() {
        const { onTabSave, moduleId } = this.props
        const path = `body.tabs`
        const data = this.state.tabs
        onTabSave(path, data, moduleId)
    }
    render() {
        const { tabs } = this.state
        return (
            <React.Fragment>
                <Callout>
                    Edit the tab titles or add/remove tabs. You cannot remove
                    the first two tabs.
                </Callout>
                <TabWrapper>
                    <React.Fragment>
                        {tabs.map((tab, index) => (
                            <TabEdit key={index}>
                                <InputGroup
                                    id="text-input"
                                    placeholder="Placeholder text"
                                    value={tab.title}
                                    small
                                    onChange={e => {
                                        const tab = tabs[index]
                                        tab.title = e.target.value
                                        let _tabs = [...tabs]
                                        _tabs.splice(index, 1, tab)
                                        this.setState({ tabs: _tabs })
                                    }}
                                />
                                {index >= 2 ? (
                                    <Button
                                        icon="delete"
                                        minimal
                                        onClick={() => {
                                            let _tabs = [...tabs]
                                            _tabs.splice(index, 1)
                                            this.setState({ tabs: _tabs })
                                        }}
                                    />
                                ) : null}
                            </TabEdit>
                        ))}
                    </React.Fragment>
                    <Button
                        text="Add a new tab"
                        icon="add"
                        minimal
                        onClick={() => {
                            let _tabs = [...tabs]
                            _tabs.push({ title: 'Untitled tab', body: [] })
                            this.setState({ tabs: _tabs })
                        }}
                    />
                </TabWrapper>
                {tabs.length > 2 ? (
                    <Callout intent={Intent.WARNING}>
                        Deleting a tab will also delete all it's content if you
                        commit the changes.
                    </Callout>
                ) : null}
            </React.Fragment>
        )
    }
}

class TabEditorWrapper extends React.Component {
    state = {
        isOpen: false,
        tabs: [],
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        const {
            preview,
            module: { body, _id },
            setRenderTabId,
            updateTabs,
        } = this.props
        const { isOpen } = this.state

        // Wait for body to be ready before attempting a render
        if (!body) {
            return null
        }

        let selectedTabId =
            preview.render && preview.render.selectedTabId
                ? preview.render.selectedTabId
                : 0

        return (
            <React.Fragment>
                <h4>Tabs</h4>
                <Callout>
                    You can edit the tab title or add more tab sections to your view.
                </Callout>
                <Tabs
                    selectedTabId={selectedTabId}
                    onChange={setRenderTabId}
                    renderActiveTabPanelOnly={true}
                >
                    {body.tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            id={index}
                            title={tab.title}
                            panel={
                                <BodyEdit
                                    body={tab.body}
                                    id={_id}
                                    editPath={`body.tabs[${selectedTabId}].body`}
                                />
                            }
                        />
                    ))}
                    <Tabs.Expander />
                    <Button
                        text="Edit tabs"
                        minimal
                        intent={Intent.PRIMARY}
                        onClick={() => this.setState({ isOpen: true })}
                    />
                </Tabs>
                <Divider />
                <Dialog
                    isOpen={isOpen}
                    title="Edit tabs"
                    onClose={this.handleClose}
                    style={{ width: 800 }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <TabEditor
                            ref={ref => (this.tabEditor = ref)}
                            tabs={body.tabs}
                            selectedTabId={selectedTabId}
                            onTabSave={updateTabs}
                            moduleId={_id}
                        />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button minimal onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <Button
                                minimal
                                intent={Intent.PRIMARY}
                                onClick={() => {
                                    this.handleClose()
                                    this.tabEditor.saveTabs()
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    module: state.modules[ownProps.id],
    preview: state.preview,
})

const mapDispatchToProps = dispatch => {
    return {
        setRenderTabId: id =>
            dispatch({
                type: 'PREVIEW_SET_TAB',
                payload: id,
            }),
        updateTabs: (activePath, data, id) =>
            dispatch({
                type: 'UPDATE_MODULE',
                payload: {
                    path: activePath,
                    data,
                    moduleId: id,
                },
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TabEditorWrapper)
