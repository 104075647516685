import produce from 'immer'

import { socket } from 'store/socket'

function saveDocument(token, payload, callback) {
    let doc

    if (payload.screen === 'MapView') {
        doc = produce(payload, (draftState) => {
            draftState.data.categories.forEach((category) => {
                delete category.hidden
            })

            draftState.data.locations.forEach((location) => {
                delete location.editable
                delete location.active
            })

            return draftState
        })
    }

    else {
        doc = payload
    }

    socket.emit('LOCK/RENEW', { token })
    socket.emit('MODULES/SAVE', { token, payload: doc }, callback)
}

export default saveDocument
