const materialIcons = [
    { value: 0xe3ab,  label: 'location_on' },
    { value: 0xe532,  label: 'restaurant' },
    { value: 0xe499,  label: 'person_pin_circle' },
    { value: 0xe39a,  label: 'local_mall' },
    { value: 0xe399,  label: 'local_library' },
    { value: 0xe3d8,  label: 'medical_services' },
    { value: 0xe1d5,  label: 'directions_bus' },
    { value: 0xe389,  label: 'local_activity' },
    { value: 0xe675,  label: 'train' },
    { value: 0xe39d,  label: 'local_parking' },
    { value: 0xe3a2,  label: 'local_police' },
    { value: 0xe478,  label: 'park' },
    { value: 0xe4e0,  label: 'power' },
    { value: 0xe30a,  label: 'help_center' },
    { value: 0xe55a,  label: 'science' },
    { value: 0xf085c, label: 'diversity_3' },
    { value: 0xe394,  label: 'local_gas_station' },
    { value: 0xe322,  label: 'hotel' },
    { value: 0xe60c,  label: 'storefront' },
    { value: 0xe3d2,  label: 'markunread_mailbox' },
    { value: 0xe040,  label: 'account_balance' },
    { value: 0xe393,  label: 'local_florist' },
    { value: 0xe6dc,  label: 'wc' },
    { value: 0xe1d2,  label: 'directions_bike' },
    { value: 0xe03e,  label: 'accessible' },
    { value: 0xe38d,  label: 'local_cafe' },
]

export default materialIcons
