import React from 'react'
import { InputGroup } from '@blueprintjs/core'

const Model = ({ value, onChange, className }) => (
	<InputGroup
		className={className}
		maxLength={100}
		placeholder="Edit me..."
		value={value}
		onChange={e => onChange(e.target.value)}
		small='true'
	/>
)

export default Model
