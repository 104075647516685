import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tab, Tabs, Spinner } from '@blueprintjs/core'
import Timeline from './renderer/timeline'
import { RenderBody, EmptyBody } from './renderer/body'
import RenderNearby from './renderer/nearby'

class ModuleRenderer extends React.Component {
    constructor(props) {
        super(props)

        const { getModule, module } = this.props

        if (!module.body && module._id) {
            getModule(module._id)
        }
    }

    render() {
        const {
            module,
            modules,
            preview,
            setRenderTabId,
            openPanel,
        } = this.props
        const { body } = module

        if (body) {
            // Render tabs
            if (body.tabs) {
                const { tabs } = body
                let selectedTabId =
                    preview.render && preview.render.selectedTabId
                        ? preview.render.selectedTabId
                        : 0
                return (
                    <Tabs
                        id="TabsExample"
                        onChange={setRenderTabId}
                        selectedTabId={selectedTabId}
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                id={index}
                                title={tab.title}
                                panel={
                                    <React.Fragment>
                                        <RenderBody
                                            module={module}
                                            openPanel={openPanel}
                                            body={tab.body}
                                            selectedTabId={index}
                                            modules={modules}
                                        />
                                    </React.Fragment>
                                }
                            />
                        ))}

                        <Tabs.Expander />
                    </Tabs>
                )
            }

            if (module.screen === 'TimelineView') {
                return <Timeline body={body} />
            }

            if (module.screen === 'NearbyView') {
                let selectedTabId =
                    preview.render && preview.render.selectedTabId
                        ? preview.render.selectedTabId
                        : 0
                return (
                    <RenderNearby
                        body={body}
                        module={module}
                        openPanel={openPanel}
                        modules={modules}
                        setRenderTabId={setRenderTabId}
                        selectedTabId={selectedTabId}
                    />
                )
            }

            return (
                <React.Fragment>
                    <RenderBody
                        module={module}
                        openPanel={openPanel}
                        body={body}
                        modules={modules}
                    />
                </React.Fragment>
            )
        }

        return (
            <EmptyBody>
                <Spinner size={Spinner.SIZE_LARGE} />
            </EmptyBody>
        )
    }
}

ModuleRenderer.propTypes = {
    module: PropTypes.object,
}

ModuleRenderer.defaultProps = {
    module: {},
}

const mapStateToProps = (state, ownProps) => {
    return {
        modules: state.modules,
        module: state.modules[ownProps.module.id],
        preview: state.preview,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRenderTabId: id =>
            dispatch({
                type: 'PREVIEW_SET_TAB',
                payload: id,
            }),
        getModule: id => {
            dispatch({ type: 'MODULES/GET', payload: id })
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleRenderer)
