import React from 'react'
import { connect } from 'react-redux'
import {
    Divider,
    Callout,
    Classes,
    Tag,
    Button,
    Popover,
    Menu,
    InputGroup,
} from '@blueprintjs/core'

class View extends React.Component {
    state = {
        newTag: '',
    }
    render() {
        const { dashboard, module } = this.props
        const { addTag, removeTag } = this.props
        const { tags } = module
        const { newTag } = this.state

        return (
            <Callout title="Tags">
                <div>
                    {tags
                        ? tags.map((tag, key) => (
                              <Tag
                                  key={key}
                                  onRemove={(e, tag) => {
                                      const { children } = tag
                                      removeTag(module._id, children)
                                  }}
                              >
                                  {tag}
                              </Tag>
                          ))
                        : null}
                </div>
                <Divider />
                <Popover
                    onOpening={() => this.setState({ newTag: '' })}
                    content={
                        <React.Fragment>
                            {dashboard.tags ? (
                                <Menu>
                                    <Menu.Divider title="Tags" />
                                    {dashboard.tags.map((tag, key) => (
                                        <Menu.Item
                                            text={tag}
                                            key={key}
                                            onClick={() => {
                                                addTag(module._id, tag)
                                            }}
                                        />
                                    ))}
                                    <Menu.Divider title="Create a new tag" />
                                </Menu>
                            ) : null}
                            <div style={{ padding: 8, display: 'flex' }}>
                                <InputGroup
                                    leftIcon="tag"
                                    value={newTag}
                                    onChange={e =>
                                        this.setState({
                                            newTag: e.target.value,
                                        })
                                    }
                                />
                                <div className={Classes.POPOVER_DISMISS}>
                                    <Button
                                        icon="tick"
                                        text="Create"
                                        minimal
                                        onClick={() => {
                                            if (newTag !== '') {
                                                addTag(module._id, newTag)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    target={
                        <Button
                            text="Add tag"
                            minimal
                            fill
                            small='true'
                            icon="tag"
                        />
                    }
                />
            </Callout>
        )
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard,
})
const mapDispatchToProps = dispatch => ({
    addTag: (id, tag) =>
        dispatch({
            type: 'TAG/ADD',
            payload: {
                moduleId: id,
                tag,
            },
        }),
    removeTag: (id, tag) =>
        dispatch({
            type: 'TAG/REMOVE',
            payload: {
                moduleId: id,
                tag,
            },
        }),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(View)
