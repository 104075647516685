import styled from 'styled-components'

export const Items = styled.ul`
    margin: 0;
    padding: 4px;
    overflow-y: scroll;
    height: 570px;

    &.half-button, &.half-button-image {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    &.half-button-image {
        li:first-child {
            grid-column-start: 1;
            grid-column-end: 3;

            padding: 0;

            img {
                max-width: 100%;
            }
        }
    }

    &.third-button, &.third-button-image {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &.third-button-image {
        li:first-child {
            grid-column-start: 1;
            grid-column-end: 4;

            padding: 0;

            img {
                max-width: 100%;
            }
            button {
                width: 100%;
            }
        }
    }

    &.list, &.list-image {
        padding: 0;
        background: #137CBD;
        li {
            border-bottom: 1px solid #2B95D6;

            button {
                color: #fff;
            }
        }
    }

    &.list, &.list-white, &.list-image, &.list-white-image {
        li {
            flex-direction: row;
            > span {
                width: 50px;
            }
        }
    }

    &.list-image, &.list-white-image {
        li:first-child {
            flex-direction: column;
            padding: 0;
            > span {
                width: 100%;
            }
            img {
                max-width: 100%;
                width: 100%;
            }
        }
    }


    &.vertical-with-blue-label {
        padding: 0;
        display: flex;
        flex-direction: column;

        li {
            margin: -2px;

            position: relative;
            padding: 0;
            span {
                float: left;
                height: unset;
            }
            img {
                width: 100%;
                max-width: 100%;
                float: left;
            }
            button {
                position: absolute;
                left: 0;
                top: 0;

                color: #fff;
                background: #3D72A9;
                font-weight: 300;
                padding: 4px 20px 4px 10px;
                font-size: 1.75rem;
                border-radius: 0;

                &:hover {
                    background: #3D72A9;
                }
            }
        }
    }

    &.ucla-alumni,
    &.ucla-faculty,
    &.ucla-friends,
    &.ucla-prospective,
    &.ucla-staff,
    &.ucla-students {
        li {
            flex-direction: column;
            padding: 0;

            > span, > button > span {
                width: 100%;

                > img {
                    max-width: 100%;
                    width: 100%;
                }
            }

            img {
                width: auto;
            }

            > button {
                background-color: transparent;
            }
        }
    }

    &.ucla-health {
        background-size: cover;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        li:first-child {
            grid-column-start: 1;
            grid-column-end: 4;

            padding: 0;

            img {
                max-width: 100%;
            }
            button {
                width: 100%;
            }
        }

        li {
            button {
                background-color: transparent;
                color: white;
            }
        }
    }
`

export const Item = styled.li`
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    padding: 10px;
    cursor: pointer;

    img {
        max-width: 125px;
        width: 100%;
    }

    border: 2px solid transparent;

    &:hover {
        border: 2px solid #FF6E4A;
    }

    &.dashboard-item button {
        text-align: center;
    }

    &.non-editable {
        cursor: default;

        &:hover {
            border-color: transparent;
        }
    }

    &.gray {
        background-color: #eeeeee;
    }
`

export const Image = styled.span`
    width: 100%;
`

export const Title = styled.div`
    text-align: center;
    padding: 6px;
    font-size: 0.8rem;
    color: #1f4b99;
    // font-weight: bold;
`

export const NoImage = styled.div`
    width: 100%;
    height: 100px;
    text-align: center;

    span {
        color: #ced9e0;
    }
`

export const Header = styled.div`
    background: #ddd;
    border-bottom: 1px solid #ccc;

    grid-column-start: 1;
    grid-column-end: 3;

    width: 100%;
    padding: 12px;

    text-align: center;

    color: #999;
`

export const Footer = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;

    background: #1c4e84;
    color: #fff;
    border-top: 1px solid #3a4250;

    padding: 3px 0;
    width: 100%;
    text-align: center;

    align-self: end;
    height: 57px;

    display: flex;
    justify-content: space-evenly;

    .icon {
        opacity: .25;
        width: 50px;
        height: 50px;
        border: 1px solid #fff;
        border-radius: 4px;
    }

    &.custom .icon {
        opacity: 1;
        width: 100px;
        border: 2px solid transparent;
        border-radius: 0;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            border-color: #ff6e4a;
        }

        &.disabled {
            cursor: default;

            &:hover {
                border-color: transparent;
            }
        }
    }

    &.ucla-alumni,
    &.ucla-faculty,
    &.ucla-friends,
    &.ucla-prospective,
    &.ucla-staff,
    &.ucla-students {
        background: white;

        img {
            width: 100%;
        }
    }

    &.ucla-health {
        background: #2774ae;
    }
`
