import React, { Component } from 'react'
import {
    Button,
    Dialog,
    Classes,
    Intent,
    Divider,
    InputGroup,
    Checkbox,
} from '@blueprintjs/core'
// import Editable from '../../modules/models/title'
import Icon from '../../modules/models/icon'

class Item extends Component {
    state = {
        image: '',
        name: '',
        background: false,
    }

    render() {
        const { isOpen, handleClose, handleSave, item } = this.props
        const { image, name, background } = this.state

        return (
            <Dialog
                autoFocus={true}
                isOpen={isOpen}
                usePortal={true}
                lazy={true}
                title={`Edit Dashboard Item`}
                onClose={handleClose}
                onOpening={() => this.setState({ ...item, background: item.background || false })}
            >
                <div className={Classes.DIALOG_BODY}>
                    <Icon
                        width="auto"
                        image={image}
                        onChange={image => this.setState({ image: image })}
                    />
                    <Divider />
                    <InputGroup
                        type="text"
                        value={name}
                        onChange={e => this.setState({ name: e.target.value })}
                    />
                    <Divider />
                    <Checkbox
                        label="Background"
                        checked={background}
                        onChange={e => this.setState({ background: e.target.checked })}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button minimal onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            minimal
                            intent={Intent.PRIMARY}
                            onClick={() => handleSave(this.state)}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default Item
