import React from 'react'
import Heading from '../../../modules/models/title'
import Editor from '../../../modules/models/text'
import Connected from './index'

const View = ({updatePath}) => (
    <div>
        <Connected path={`${updatePath}.title`}>
            {({ data }, updateData) => (
                <h4>
                    <Heading value={data} onChange={updateData} />
                </h4>
            )}
        </Connected>

        <Connected path={`${updatePath}.content`}>
            {({ data }, updateData) => (
                <Editor content={data.markdown} updateItem={updateData} />
            )}
        </Connected>
    </div>
)


View.defaultProps = {
    title: 'Text',
    description: 'Edit text.',
    descriptor: {
        type: 'timeline-entry',
        data: {
            content: {
                markdown: 'Edit me...',
            },
            title: 'Heading',
            type: [],
            reminders: [],
            day: 0,
        },
    },
    iconName: 'new-text-box',
}

export default View
