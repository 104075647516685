import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import {
    Tabs,
    Tab,
    Callout,
    Tag,
    Popover,
    Button,
    PopoverInteractionKind,
    Position,
} from '@blueprintjs/core'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import truncate from 'lodash/truncate'

const Wrapper = styled.div`
    .timeline-tabs {
        .bp3-tab-list {
            border: 1px solid #ccc;
            border-radius: 4px;
            margin: 4px;
            overflow: hidden;

            .bp3-tab-indicator-wrapper {
                background: #e1e8ed;
            }
            .bp3-tab-indicator {
                background: #5c7080;
            }

            .bp3-tab {
                padding: 4px 8px;
                margin-right: 4px;
            }
        }
    }
`

const Slide = styled.div`
    background: #f5f8fa;
    display: flex;
    flex-direction: column;
    height: 420px;
    align-items: center;
    justify-content: center;
`

const Content = styled.div`
    width: 70%;
    height: 90%;
    background: #ebf1f5;
    border: 1px solid #d8e1e8;
    padding: 0 10px;
    box-shadow: -5px 14px 33px -19px rgba(0, 0, 0, 0.35);

    display: flex;
    flex-direction: column;
    text-align: left;

    h3 {
        width: 100%;
    }

    .reminders {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-right: 4px;
                background: #bfccd6;
                border-radius: 4px;
                padding: 4px;
                margin-bottom: 4px;

                .title {
                    margin-top: 0;
                    margin-bottom: 4px;
                }
            }
        }
    }
`

const Metadata = styled.div`
    padding: 0 10px 4px;

    .reminders {
        display: flex;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-left: 10px;
        display: flex;

        li {
            margin-right: 4px;
        }
    }
`

const Reminders = ({ reminders }) => (
    <div className="reminders">
        <strong>Attached reminders</strong>
        <ul>
            {reminders.map((reminder, key) => (
                <li key={key}>
                    <h4 className="title">{reminder.title}</h4>
                    <div>
                        remind <strong>{reminder.type}</strong> at{' '}
                        <strong>{reminder.time}</strong>
                    </div>
                    <Popover
                        interactionKind={PopoverInteractionKind.HOVER}
                        popoverClassName="bp3-popover-content-sizing"
                        position={Position.BOTTOM}
                        content={
                            <ReactMarkdown children={reminder.content.markdown} />
                        }
                    >
                        <Button
                            minimal
                            fill
                            text={truncate(reminder.content.markdown, {
                                length: 30,
                            })}
                        />
                    </Popover>
                </li>
            ))}
        </ul>
    </div>
)

const Timeline = ({ body, title, reminderTypes }) => (
    <React.Fragment>
        <Metadata>
            <h3>{title}</h3>
            <div className="reminders">
                <strong>reminder types</strong>
                <ul>
                    {reminderTypes.map((type, key) => (
                        <li key={key}>
                            <Tag>{type}</Tag>
                        </li>
                    ))}
                </ul>
            </div>
        </Metadata>
        <Carousel emulateTouch={true} showIndicators={false} showThumbs={false}>
            {body.map((elem, key) => (
                <Slide key={key}>
                    <img
                        style={{ height: 0, display: 'none' }}
                        alt="Empty placeholder"
                    />
                    <Content>
                        <h3>{elem.title}</h3>
                        <ReactMarkdown children={elem.content.markdown} />
                        <Reminders reminders={elem.reminders} />
                    </Content>
                </Slide>
            ))}
        </Carousel>
    </React.Fragment>
)

export default class extends React.Component {
    state = {
        selectedTabId: 0,
    }

    handleTabChange = selectedTabId => {
        this.setState({ selectedTabId })
    }
    render() {
        const { body } = this.props
        const { selectedTabId } = this.state

        return (
            <Wrapper>
                <Tabs
                    className="timeline-tabs"
                    selectedTabId={selectedTabId}
                    onChange={this.handleTabChange}
                >
                    {body.map((timeline, key) => (
                        <Tab
                            key={key}
                            id={key}
                            title={timeline.type}
                            panel={
                                <Timeline
                                    reminderTypes={timeline.reminderTypes}
                                    title={timeline.title}
                                    body={timeline.body}
                                />
                            }
                        />
                    ))}
                </Tabs>
                <Callout icon="info-sign">
                    This is an all information view of the timeline. When a user
                    customizes it, they will see something tailored to their
                    preferences.
                </Callout>
            </Wrapper>
        )
    }
}
