import React, { useState } from 'react'
import classname from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import useInterval from '@use-it/interval'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import moment from 'moment'
import _ from 'lodash'
import {
    Button,
    Intent,
    Alert,
    Menu,
    Popover,
    Position,
    InputGroup,
    ButtonGroup,
    FormGroup,
    Tooltip,
} from '@blueprintjs/core'

import CreateView from 'v2/components/edit/create'
import DocumentStat from 'v2/components/edit/stat'
import { DocumentError } from 'v2/components/edit/error'
import {
    loaderState,
    documentState,
    panelStackState,
} from 'v2/components/manager/document'
import { useContextTools } from 'v2/components/manager/api'
import { socket } from 'store/socket'
import { getCanWrite } from 'store/selectors'

export const StyledDocumentIcon = styled.div`
    position: relative;
    padding: 8px 8px 4px 8px;
    font-weight: normal;

    background: #e1e7ec;
    box-shadow: 0 2px 0 0px rgba(0, 0, 0, 0.18);
    width: 220px;

    display: flex;

    .stat {
        margin-top: 10px;
    }
    .menu {
        margin-right: 4px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-width: 0 16px 16px 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.25) #fff;
    }

    &.selected {
        background: #ffd102;
    }

    .title {
        display: flex;
        align-items: center;

        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;

        .image img {
            height: 24px;
        }
    }

    .impressions {
        margin-bottom: 4px;
    }

    .last-update {
        margin-bottom: 10px;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 280px;
    padding: 20px;
    background-color: #f8f9fa;

    .bp3-button-group > .bp3-button {
        font-size: 12px;
    }

    ul {
        max-height: calc(100vh - 440px);
        overflow-y: scroll;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
            display: flex;
            margin-bottom: 4px;
            padding: 8px;
            border-radius: 4px;
            border: 2px solid transparent;
            cursor: pointer;

            &:hover {
                border-color: #ced9e0;
            }

            &.active {
                border-color: #48aff0;

                .bp3-button.delete {
                    display: none;
                }

                &:hover {
                    border-color: #2b95d6;
                }
            }

            .doc-actions {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 10px 0;
            }
        }
    }
`

const useDocumentLastUpdatedAt = (updated) => {
    const [last, setLast] = useState(moment(updated).fromNow())
    useInterval(() => {
        setLast(moment(updated).fromNow())
    }, 1000 * 60)

    return last
}

export const Document = ({ doc, selected, onDelete, onLoad, lock }) => {
    const analytics = useSelector((state) => state.analytics[doc._id])
    const canWrite = useSelector(getCanWrite)
    const impressions = _.isNumber(analytics) ? analytics : 0
    const lastUpdated = useDocumentLastUpdatedAt(doc.updated)
    const { title, hiddenTitle, image } = doc.header
    const src = image.url || image.base64
    let label = title

    if (hiddenTitle) {
        label = hiddenTitle
    }

    else if (src) {
        label = (
            <React.Fragment>
                {title}
                <span className="image">
                    <img src={src} alt="" />{' '}
                </span>
            </React.Fragment>
        )
    }

    let button

    if (canWrite) {
        if (lock) {
            let tooltip = 'Currently being edited by ' + lock.owner.name
            tooltip += ' (' + lock.owner.email + ')'

            button = (
                <Tooltip content={tooltip} position={Position.BOTTOM_RIGHT}>
                    <Button minimal disabled icon="lock" />
                </Tooltip>
            )
        }

        else {
            button = (
                <div className="menu">
                    <Popover
                        position={Position.BOTTOM}
                        content={
                            <Menu>
                                <Menu.Item
                                    text="Delete"
                                    icon="trash"
                                    onClick={onDelete}
                                />
                            </Menu>
                        }
                        target={<Button minimal={true} icon="caret-down" />}
                    />
                </div>
            )
        }
    }

    return (
        <StyledDocumentIcon className={classname({ selected })}>
            <div className="heading">
                <div className="title">
                    {button}
                    {label}
                </div>
                <div className="impressions">
                    <strong>{impressions}</strong> impressions
                </div>
                <div className="last-update">Updated {lastUpdated}</div>
            </div>
            <div className="stat">
                <DocumentStat doc={doc} />
            </div>
        </StyledDocumentIcon>
    )
}

export const AllDocuments = ({ org }) => {
    const analytics = useSelector(state => state.analytics)
    const locks = useSelector(state => state.locks)
    const dispatch = useDispatch()
    const setLoader = useSetRecoilState(loaderState)
    const setStack = useSetRecoilState(panelStackState)
    const currentDoc = useRecoilValue(documentState)
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [typeFilter, setTypeFilter] = useState(null)
    const [sort, setSort] = useState('impressions')
    const [sortAscending, setSortAscending] = useState(false)
    const { selectElement } = useContextTools()

    const _search = (item) => {
        const title = _.get(item, 'header.title', '').toLowerCase()
        const hiddenTitle = _.get(item, 'header.hiddenTitle', '').toLowerCase()
        const term = search.trim().toLowerCase()

        // If no search terms are entered, every view matches
        if (!term) {
            return true
        }

        return title.includes(term) || hiddenTitle.includes(term)
    }

    const _typeFilter = (item) => {
        const type = item.screen.replace('Dashboard', 'View')

        // If no type filter is set, every view matches
        if (!typeFilter) {
            return true
        }

        return type === typeFilter
    }

    let docs = useSelector(state => Object.keys(state.modules).map(key => (
        state.modules[key]
    )).filter(item => (
        item.version === 2 && _search(item) && _typeFilter(item)
    )))

    if (sort === 'title') {
        docs.sort((a, b) => {
            let aTitle = _.get(a, 'header.title')
            let bTitle = _.get(b, 'header.title')

            if (!aTitle) {
                aTitle = _.get(a, 'header.hiddenTitle', '')
            }
            if (!bTitle) {
                bTitle = _.get(b, 'header.hiddenTitle', '')
            }

            aTitle = aTitle.toLowerCase()
            bTitle = bTitle.toLowerCase()

            if (aTitle < bTitle) {
                return sortAscending ? -1 : 1
            }
            else if (aTitle > bTitle) {
                return sortAscending ? 1 : -1
            }
            else {
                return 0
            }
        })
    }

    else if (sort === 'updated') {
        docs.sort((a, b) => {
            const aUpdated = _.get(a, 'updated', '1970-01-01T12:00:00.000Z')
            const bUpdated = _.get(b, 'updated', '1970-01-01T12:00:00.000Z')

            if (aUpdated < bUpdated) {
                return sortAscending ? -1 : 1
            }
            else if (aUpdated > bUpdated) {
                return sortAscending ? 1 : -1
            }
            else {
                return 0
            }
        })
    }

    else if (sort === 'impressions') {
        docs.sort((a, b) => {
            let aImpressions = analytics[a._id]
            let bImpressions = analytics[b._id]

            if (!_.isNumber(aImpressions)) {
                aImpressions = 0
            }
            if (!_.isNumber(bImpressions)) {
                bImpressions = 0
            }

            if (aImpressions < bImpressions) {
                return sortAscending ? -1 : 1
            }
            else if (aImpressions > bImpressions) {
                return sortAscending ? 1 : -1
            }
            else {
                return 0
            }
        })
    }

    const _onLoadDocument = (doc) => {
        const { _id, header } = doc
        setLoader(() => ({
            load: _id,
            header,
        }))

        selectElement(null)
    }

    const _onDocumentDelete = (doc) => {
        dispatch({
            type: 'MODULES/DELETE',
            payload: {
                id: doc._id,
                rev: doc._rev,
            },
        })
    }

    const _onReturnToRoot = () => {
        setLoader(() => ({ clear: true }))
        setStack(() => [])
    }

    const _setSort = (type) => {
        // If the button was alredy active, invert the sort order
        if (type === sort) {
            setSortAscending(!sortAscending)
        }

        // Otherwise, change the sort type (with its default direction)
        else {
            setSort(type)
            setSortAscending(type === 'title')
        }
    }

    const sortButtons = ['Title', 'Updated', 'Impressions'].map((label) => {
        const type = label.toLowerCase()
        let icon

        if (sort === type) {
            icon = sortAscending ? 'chevron-up' : 'chevron-down'
        }

        return (
            <Button
                key={type}
                active={sort === type}
                rightIcon={icon}
                onClick={() => _setSort(type)}
            >
                {label}
            </Button>
        )
    })

    const docList = docs.map((doc) => {
        const lock = Object.values(locks).find(lock => (
            lock.moduleId === doc._id && lock.socketId !== socket.id
        ))

        return (
            <li
                key={doc._id}
                className={classname({
                    active: doc._id === currentDoc._id,
                })}
                onClick={() => _onLoadDocument(doc)}
            >
                <DocumentError doc={doc}>
                    <Document
                        onDelete={() => setDeleteAlertOpen(doc._id)}
                        doc={doc}
                        selected={doc._id === currentDoc._id}
                        lock={lock}
                    />
                </DocumentError>
            </li>
        )
    })

    return (
        <Wrapper>
            <CreateView org={org} />
            <Button
                outlined
                fill
                icon="tree"
                text="Return to the root"
                onClick={_onReturnToRoot}
                style={{ margin: '10px 0' }}
            />

            <FormGroup
                label={'Views (' + docs.length + ')'}
            >
                <InputGroup
                    placeholder="Search views"
                    type="search"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </FormGroup>

            <FormGroup label="Filter by view type">
                <ButtonGroup>
                    <Button
                        active={!typeFilter}
                        onClick={() => setTypeFilter(null)}
                    >
                        All
                    </Button>
                    <Button
                        active={typeFilter === 'View'}
                        onClick={() => setTypeFilter('View')}
                    >
                        Standard
                    </Button>
                    <Button
                        active={typeFilter === 'DataDrivenView'}
                        onClick={() => setTypeFilter('DataDrivenView')}
                    >
                        Data-Driven
                    </Button>
                    <Button
                        active={typeFilter === 'MapView'}
                        onClick={() => setTypeFilter('MapView')}
                    >
                        Map
                    </Button>
                </ButtonGroup>
            </FormGroup>

            <FormGroup label="Sort by">
                <ButtonGroup>
                    {sortButtons}
                </ButtonGroup>
            </FormGroup>

            <ul>
                {docList}
            </ul>

            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={() => setDeleteAlertOpen(false)}
                onConfirm={() => {
                    const [doc] = docs.filter(
                        (d) => d._id === isDeleteAlertOpen
                    )
                    _onDocumentDelete(doc)
                    setDeleteAlertOpen(false)
                }}
            >
                <p>Are you sure you want to delete this view?</p>
            </Alert>
        </Wrapper>
    )
}
