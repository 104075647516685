/* eslint-disable */
import { combineReducers } from 'redux'
import set from 'lodash/set'
import unset from 'lodash/unset'
import get from 'lodash/get'
import clone from 'lodash/clone'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import compact from 'lodash/compact'

const previewInitial = {
    parent: {type: 'root'}
}

const adminInitial = {
    progress: 0,
    orgViewLimit: 300
}

const keycloak = (state = {}, action) => {
    if (action.type === 'SET_KEYCLOAK') {
        return action.payload
    }
    return state
}

const preview = (state = previewInitial, action) => {
    switch (action.type) {
        case 'PREVIEW_SET_MODULE':
            return {...action.payload}
        case 'PREVIEW_SET_TAB':
            return {...state, render: {
                selectedTabId: action.payload,
            }}
        default:
            return state
    }
}

const editing = (state = [], action) => {
    switch (action.type) {
        case 'EDITING_ADD_MODULE':
        case '@@SOCKET/EDITING_ADD_MODULE':
            const _state = [...state]
            const index = state.findIndex((mod) => mod.id === action.payload)

            if (index !== -1) {
                _state.splice(index, 1, {id: action.payload, date: new Date()})
            } else {
                _state.push({id: action.payload, date: new Date()})
            }

            return _state

        default:
            return state
    }
}

const modules = (state = {}, action) => {
    switch (action.type) {
        case 'MODULE_UPDATE_METADATA': {
            let _state = {...state}
            const { moduleId, metadata } = action.payload
            let _module = _state[moduleId]
            _module.metadata = metadata
            _state[moduleId] = _module
            return _state
        }
        case 'MODULE_REMOVE_PARENT': {
            let _state = clone(state)
            const { payload } = action
            const { moduleId, parent } = payload
            let _module = _state[moduleId]

            if (_module.metadata.parents) {
                const index = _module.metadata.parents.findIndex((elem) => {
                    return isEqual(elem, parent)
                })

                if (index !== -1) {
                    _module.metadata.parents.splice(index, 1)
                    _state[moduleId] = _module
                }
            }

            return _state
        }
        case 'MODULE_ADD_PARENT': {

            let _state = {...state}
            const { payload } = action
            const { moduleId, parent } = payload
            let _module = {..._state[moduleId]}

            // Case: module has no parents.. create the parents array and store parent
            if (!_module.metadata.parents) {
                _module.metadata.parents = []
                _module.metadata.parents.push(parent)

            } else {
                // Case: module has a parent.. check if it's duplicate
                // if not, add it..
                const dupes = _module.metadata.parents.filter(currParent => {
                    return isEqual(currParent, parent)
                })


                if (dupes.length === 0) {
                    _module.metadata.parents.push(parent)
                }
            }

            _state[moduleId] = {..._module}
            return _state
        }
        case 'MODULE_ADD_TAG': {
            let _state = {...state}
            const { moduleId, tag } = action.payload
            let _module = _state[moduleId]

            if (_module.tags && !_module.tags.includes(tag)) {
                _module.tags.push(tag)
            } else if (!_module.tags) {
                _module.tags = [ tag ]
            }
            _state[moduleId] = _module

            return _state
        }
        case 'MODULE_REMOVE_TAG': {
            let _state = {...state}
            const { moduleId, tag } = action.payload
            let _module = _state[moduleId]

            if (_module.tags && _module.tags.includes(tag)) {
                const index = _module.tags.indexOf(tag)
                _module.tags.splice(index, 1)
            }
            _state[moduleId] = {..._module}

            return _state
        }
        case 'MODULE_REORDER': {
            let _state = { ...state }

            const { moduleId, result, path } = action.payload

            let _module = _state[moduleId]
            let body = get(_module, path)

            // Handles all cases of reordering
            // const res = get(_state, path)
            // console.log(res)
            const [removed] = body.splice(result.source.index, 1)
            body.splice(result.destination.index, 0, removed)

            let __module = set(
                { ..._state[moduleId] },
                path,
                body
            )

            _state[moduleId] = __module

            return _state
        }
        case 'MODULE_LINK': {
            let _state = { ...state }
            const { navigation, path } = action.payload

            const item = get(_state, path)
            item.navigation = navigation

            // console.log(item)
            return _state
        }
        case 'CREATE_MODULE': {
            const {_id} = action.payload

            let _state = {...state}
            _state[_id] = action.payload
            return _state
        }
        case 'ADD_VIEW_TO_MODULE': {
            const { moduleId, path, data } = action.payload

            let _state = { ...state }
            let _module = _state[moduleId]

            let body = get(_module, path)
            body.push({ ...data })

            let __module = set(
                { ..._state[moduleId] },
                path,
                body
            )

            _state[moduleId] = __module

            return _state
        }
        case 'UPDATE_MODULE': {
            const { moduleId, path, data } = action.payload
            let _state = {...state}
            let _module = set(
                { ..._state[moduleId] },
                path,
                data
            )
            _state[moduleId] = _module
            return _state
        }
        case 'DELETE_MODULE':
        case '@@SOCKET/DELETE_MODULE': {
            const { id, rev } = action.payload
            let _state = {...state}
            delete _state[id]
            return _state
        }
        case '@@SOCKET/UPDATE_MODULE_REVISION':
        case 'UPDATE_MODULE_REVISION': {
            const { id, rev } = action.payload
            let _state = {...state}
            _state[id]._rev = rev
            return _state
        }
        case 'REFRESH_MODULE':
        case '@@SOCKET/REFRESH_MODULE': {
            let _state = {...state}
            _state[action.payload._id] = action.payload
            return _state
        }
        case 'SET_MODULES':
        case '@@SOCKET/SET_MODULES':
            let _state = {}
            action.payload.forEach(mod => _state[mod._id] = mod)
            return _state
        case 'REMOVE_FROM_BODY': {
            let _state = {...state}
            let { path, moduleId } = action.payload
            let _path = path

            if (path.includes('module')) {
                _path = path.substring(`modules.${moduleId}.`.length)
            }

            let _module = {..._state[moduleId]}

            if (unset(_module, _path)) {
                // console.log(JSON.stringify(_module, null, 2))

                // NOTE: `unset` will leave `null` entries in array, so
                // we need to use `_.compact` to clear them out
                // having `null` entries will screw up the render
                // deep down the pipeline
                if (isArray(_module.body)) {
                    _module.body = compact(_module.body)
                }

                if (_module.body.tabs) {
                    _module.body.tabs.forEach(tab => {
                        tab.body = compact(tab.body)
                    })
                }

                // console.log(JSON.stringify(_module, null, 2))
                _state[moduleId] = _module
                return _state
            }

            console.error('FAIL: REMOVE_FROM_BODY')
            return state
        }
        default:
            return state
    }
}

const dashboardItems = (state = {}, action) => {
    return state
}

const navigation = (state = {}, action) => {
    switch (action.type) {
        case 'SET_NAVIGATION':
            return { ...state, ...action.payload }
        case 'NAVIGATION_SET_ORG': {
            let _state = { ...state, org: action.payload }
            return _state
        }
        case 'NAVIGATION_SET_TAB':
            return { ...state, selectedTab: action.payload }
        case 'NAVIGATION_SET_API':
            return { ...state, api: action.payload }
        case 'NAVIGATION_SET_ORGS':
            return { ...state, orgs: action.payload }
        case 'NAVIGATION_SET_MESSAGE':
        case '@@SOCKET/NAVIGATION_SET_MESSAGE': {
            let _state = { ... state}
            _state.messages.push(action.payload)
            return _state
        }
        // case '@@SOCKET/DELETE_MODULE': {
        //     const { id, rev } = action.payload
        //     if (state.selectedTab === `editing-${id}`) {
        //         return {...state, selectedTab: 'modules' }
        //     }
        //     return state
        // }
    }
    return state
}

const dashboard = (state = {}, action) => {
    switch (action.type) {
        case 'DASHBOARD_ADD_TAG': {
            let _state = {...state}
            const { tag } = action.payload

            if (_state.tags && !_state.tags.includes(tag)) {
                _state.tags.push(tag)
            } else if (!_state.tags) {
                _state.tags = [ tag ]
            }

            return _state
        }
        case 'DASHBOARD_UPDATE_METADATA':
            return {
                ...state,
                metadata: action.payload,
            }
        case 'SET_DASHBOARD':
        case '@@SOCKET/SET_DASHBOARD':
            return action.payload
        case 'DASHBOARD_ADD_ITEM': {
            let _state = { ...state }

            const { item } = action.payload
            _state.items.push(item)
            return _state
        }
        case 'DASHBOARD_REMOVE_ITEM': {
            let _state = { ...state }
            _state.items.splice(action.payload, 1)
            return _state
        }
        case 'DASHBOARD_UPDATE_ITEM': {
            const _items = state.items.map((item, i) => {
                const _item = item

                if (i === action.index) {
                    return action.item
                }
                else {
                    return {
                        ...item,
                        background: false,
                    }
                }
            })

            return { ...state, items: _items }
        }
        case 'DASHBOARD_LINK': {
            let _state = { ...state }

            const { navigation, path } = action.payload
            _state.items[path].navigation = navigation

            return _state
        }
        case 'DASHBOARD_REORDER': {
            let _state = { ...state, ...action.payload }
            return _state
        }
        case 'DASHBOARD_UPDATE_LAYOUT': {
            return {...state, layout: action.payload}
        }
    }
    return state
}

const admin = (state = adminInitial, action) => {
    switch (action.type) {
        case '@@SOCKET/ADMIN_SET_PROGRESS':
        case 'ADMIN_SET_PROGRESS':
            const { progress } = action
            let _state = {...state, progress}
            return _state
        default:
            return state
    }
}

const version = (state = {}, action) => {
    switch (action.type) {
        case 'SET_VERSION':
            return action.payload
        default:
            return state
    }
}

const analytics = (state = {}, action) => {
    switch (action.type) {
        case '@@SOCKET/SET_ANALYTICS':
            return action.payload
        default:
            return state
    }
}

const org = (state = {}, action) => {
    switch (action.type) {
        case 'CLEAR_ORG':
            return {}

        case '@@SOCKET/SET_ORG':
            // TODO: Get these awful hacks out, deal with org/version switching better
            if (window.location.pathname.startsWith('/admin')) {
                return action.payload
            }

            const isVersion1 = window.location.pathname === '/'
            const versions = Array.isArray(action.payload.versions) ? action.payload.versions : [1]

            if (isVersion1) {
                // Org only supports V2 *or* it defaults to V2 and we just switched orgs
                if (!versions.includes(1) || (versions[0] === 2 && action.payload.refresh)) {
                    window.location = 'start'
                }
            }

            else {
                // Org only supports V1 *or* it defaults to V1 and we just switched orgs
                if (!versions.includes(2) || (versions[0] === 1 && action.payload.refresh)) {
                    window.location = '/'
                }

                // If we're staying in V2 but just switched orgs, we need a hacky refresh
                else if (action.payload.refresh) {
                    window.location.reload()
                }
            }

            return action.payload

        default:
            return state
    }
}

const locks = (state = {}, action) => {
    let newState = { ...state }

    switch (action.type) {
        case '@@SOCKET/SET_LOCKS':
            return action.payload

        default:
            return state
    }
}

const roles = (state = null, action) => {
    switch (action.type) {
        case 'SET_ROLES':
            return action.payload

        default:
            return state
    }
}

export default combineReducers({
    dashboard,
    dashboardItems,
    modules,
    editing,
    keycloak,
    navigation,
    preview,
    admin,
    version,
    analytics,
    org,
    locks,
    roles,
})
