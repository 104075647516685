import React from 'react'
import { TextArea } from '@blueprintjs/core'

const Model = ({ content, onChange }) => (
	<TextArea
		className="pt-input pt-large pt-fill"
		large={true}
		onChange={val => onChange(val)}
		value={content}
		rows="auto"
		style={{ height: '150px' }}
	/>
)

export default Model
