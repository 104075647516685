import React, { useState } from 'react'
import { InputGroup } from '@blueprintjs/core'

import { useContextTools } from 'v2/components/manager/api'

const UrlEditor = ({ initialData, updatePath }) => {
    const [ data, setData ] = useState(initialData)
    const { updateStyle } = useContextTools()
    let placeholder

    const _onChange = (e) => {
        const value = e.target.value
        setData(value)
        updateStyle(value, updatePath)
    }

    if (updatePath === 'data.videoUrl') {
        placeholder = 'https://www.youtube.com/watch?v=???????????'
    }

    return (
        <InputGroup
            type="url"
            value={data}
            onChange={_onChange}
            placeholder={placeholder}
        />
    )
}

export default UrlEditor
