import React from 'react'
import styled from 'styled-components'
import { Icon, Callout } from '@blueprintjs/core'
import get from 'lodash/get'
import ContextPreview from '../modules/context'

const Item = styled.div`
    min-width: 250px;
    display: flex;
    align-items: center;

    border: 1px solid #ddd;
    padding: 4px 4px;

    .title {
        padding: 0 4px;
        font-size: 0.8rem;
        flex-grow: 1;
    }
`

const CompactPreview = styled.div`
    .bp3-icon-key-enter svg {
        transform: scale(-1, 1);
    }

    display: flex;
    .link {
        font-size: 0.75rem;
        background: #5c7080;
        color: white;
        padding: 2px 4px;
        border-radius: 2px;
        display: inline-block;
    }
    .unloaded {
        font-size: 0.75rem;
        background: #5c7080;
        color: white;
    }
    .link-button {
        margin-top: 4px;
    }
`

const Image = styled.img`
    height: 30px;
`

export const Parent = ({ modules, parent, compact }) => {
    const link = get(modules, parent.itemPath)

    if (link && !compact) {
        const renderImage = link.image ? (
            <Image src={link.image.base64} />
        ) : null

        return (
            <Item compact={compact}>
                <div>{renderImage}</div>
                <div className="title">{link.title}</div>
                <Icon icon="chevron-right" />
            </Item>
        )
    }

    if (compact) {
        return (
            <CompactPreview>
                {link ? (
                    <React.Fragment>
                        <Icon icon="key-enter" color="#5C7080" />
                        <div className="link-button">
                            <div className="link">{link.title}</div>
                        </div>
                    </React.Fragment>
                ) : null}
            </CompactPreview>
        )
    }

    return <Callout>Unable to render parent</Callout>
}

Parent.defaultProps = {
    compact: false,
}

export default () => <ContextPreview parent={Parent} />
