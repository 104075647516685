import React from 'react'
import styled from 'styled-components'
import List from '../../../modules/models/list'
import Icon from '../../../modules/models/icon'
import Title from '../../../modules/models/title'
import Description from '../../../modules/models/title'
import { Button, Label } from '@blueprintjs/core'
import Connected from './connect'

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 150px 1fr;
    padding: 4px;
    background: #fff;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 8px;
`

class View extends React.Component {
    state = {
        isOpen: false,
    }
    _handleClose = () => {
        this.setState({ isOpen: false })
    }
    render() {
        const { activePath, id } = this.props
        const { isOpen } = this.state
        return (
            <React.Fragment>
                <Button
                    icon="edit"
                    minimal
                    onClick={() => this.setState({ isOpen: !isOpen })}
                />
                <Connected
                    activePath={activePath}
                    id={id}
                    isOpen={isOpen}
                    handleClose={this._handleClose}
                >
                    {({ data }, updateData) => {
                        return (
                            <List
                                ref={(ref) => this.list = ref}
                                items={data}
                                path={activePath}
                                updateItem={updateData}
                                itemTemplate={{
                                    title: '',
                                    description: '',
                                    image: {},
                                    navigation: {},
                                }}
                            >
                                {({ item, index }, updateParent) => (
                                    <Wrapper>
                                        <Icon
                                            image={item.image}
                                            onChange={image => {
                                                updateParent(
                                                    {
                                                        ...item,
                                                        image,
                                                    },
                                                    index
                                                )
                                            }}
                                        />
                                        <Content>
                                            <div className="title">
                                                <Label>
                                                    Title
                                                    <Title
                                                        value={item.title}
                                                        onChange={title =>
                                                            updateParent(
                                                                {
                                                                    ...item,
                                                                    title,
                                                                },
                                                                index
                                                            )
                                                        }
                                                    />
                                                </Label>
                                            </div>
                                            <div className="desc">
                                                <Label>
                                                    Description{' '}
                                                    <span className="bp3-text-muted">
                                                        (optional)
                                                    </span>
                                                    <Description
                                                        value={
                                                            item.description ||
                                                            ''
                                                        }
                                                        onChange={description =>
                                                            updateParent(
                                                                {
                                                                    ...item,
                                                                    description,
                                                                },
                                                                index
                                                            )
                                                        }
                                                    />
                                                </Label>
                                            </div>
                                        </Content>
                                    </Wrapper>
                                )}
                            </List>
                        )
                    }}
                </Connected>
            </React.Fragment>
        )
    }
}


View.defaultProps = {
    title: 'List',
    description: 'Create a list of items.',
    descriptor: {
        type: 'list',
        data: [],
        order: 99999,
    },
    iconName: 'pt-icon-list',
}

export default View
