import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, Classes } from '@blueprintjs/core'
import { BodyV2 } from '../../components/auth/styled'
import DocumentViewManager from 'v2/components/document-manager'
import Toaster from '../../components/toaster'

// Loader for new document version
const Main = ({ module, buttonText }) => {
    // const dispatch = useDispatch()
    // useEffect(() => {
    //     dispatch({ type: 'MODULES/GETALL' })
    // }, [])

    const [isOpen, setOpen] = useState(false)
    const messages = useSelector((state) => state.navigation.messages)

    return (
        <>
            <Button
                text={buttonText}
                icon="cog"
                onClick={() => setOpen(true)}
                minimal
                fill
            />
            <Dialog
                isOpen={isOpen}
                usePortal={true}
                lazy={true}
                canOutsideClickClose={false}
                canEscapeKeyClose={false}
                title={`Edit View`}
                onClose={() => setOpen(false)}
                style={{ width: 1200 }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <BodyV2 role="main">
                        <Toaster messages={messages} />
                        <DocumentViewManager
                            id={module._id}
                            header={module.header}
                            onDelete={() => setOpen(false)}
                        />
                    </BodyV2>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            minimal={true}
                            text="Close"
                            onClick={() => setOpen(false)}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

Main.defaultProps = {
    module: {},
    buttonText: 'Edit View',
}

export default Main
