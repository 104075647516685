import React from 'react'
import {
    Menu,
    MenuItem,
    Button,
    Tooltip,
    FormGroup,
    InputGroup,
    Popover,
    Position,
} from '@blueprintjs/core'
import styled from 'styled-components'

import { EditMenuPopover, EditMenu } from 'v2/components/edit/menu'
import ColorPicker from 'v2/components/widgets/color'
import materialIcons from 'materialIcons'

const StyledRow = styled.div`
    display: flex;
    width: 100%;
    margin-top: 15px;
    padding: 6px;
    border: 2px solid #aebecd;
    border-radius: 2px;
    background-color: #e1e7ec;
    font-size: 16px;

    > .left {
        margin-right: 4px;
    }

    .body {
        flex: 1;

        > .content {
            display: flex;
            width: 100%;
            min-height: 50px;
            border-radius: 4px;

            > .empty-row {
                padding-left: 10px;
                padding-top: 8px;
                font-size: 14px;
                color: #5c7080;
            }
        }
    }
`

function DraggableCategory({
    index,
    category,
    usedCategories,
    lastCategory,
    onDeleteCategory,
    onUpdateCategory,
}) {
    const activeIcon = materialIcons.find(icon => icon.value === category.icon)

    const _updateName = (event) => {
        onUpdateCategory(category.id, { name: event.currentTarget.value })
    }

    const menuItems = materialIcons.map(icon => (
        <MenuItem
            key={icon.value}
            text={icon.label}
            onClick={() => onUpdateCategory(category.id, { icon: icon.value })}
        />
    ))

    const iconMenu = (
        <Menu
            className="material-icons"
            style={{
                color: category.color,
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
            }}
        >
            {menuItems}
        </Menu>
    )


    let deleteAction = (
        <Button
            minimal
            text="Delete Category"
            onClick={() => onDeleteCategory(category.id)}
        />
    )

    if (usedCategories[category.id]) {
        deleteAction = 'You cannot delete a category that is being used.'
    }
    else if (lastCategory) {
        deleteAction = 'You cannot delete all categories.'
    }

    return (
        <StyledRow>
            <div className="left">
                <EditMenuPopover>
                    <EditMenu>
                        {deleteAction}
                    </EditMenu>
                    <Tooltip content="Configure category">
                        <Button
                            minimal
                            className="row-button"
                            icon="cog"
                        />
                    </Tooltip>
                </EditMenuPopover>
            </div>

            <div className="body">
                <div style={{ display: 'block' }}>
                    <FormGroup
                        label="Name"
                        labelFor="name"
                    >
                        <InputGroup
                            id="name"
                            defaultValue={category.name}
                            onChange={_updateName}
                        />
                    </FormGroup>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormGroup label="Icon">
                            <Popover
                                content={iconMenu}
                                position={Position.RIGHT_TOP}
                            >
                                <Button
                                    text={activeIcon.label}
                                    className="material-icons"
                                    style={{ color: category.color }}
                                />
                            </Popover>
                        </FormGroup>

                        <FormGroup label="Color">
                            <ColorPicker
                                initialData={category.color}
                                updatePath={'root.data.categories[' + index + '].color'}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </StyledRow>
    )
}

const CategoryList = React.memo(function CategoryList({
    categories,
    usedCategories,
    onDeleteCategory,
    onUpdateCategory,
}) {
    return categories.map((category, i) => {
        return (
            <DraggableCategory
                key={category.id}
                index={i}
                category={category}
                usedCategories={usedCategories}
                lastCategory={categories.length === 1}
                onDeleteCategory={onDeleteCategory}
                onUpdateCategory={onUpdateCategory}
            />
        )
    })
})

export { CategoryList }
