import React, { useState } from 'react'
import {
    Button,
    Position,
    Popover,
    Menu,
    Classes,
    Dialog,
    Tooltip,
    Label,
    Icon,
    Divider,
    InputGroup,
    TextArea,
    Intent,
    FormGroup,
    Callout,
    Alert,
} from '@blueprintjs/core'
import _ from 'lodash'
import uuid from 'uuid/v4'
import TimelineReminderEditor from 'v2/components/widgets/timeline-reminder'
const Validator = require('jsonschema').Validator

const ReminderData = ({ data, options, onChange, onDelete }) => {
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false)
    const [isOpen, setOpen] = useState(false)
    return (
        <div className="ReminderDataRender editable-box">
            <div className="descriptor">
                <div className="label">reminder</div>
                <Popover position={Position.BOTTOM}>
                    <Button icon="menu" minimal="true" />
                    <Menu>
                        <Menu.Item
                            icon="edit"
                            text="Edit Reminder"
                            onClick={() => {
                                setOpen(true)
                            }}
                        />
                        <Menu.Divider />
                        <Menu.Item
                            intent={Intent.DANGER}
                            icon="trash"
                            text="Delete"
                            onClick={() => setDeleteAlertOpen(true)}
                        />
                    </Menu>
                </Popover>
            </div>
            <div className="type">{data.type}</div>
            <div className="time">{data.time}</div>
            <div className="text">{data.title}</div>
            <pre className="content text">{data.text.markdown}</pre>
            <TimelineReminderEditor
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                initialData={data}
                options={options}
                onChange={onChange}
            />
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={() => setDeleteAlertOpen(false)}
                onConfirm={() => {
                    onDelete()
                    setDeleteAlertOpen(false)
                }}
            >
                <p>
                    Are you sure you want to delete <b>{data.title}</b>{' '}
                    reminder?
                </p>
            </Alert>
        </div>
    )
}

const ReminderTypeEdit = ({
    reminders,
    reminder,
    onDelete,
    onDuplicate,
    onEdit,
}) => {
    const [isOpen, setOpen] = useState(false)
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false)

    return (
        <div className="edit-box">
            <div className="descriptor">
                <span className="label">reminder</span>{' '}
                <Popover position={Position.BOTTOM}>
                    <Button icon="menu" minimal="true" />
                    <Menu>
                        <Menu.Item
                            icon="edit"
                            text="Edit"
                            onClick={() => {
                                setOpen(true)
                            }}
                        />
                        <Menu.Item
                            icon="duplicate"
                            text="Duplicate"
                            onClick={onDuplicate}
                        />
                        <Menu.Divider />
                        <Menu.Item
                            intent={Intent.DANGER}
                            icon="trash"
                            text="Delete"
                            onClick={() => setDeleteAlertOpen(true)}
                        />
                    </Menu>
                </Popover>
            </div>
            <div className="type">{reminder.data.type}</div>
            <ReminderTypeDialog
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                reminders={reminders.filter(
                    (r) => r.data.type !== reminder.data.type
                )}
                initialData={reminder.data}
                onSave={(data) => {
                    const payload = {
                        ...reminder,
                        data,
                    }
                    onEdit(payload)
                }}
            />
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={() => setDeleteAlertOpen(false)}
                onConfirm={() => {
                    onDelete()
                    setDeleteAlertOpen(false)
                }}
            >
                <p>
                    Are you sure you want to delete <b>{reminder.data.type}</b>{' '}
                    reminder type? Deleting it will remove all reminders created
                    with this type.
                </p>
            </Alert>
        </div>
    )
}

const ReminderTypeAdd = ({ reminders, onReminderAdd }) => {
    const initialData = {
        type: '',
        title: '',
        description: '',
    }

    const [isOpen, setOpen] = useState(false)
    return (
        <>
            {_.isEmpty(reminders) ? (
                <Button
                    className="add"
                    minimal="true"
                    onClick={() => {
                        setOpen(true)
                    }}
                >
                    <Icon icon="plus" color="white" />{' '}
                    <span>Add Reminder Type</span>
                </Button>
            ) : (
                <Tooltip content="Add Reminder Type" position={Position.BOTTOM}>
                    <button
                        className="zap-btn"
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        <Icon icon="plus" color="white" />
                    </button>
                </Tooltip>
            )}
            <ReminderTypeDialog
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                reminders={reminders}
                initialData={initialData}
                onSave={(data) => {
                    const payload = {
                        type: 'Reminder',
                        id: uuid(),
                        data,
                    }

                    onReminderAdd(payload)
                }}
            />
        </>
    )
}

const ReminderTypeDialog = ({
    isOpen,
    onClose,
    reminders,
    initialData,
    onSave,
}) => {
    const not = reminders.map((r) => r.data.type)
    const schema = {
        id: '/Reminder',
        type: 'object',
        properties: {
            type: {
                type: 'string',
                minLength: 3,
                maxLenght: 10,
                not: { enum: not },
            },
            title: { type: 'string' },
            description: {
                type: 'string',
            },
        },
        required: ['type'],
    }

    const errorMap = {
        'instance.type': 'Reminder type',
    }

    const [data, setValue] = useState(initialData)
    const [errors, setErrors] = useState([])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Add reminder"
            onOpening={() => {
                setValue(initialData)
                setErrors([])
            }}
        >
            <div className={Classes.DIALOG_BODY}>
                {errors.length > 0 ? (
                    <Callout title="There was an error" intent={Intent.WARNING}>
                        <ul>
                            {errors.map((err, k) => (
                                <li key={k}>
                                    <div>
                                        <strong>
                                            {errorMap[err.property]}
                                        </strong>
                                    </div>
                                    <div>{err.stack}</div>
                                </li>
                            ))}
                        </ul>
                    </Callout>
                ) : null}
                <FormGroup
                    intent={Intent.PRIMARY}
                    label="Reminder type"
                    helperText="A reminder type is a unique identifier used to group timeline events on the device"
                    labelFor="text-input-type"
                    labelInfo="(required)"
                >
                    <InputGroup
                        id="text-input-type"
                        value={data.type}
                        onChange={(e) => {
                            const type = e.target.value
                                .replace(' ', '-')
                                .toLowerCase()
                            setValue({ ...data, type })
                        }}
                    />
                </FormGroup>
                <Divider />
                <Label>
                    Reminder title
                    <InputGroup
                        value={data.title}
                        onChange={(e) => {
                            setValue({ ...data, title: e.target.value })
                        }}
                    />
                </Label>
                <Divider />
                <Label>
                    Description
                    <InputGroup
                        value={data.description}
                        onChange={(e) => {
                            setValue({
                                ...data,
                                description: e.target.value,
                            })
                        }}
                    />
                </Label>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Cancel" onClick={onClose} minimal="true" />
                    <Button
                        text="Save changes"
                        intent={Intent.PRIMARY}
                        minimal="true"
                        onClick={() => {
                            const v = new Validator()
                            const res = v.validate(data, schema)

                            setErrors(res.errors)

                            if (res.valid) {
                                onSave(data)
                                onClose()
                            }
                        }}
                    />
                </div>
            </div>
        </Dialog>
    )
}

const ReminderAdd = ({ reminders, onAddReminder }) => {
    const options = reminders.map((r) => r.data.type)
    const schema = {
        id: '/Reminder',
        type: 'object',
        properties: {
            time: {
                type: 'string',
                minLength: 1,
                pattern: '^([0-9][0-9]?:[0-9][0-9]?|([0-9][0-9]?))( )?(pm|am)$',
            },
            type: { type: 'string', pattern: `^(${options.join(')|(')})$` },
            title: { type: 'string', minLength: 1 },
            text: {
                type: 'object',
                properties: {
                    markdown: { type: 'string' },
                },
            },
        },
        required: ['time', 'type', 'title', 'text'],
    }
    const initial = {
        time: '12:00pm',
        type: '',
        title: '',
        text: { markdown: '' },
    }

    const [isOpen, setOpen] = useState(false)
    const [data, setValue] = useState(initial)

    return (
        <>
            <Tooltip content="Add a new reminder" position={Position.BOTTOM}>
                <button
                    className="zap-btn"
                    onClick={() => {
                        setOpen(true)
                    }}
                >
                    <Icon icon="plus" color="white" />
                </button>
            </Tooltip>
            <Dialog
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                title="Add reminder"
                onOpening={() => setValue(initial)}
            >
                <div className={Classes.DIALOG_BODY}>
                    <Label>
                        Time (example: 12:00pm)
                        <InputGroup
                            value={data.time}
                            onChange={(e) => {
                                setValue({ ...data, time: e.target.value })
                            }}
                        />
                    </Label>
                    <Divider />
                    <Label>
                        Reminder type
                        <Menu>
                            {options.map((key) => (
                                <Menu.Item
                                    key={key}
                                    text={key}
                                    active={key === data.type}
                                    onClick={() =>
                                        setValue({ ...data, type: key })
                                    }
                                />
                            ))}
                        </Menu>
                    </Label>
                    <Divider />
                    <Label>
                        Title
                        <InputGroup
                            value={data.title}
                            onChange={(e) => {
                                setValue({
                                    ...data,
                                    title: e.target.value,
                                })
                            }}
                        />
                    </Label>
                    <Divider />
                    <Label>
                        Content (Markdown is allowed)
                        <TextArea
                            value={data.text.markdown}
                            fill="true"
                            onChange={(e) => {
                                setValue({
                                    ...data,
                                    text: {
                                        markdown: e.target.value,
                                    },
                                })
                            }}
                            growVertically={true}
                        />
                    </Label>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            text="Cancel"
                            onClick={() => setOpen(false)}
                            minimal="true"
                        />
                        <Button
                            text="Save changes"
                            intent={Intent.PRIMARY}
                            minimal="true"
                            onClick={() => {
                                const payload = {
                                    type: 'Reminder',
                                    id: uuid(),
                                    data,
                                }
                                const v = new Validator()

                                const res = v.validate(data, schema)

                                if (res.valid) {
                                    onAddReminder(payload)
                                    setOpen(false)
                                }
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export { ReminderTypeEdit, ReminderData, ReminderTypeAdd, ReminderAdd }
