import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Collapse, Button, Dialog, Classes, Checkbox } from '@blueprintjs/core'

import { useContextTools } from 'v2/components/manager/api'
import { zapStyles } from 'v2/components/widgets/definitions'
import ColorPicker from 'v2/components/widgets/color'
import NumberEditor from 'v2/components/widgets/number'
import PercentEditor from 'v2/components/widgets/percent'
import FontEditor from 'v2/components/widgets/gfonts'
import MultiLineEditor from 'v2/components/widgets/multiline'
import ImageEditor from 'v2/components/widgets/image'
import BoxShadowEditor from 'v2/components/widgets/boxShadow'
import WidgetBrowser from 'v2/components/widgets/browser'
import RichTextEditor from 'v2/components/widgets/text'
import PollEditor from 'v2/components/widgets/poll'
import UrlEditor from 'v2/components/widgets/url'
import LockedOverlay from '../../../components/lockedOverlay'

const Wrapper = styled.div`
    position: relative;
    width: 250px;
    max-height: calc(100vh - 130px);
    overflow-y: scroll;
    padding: 10px;
    background-color: #f8f9fa;

    pre {
        font-size: 0.6rem;
    }

    section {
        border-bottom: 1px solid #ccd4db;
        margin-bottom: 10px;
        padding-bottom: 10px;

        &:last-child {
            border-bottom: 0;
        }
    }

    label {
        display: inline-block;
        margin-bottom: 4px;
        font-size: 0.75rem;
        font-weight: 500;
        color: #404b5a;
        text-transform: capitalize;

        &.section {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.65rem;
            color: #6e7a8a;
        }
    }

    .collapse-section {
        margin-left: 4px;
    }

    .custom-editor {
        padding: 0 10px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            flex-direction: column;
            padding: 6px 10px;
        }
    }
`

const editors = {
    backgroundColor: ColorPicker,
    borderColor: ColorPicker,
    borderSize: NumberEditor,
    borderBottomColor: ColorPicker,
    borderBottomSize: NumberEditor,
    borderRightColor: ColorPicker,
    borderRightSize: NumberEditor,
    borderRadius: NumberEditor,
    paddingLeft: NumberEditor,
    paddingRight: NumberEditor,
    paddingTop: NumberEditor,
    paddingBottom: NumberEditor,
    marginLeft: NumberEditor,
    marginRight: NumberEditor,
    marginTop: NumberEditor,
    marginBottom: NumberEditor,
    textColor: ColorPicker,
    value: MultiLineEditor,
    width: PercentEditor,
    height: PercentEditor,
    maxLines: NumberEditor,
    boxShadow: BoxShadowEditor,
    poll: PollEditor,
    videoUrl: UrlEditor,
}

const CollapsibleList = ({ label, data, updatePath }) => {
    const [isOpen, setOpen] = useState(false)
    return (
        <div className="collapse-section">
            <Button
                minimal={true}
                small={true}
                onClick={() => setOpen(!isOpen)}
                icon={isOpen ? 'chevron-down' : 'chevron-right'}
            />
            <label>{label}</label>
            <Collapse isOpen={isOpen}>
                <ul className="collapsible">
                    {Object.keys(data).map((key, i) => {
                        const Editor = editors[key]
                        const [, labelText, labelText2] = key
                            .replace(/([a-z](?=[A-Z]))/g, '$1 ')
                            .split(' ')
                        return (
                            <li key={i}>
                                <label>
                                    {labelText} {labelText2}
                                </label>
                                {Editor ? (
                                    <Editor
                                        initialData={data[key]}
                                        updatePath={`${updatePath}data.${key}`}
                                    />
                                ) : null}
                            </li>
                        )
                    })}
                </ul>
            </Collapse>
        </div>
    )
}

const filterObject = (data, path) => {
    return Object.keys(data)
        .filter((key) => key.includes(path))
        .reduce((accumulator, currentValue) => {
            accumulator[currentValue] = data[currentValue]
            return accumulator
        }, {})
}

const ContextSection = ({ label, data, updatePath }) => {
    const border = filterObject(data, 'border')
    const padding = filterObject(data, 'padding')
    const margin = filterObject(data, 'margin')

    const fonts = Object.keys(data).filter((key) => key.includes('font'))
    const image = Object.keys(data).filter((key) => key.includes('image'))

    const rest = Object.keys(data)
        .filter(
            (key) =>
                !key.includes('border') &&
                !key.includes('padding') &&
                !key.includes('margin') &&
                !key.includes('font') &&
                !key.includes('image')
        )
        .reduce((accumulator, currentValue) => {
            accumulator[currentValue] = data[currentValue]
            return accumulator
        }, {})

    return (
        <section>
            <label className="section">
                {label === 'RichText' ? 'Text' : label}
            </label>

            {!_.isEmpty(image) ? (
                <div className="image-editor custom-editor">
                    <label>image</label>
                    <ImageEditor
                        initialImage={data.image}
                        updatePath={`${updatePath}data.image`}
                    />
                </div>
            ) : null}
            {!_.isEmpty(fonts) ? (
                <div className="font-editor custom-editor">
                    <label>font</label>
                    <FontEditor
                        initialFont={data.fontFamily}
                        initialWeight={data.fontWeight}
                        updatePath={`${updatePath}data`}
                    />
                    <label>font size</label>
                    <NumberEditor
                        initialData={data.fontSize}
                        updatePath={`${updatePath}data.fontSize`}
                    />
                </div>
            ) : null}
            <ul>
                {Object.keys(rest).map((key, i) => {
                    let Editor = editors[key]
                    let prettyLabel = key.replace(/([a-z](?=[A-Z]))/g, '$1 ')

                    if (label === 'RichText' && key === 'value') {
                        Editor = RichTextEditor
                    }
                    else if (key === 'videoUrl') {
                        prettyLabel = 'YouTube URL'
                    }
                    else if (key === 'textAlign') {
                        prettyLabel = ''
                    }

                    return (
                        <li key={i}>
                            <label>
                                {prettyLabel}
                            </label>
                            {Editor ? (
                                <Editor
                                    initialData={data[key]}
                                    updatePath={`${updatePath}data.${key}`}
                                />
                            ) : null}
                        </li>
                    )
                })}
            </ul>
            {!_.isEmpty(border) ? (
                <CollapsibleList
                    label="border"
                    data={border}
                    updatePath={updatePath}
                />
            ) : null}
            {data.hasOwnProperty('poll') ? null : (
                <CollapsibleList
                    label="padding"
                    data={padding}
                    updatePath={updatePath}
                />
            )}
            {data.hasOwnProperty('poll') ? null : (
                <CollapsibleList
                    label="margin"
                    data={margin}
                    updatePath={updatePath}
                />
            )}
        </section>
    )
}

const ContextTools = () => {
    const [ isDialogOpen, setDialogOpen ] = useState(false)
    const [ selectedStyle, setSelectedStyle ] = useState(null)
    const { context, setStyle, updateStyle } = useContextTools()
    let contextSection = null

    if (!context.selected) {
        return (
            <Wrapper />
        )
    }

    const { data, body, id, style, dismissible } = context.selected

    if (!style) {
        contextSection = (
            <Button
                fill
                text="Choose a container style"
                onClick={() => setDialogOpen(true)}
            />
        )
    }

    else if (!zapStyles[style]) {
        return (
            <Wrapper>
                This widget uses custom code and cannot be modified.
                <LockedOverlay />
            </Wrapper>
        )
    }

    else {
        contextSection = (
            <React.Fragment>
                <Button
                    fill
                    text="Change container style"
                    onClick={() => setDialogOpen(true)}
                />

                <Checkbox
                    label="Dismissible"
                    checked={!!dismissible}
                    onChange={e => updateStyle(e.target.checked, 'dismissible') }
                    style={{ marginTop: '10px' }}
                />

                <ContextSection
                    key={id}
                    label="Container"
                    data={data}
                    updatePath=""
                />

                {body.map((elem, i) => (
                    <ContextSection
                        key={`${id}-${i}-${style}`}
                        label={elem.type}
                        data={elem.data}
                        updatePath={`body.[${i}].`}
                    />
                ))}
            </React.Fragment>
        )
    }

    return (
        <Wrapper>
            <Dialog
                isOpen={isDialogOpen}
                title="Choose a container style"
                onClose={() => setDialogOpen(false)}
                onOpened={() => setSelectedStyle(null)}
                style={{ width: '1100px', height: '550px' }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <WidgetBrowser
                        defaultType={style}
                        onSelectStyle={(s) => setSelectedStyle(s)}
                    />
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            minimal
                            text="Cancel"
                            onClick={() => setDialogOpen(false)}
                        />
                        <Button
                            minimal
                            text="OK"
                            disabled={selectedStyle === null}
                            onClick={() => {
                                setStyle(selectedStyle.style)
                                setDialogOpen(false)
                            }}
                        />
                    </div>
                </div>
            </Dialog>

            {contextSection}
        </Wrapper>
    )
}

export default ContextTools
