import React, { useState } from 'react'
import { NumericInput, Tag } from '@blueprintjs/core'
import styled from 'styled-components'
import _ from 'lodash'

import { useContextTools } from 'v2/components/manager/api'

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    > span {
        margin-right: 10px;
    }
`

const PercentEditor = ({ initialData, updatePath }) => {
    const [ value, setValue ] = useState(initialData * 100)
    const { updateStyle } = useContextTools()

    const _onChange = (num, str) => {
        setValue(str)
        if (!isNaN(_.parseInt(str))) {
            updateStyle(_.parseInt(str) / 100, updatePath)
        }
    }

    return (
        <Wrapper>
            <NumericInput
                small
                min={0}
                max={100}
                value={value}
                onValueChange={_onChange}
                rightElement={<Tag>%</Tag>}
            />
        </Wrapper>
    )
}

export default PercentEditor
