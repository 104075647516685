import React from 'react'
import { connect } from 'react-redux'
import { Callout } from '@blueprintjs/core'
import isEmpty from 'lodash/isEmpty'
import EmptyDocument from './empty'
import WebDocument from './web'
import RenderDocument from './render'
import PhoneDocument from './phone'
import AppLaunchDocument from './appLaunch'

class DocumentRenderer extends React.Component {

    render() {
        // console.log('document render---', this.props.preview)
        const { preview, openPanel } = this.props

        // If no navigation, then show the linker in the device preview
        if (isEmpty(preview.navigation)) {
            return <EmptyDocument parent={preview.parent} />
        }

        if (!isEmpty(preview.navigation.url)) {
            return <WebDocument url={preview.navigation.url} />
        }

        if (!isEmpty(preview.navigation.module)) {
            return (
                <RenderDocument
                    module={preview.navigation.module}
                    openPanel={openPanel}
                />
            )
        }

        if (!isEmpty(preview.navigation.phone)) {
            return <PhoneDocument phone={preview.navigation.phone} />
        }

        if (!isEmpty(preview.navigation.appLaunch)) {
            return (
                <AppLaunchDocument
                    iosUrl={preview.navigation.appLaunch.iosUrl}
                    iosStoreUrl={preview.navigation.appLaunch.iosStoreUrl}
                    androidUrl={preview.navigation.appLaunch.androidUrl}
                />
            )
        }

        return <Callout>Unable to render this document</Callout>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        preview: state.preview,
    }
}
const mapDispatchToProps = dispatch => ({
    loadModule: payload => {
        dispatch({ type: 'MODULES/EDIT', ...payload })
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentRenderer)
