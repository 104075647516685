import React from 'react'
import styled from 'styled-components'
import './devices.min.css'
// import './preview.scss'

const Screen = styled.div`

    .bp3-panel-stack {
        position: initial;
    }
    .bp3-panel-stack-header {
        margin-top: 40px;
        height: 40px;
    }

    box-sizing: border-box;
`

const Device = ({ children }) => (
    <div className="marvel-device iphone-x">
        <div className="notch">
            <div className="camera"></div>
            <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
            <div className="shadow shadow--tr"></div>
            <div className="shadow shadow--tl"></div>
            <div className="shadow shadow--br"></div>
            <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">
            {children}
        </div>
    </div>
)

class iPhoneDevice extends React.Component {

    render() {
        const { children } = this.props
        return (
            <Device>
                <Screen>
                    {children}
                </Screen>
            </Device>
        )
    }
}

export default iPhoneDevice
