import React, { createContext, useContext, useReducer } from 'react'
import _ from 'lodash'
import rootReducer from './reducers'

const ManagerContext = createContext()

const initialState = {
    editable: {},
    active: { id: '', ids: [] },
    stack: [],
}


const _processBody = (item,  target, acc) => {
    if (_.isEmpty(item)) {
        return null
    }
    // console.log(item.id, target)
    if (item.id === target) {
        return acc
    }
    if (_.isEmpty(item.body)) {
        return null
    }

    for (var i = item.body.length - 1; i >= 0; i--) {
        const res = _processBody(item.body[i], target, acc)
        if (res != null) {
            acc.push(item.id)
            return res
        }
    }
}

export const activeStateAction = (editable, id) => {
    let ids = []

    // console.log(editable)
    editable.body.forEach((item) => {
      const res = _processBody(item, id, [])
      // console.log('res', res)
      if (res != null) {
        ids = res
      }
    })

    return {
        type: 'SET_ACTIVE_ID',
        payload: { id, ids },
    }
}

export const ManagerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(rootReducer, initialState)
    return (
        <ManagerContext.Provider value={{ state, dispatch }}>
            {children}
        </ManagerContext.Provider>
    )
}

export const useManager = () => useContext(ManagerContext)
