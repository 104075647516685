import { Router } from '@reach/router'

import Header from './components/header'
import Auth from './components/auth/index'
import V1 from './components/auth/main'
import V2 from 'v2/components/main'
import Admin from './components/admin'
import Footer from './components/auth/footer'

function App() {
  let className
  let v2Footer

  if (window.location.pathname === '/start') {
    className = 'v2'

    v2Footer = (
      <Footer />
    )
  }

  return (
    <div id="page" className={className}>
      <Header />

      <Auth>
        <Router>
          <V1 path="/" />
          <V2 path="/start" />
          <Admin path="/admin" />
        </Router>
      </Auth>

      {v2Footer}
    </div>
  )
}

export default App
