import React from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Callout,
    InputGroup,
    Icon,
    ButtonGroup,
    Intent,
    Divider,
} from '@blueprintjs/core'
import styled from 'styled-components'
import classnames from 'classnames'
import get from 'lodash/get'

const List = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
    margin-bottom: 4px;

    overflow-x: scroll;
`

const Wrapper = styled.li`
    min-height: 200px;
    min-width: 175px;
    border: 1px solid #ced9e0;
    border-radius: 3px;
    background: #f5f8fa;
    margin-right: 8px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &.selected {
        border: 2px solid #0f9960;
    }

    .doc {
        display: flex;

        .icon {
            width: 24px;
        }

        .identifier {
            display: flex;
        }

        flex-grow: 2;
    }

    label {
        color: #8a9ba8;
        font-size: 0.75rem;
    }

    .id {
        font-size: 1rem;
        width: 95px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .rev {
        font-size: 1rem;
        font-weight: bold;
        margin-right: 10px;
    }

    h3 {
        margin: 0;
        margin-top: 4px;
        line-height: 1;
        font-size: 1rem;
    }

    .title {
        display: flex;
        align-items: top;
    }

    .desc {
        flex-grow: 2;
    }
`

const HeaderWrapper = styled.div`
    display: flex;
    padding: 4px;
    border: 1px solid #ced9e0;
    background: #e1e8ed;
    align-items: center;

    margin-bottom: 10px;

    div {
        padding: 0 4px;
    }

    img {
        width: 24px;
    }

    .title {
        flex-grow: 1;
        text-align: center;
        display: inline;
        font-weight: bold;
    }
`

const Header = ({ header }) => (
    <HeaderWrapper>
        <div>
            {header.image && header.image.base64 ? (
                <img
                    src={header.image.base64}
                    alt={header.image.name || `Header image preview`}
                />
            ) : (
                <Icon icon="media" />
            )}
        </div>
        <div className="title">{header.title}</div>
    </HeaderWrapper>
)

const Item = ({ item, onSelect, className }) => (
    <Wrapper className={className}>
        <Header header={item.header} />
        <div className="doc">
            <div className="icon">
                <Icon icon="document" />
            </div>
            <div>
                <div className="title">
                    <h3>{get(item, 'metadata.title')}</h3>
                </div>
                <label>revision</label>
                <div className="identifier">
                    <span className="rev">{item._rev.split('-')[0]}</span>
                    <span className="id">{item._id}</span>
                </div>
                <label>description</label>
                <div className="desc">
                    {get(item, 'metadata.description', 'none')}
                </div>
            </div>
        </div>
        <Button
            text="Select view"
            fill
            minimal
            onClick={() => onSelect(item._id, item.screen)}
        />
    </Wrapper>
)

class ModulesLinker extends React.Component {
    constructor(props) {
        super(props)

        const { modules } = props

        this.state = {
            modules,
            selected: false,
            sortFilter: 'metadata.title',
        }
    }
    _onSearch(filter) {
        const { modules } = this.props

        if (filter === '') {
            this.setState({ modules })
            return
        }

        const textToFind = filter.toLowerCase()

        const _modules = Object.keys(modules)
            .map(index => modules[index])
            .filter(mod => {
                const { metadata, header, _id } = mod
                return (
                    metadata.title.toLowerCase().includes(textToFind) ||
                    metadata.description.toLowerCase().includes(textToFind) ||
                    header.title.toLowerCase().includes(textToFind) ||
                    _id.toLowerCase().includes(textToFind)
                )
            })

        this.setState({ modules: _modules })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ modules: nextProps.modules })
    }

    render() {
        const { modules, selected, module, sortFilter } = this.state
        const { onLink, parent } = this.props

        const modulesArr = Object.keys(modules)
            .map(key => modules[key])
            .filter(mod => {
                // Prevent the parent from being linked...
                if (parent && parent.type === 'module' && parent.id) {
                    if (parent.id === mod._id) {
                        return false
                    }
                }
                return true
            })
            .sort((a, b) => {
                if (
                    get(a, sortFilter, '').toLowerCase() <
                    get(b, sortFilter, '').toLowerCase()
                ) {
                    return -1
                }
                if (
                    get(a, sortFilter, '').toLowerCase() >
                    get(b, sortFilter, '').toLowerCase()
                ) {
                    return 1
                }
                return 0
            })
        return (
            <Callout title="Load existing view">
                <InputGroup
                    placeholder="Search views"
                    type="search"
                    dir="auto"
                    onChange={e => this._onSearch(e.target.value)}
                />
                <h4>Sort views by</h4>
                <ButtonGroup minimal small='true'>
                    <Button
                        text="Document title"
                        active={sortFilter === 'metadata.title'}
                        onClick={() =>
                            this.setState({ sortFilter: 'metadata.title' })
                        }
                    />
                    <Button
                        text="Header title"
                        active={sortFilter === 'header.title'}
                        onClick={() =>
                            this.setState({ sortFilter: 'header.title' })
                        }
                    />
                </ButtonGroup>
                <List>
                    {modulesArr.map((module, index) => (
                        <Item
                            key={index}
                            className={classnames({
                                selected: selected === module._id,
                            })}
                            item={module}
                            onSelect={(id, screen) => {
                                this.setState({
                                    selected: id,
                                    module: {
                                        id,
                                        screen,
                                    },
                                })
                            }}
                        />
                    ))}
                </List>
                <div>Once you have selected, click to link view.</div>
                <Divider />
                <Button
                    text="Link to view"
                    minimal
                    fill
                    intent={Intent.PRIMARY}
                    disabled={!selected}
                    onClick={() => onLink({ module: module })}
                />
            </Callout>
        )
    }
}

ModulesLinker.defaultProps = {
    modules: {},
}

const mapStateToProps = (state, ownProps) => {
    return {
        modules: state.modules,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        linkModule: (type, navigation, path) => {
            dispatch({
                type: `MODULES/LINK`,
                payload: {
                    type,
                    navigation,
                    path,
                },
            })
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModulesLinker)
