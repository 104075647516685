import React, { useState } from 'react'
import _ from 'lodash'
import { Dialog } from '@blueprintjs/core'
import styled from 'styled-components'

import View from 'v2/components/preview/render/view'
import config from '../../../config'

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    font-size: 12px;

    button {
        flex: 1;
        margin: 10px auto 0 auto;
    }
`

const PreviewButton = styled.button`
    border: 0;
    padding: 10px 20px;
    background: #ffd102;
    box-shadow: 2px 2px 0 0 rgba(40, 116, 174, 0.28);
    border-radius: 3px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
`

const PreviewDialog = ({ isOpen, onClose, doc }) => {
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const lastRow = doc.body.length ? doc.body.at(-1) : {}
    const offsetParameter = _.get(lastRow, 'dataSource.offsetParameter', null)

    const fetchRows = async (init) => {
        setIsLoading(true)

        const api = config.nonSocketsApi.url
        let url = `${api}/apps/modules/${doc._id}`
        if (!init) {
            url += `?page=${page}`
        }

        const response = await fetch(url)
        const json = await response.json()

        setRows(prevRows => init ? json.body : [...prevRows, ...json.body])
        setPage(prevPage => init ? 2 : prevPage + 1)
        setIsLoading(false)
    }

    const scroll = (event) => {
        if (!isLoading && event.currentTarget.scrollTop + 1000 > event.currentTarget.scrollHeight) {
            fetchRows()
        }
    }

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Live Preview"
            onOpening={() => fetchRows(true)}
            style={{ width: '375px' }}
        >
            <View
                doc={{ body: rows }}
                onScroll={offsetParameter === null ? null : scroll}
            />
        </Dialog>
    )
}

const DataDrivenPreview = ({ doc }) => {
    const [isOpen, setOpen] = useState(false)

    return (
        <Wrapper>
            <PreviewButton onClick={() => setOpen(true)}>
                Live Preview (of last saved version)
            </PreviewButton>
            <PreviewDialog
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                doc={doc}
            />
        </Wrapper>
    )
}

export default DataDrivenPreview
