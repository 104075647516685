import { useRecoilState } from 'recoil'
import { useSelector } from 'react-redux'
import produce from 'immer'
import uuid from 'uuid/v4'
import _ from 'lodash'
import u from 'updeep'
import {
    documentState,
    manifestState,
    contextState,
} from 'v2/components/manager/document'
import { pathToID } from 'v2/components/manager/utils'
import { zapStyles } from 'v2/components/widgets/definitions'

export const revisionPrettyPrint = (rev) => {
    return rev.split('-')[0]
}

export const useContextTools = () => {
    const { updateElement } = useDocumentUpdateTools()
    const [context, setContext] = useRecoilState(contextState)
    const [doc, setDoc] = useRecoilState(documentState)

    const selectElement = (element) => {
        const nextState = produce(context, (draftState) => {
            draftState.selected = element

            return draftState
        })
        setContext(nextState)
    }

    const isSelected = (element) => {
        if (_.isEmpty(context.selected)) {
            return false
        }

        return context.selected.id === element.id
    }

    const currentStyle = () => {
        return _.get(context, 'selected.style')
    }

    const setStyle = (style) => {
        if (_.isEmpty(context.selected)) {
            return
        }

        const nextState = produce(context, (draftState) => {
            const styleData = zapStyles[style].defaultData
            draftState.selected.data = styleData
            return draftState
        })

        const styleBody = zapStyles[style].body.map((elem) => {
            const data = zapStyles[elem.style].defaultData
            const out = {
                type: elem.type,
                data: {
                    ...data,
                    ...elem.defaultData,
                },
                style: elem.style,
                body: [],
            }
            if (elem.type === 'Text' || elem.type === 'RichText') {
                out.data.value = elem.defaultValue
            }

            return out
        })

        // We want this update to be a merge...
        const selected = u(
            {
                style,
                body: styleBody,
            },
            nextState.selected
        )
        const next = {
            selected,
        }

        // console.log(next)
        setContext(next)
        updateElement(next.selected)
    }

    const updateStyle = (update, updatePath) => {
        const nextState = produce(context.selected, (draftState) => {
            _.set(draftState, updatePath, update)

            return draftState
        })

        setContext({ selected: nextState })
        updateElement(nextState)
    }

    const updateRootStyle = (update, updatePath) => {
        const nextState = produce(doc, (draftState) => {
            _.set(draftState, updatePath, update)

            return draftState
        })
        console.log(nextState)
        setDoc(nextState)
    }

    return {
        context,
        setStyle,
        updateStyle,
        updateRootStyle,
        isSelected,
        selectElement,
        currentStyle,
    }
}

export const useDocumentPublishTools = () => {
    const [manifest, setManifest] = useRecoilState(manifestState)
    const docs = useSelector((state) =>
        Object.keys(state.modules)
            .map((key) => state.modules[key])
            .filter((item) => item.version && item.version === 2)
    )

    const publish = (doc) => {
        const nextState = produce(manifest, (draftState) => {
            const update = {
                revision: doc._rev,
                on: new Date(),
            }

            const active = draftState.active[doc._id] || {}

            draftState.active[doc._id] = _.merge(active, update)

            return draftState
        })
        setManifest(nextState)
    }

    const lastPublish = (doc) => {
        const state = manifest.active[doc._id]

        if (!state) {
            return null
        }

        return {
            revision: revisionPrettyPrint(state.revision),
            on: state.on,
        }
    }

    const getRoot = () => {
        return manifest.root
    }

    const setRoot = (doc) => {
        const nextState = produce(manifest, (draftState) => {
            draftState.root = {
                id: doc._id,
            }

            return draftState
        })
        setManifest(nextState)
    }

    const allDocs = (filterPredicate) => {
        if (filterPredicate) {
            return docs.filter(filterPredicate)
        }

        return docs
    }

    return {
        publish,
        lastPublish,
        getRoot,
        setRoot,
        allDocs,
    }
}

export const useDocumentUpdateTools = () => {
    const [doc, setDocument] = useRecoilState(documentState)

    // Adds `content` to a given `element.body`
    const addToElementBody = (element, content) => {
        const nextState = produce(doc, (draftState) => {
            if (element._id) {
                draftState.body.push(content)
                return draftState
            }

            const { id } = element

            const path = pathToID(doc, id)

            const elem = _.get(draftState, path)

            elem.body.push(content)

            _.set(draftState, path, elem)

            return draftState
        })
        setDocument(nextState)
    }

    // Deletes item located `index` from `element.body`
    const deleteFromElementBody = (element, index) => {
        const nextState = produce(doc, (draftState) => {
            // if root
            if (element._id) {
                draftState.body.splice(index, 1)
                return draftState
            }

            const { id } = element
            const path = pathToID(doc, id)

            const elem = _.get(draftState, path)

            elem.body.splice(index, 1)

            _.set(draftState, path, elem)

            return draftState
        })
        setDocument(nextState)
    }

    const updateElement = (element) => {
        const nextState = produce(doc, (draftState) => {
            // if root
            if (element._id) {
                return draftState
            }

            const { id } = element
            const path = pathToID(doc, id)
            _.set(draftState, path, element)

            return draftState
        })
        setDocument(nextState)
    }

    // Merges an `update` to `element.data`
    const updateElementData = (element, update) => {
        const nextState = produce(doc, (draftState) => {
            // if root
            if (element._id) {
                _.merge(draftState.data, update)
                return draftState
            }
            const { id } = element

            const path = pathToID(doc, id)

            const elem = _.get(draftState, path)

            if (elem.data === undefined) {
                elem.data = {}
            }

            _.merge(elem.data, update)

            return draftState
        })
        setDocument(nextState)
    }

    // Deletes a `key` from the `element.data`
    const deleteElementData = (element, key) => {
        const nextState = produce(doc, (draftState) => {
            if (element._id) {
                delete draftState.data[key]
                return draftState
            }
            const { id } = element
            const path = pathToID(doc, id)

            const elem = _.get(draftState, path)
            delete elem.data[key]
            _.set(draftState, path, elem)

            return draftState
        })
        setDocument(nextState)
    }

    const updateElementNavigation = (element, navigation) => {
        const nextState = produce(doc, (draftState) => {
            const { id } = element

            const path = pathToID(doc, id)

            _.set(draftState, `${path}.navigation`, navigation)

            return draftState
        })
        setDocument(nextState)
    }

    const deleteElementNavigation = (element) => {
        const nextState = produce(doc, (draftState) => {
            const { id } = element

            const path = pathToID(doc, id)

            const elem = _.get(draftState, path)

            if (elem.navigation) {
                delete elem.navigation
            }

            return draftState
        })
        setDocument(nextState)
    }

    const updateRowDataSource = (row, dataSource) => {
        const nextState = produce(doc, (draftState) => {
            const { id } = row

            const path = pathToID(doc, id)

            _.set(draftState, `${path}.dataSource`, dataSource)

            return draftState
        })
        setDocument(nextState)
    }

    const deleteRowDataSource = (row) => {
        const nextState = produce(doc, (draftState) => {
            const { id } = row

            const path = pathToID(doc, id)

            const newRow = _.get(draftState, path)

            if (newRow.dataSource) {
                delete newRow.dataSource
            }

            return draftState
        })
        setDocument(nextState)
    }

    return {
        addToElementBody,
        deleteFromElementBody,
        updateElementData,
        deleteElementData,
        updateElementNavigation,
        deleteElementNavigation,
        updateRowDataSource,
        deleteRowDataSource,
        updateElement,
    }
}

export const useDocumentTemplates = () => {
    const normalizeType = (type) => {
        if (isToolTemplate(type)) {
            const [, normalized] = type.split('_')
            return normalized
        }
        return type
    }

    // Will create a generic document type with uuid
    const createTemplateType = (templateType) => {
        const type = normalizeType(templateType)
        const wrapInContainer = (body) => {
            return {
                id: uuid(),
                type: 'LayoutContainer',
                body,
                data: {},
            }
        }

        switch (type) {
            case 'Text':
            case 'Heading':
                return wrapInContainer([
                    {
                        id: uuid(),
                        type: 'Text',
                        body: [],
                        data: {},
                    },
                ])
            case 'Image':
                return wrapInContainer([
                    {
                        id: uuid(),
                        type,
                        body: [],
                        data: {},
                    },
                ])
            default:
                return {
                    id: uuid(),
                    type,
                    body: [],
                    data: {},
                }
        }
    }

    const createEmptyType = (type) => {
        return {
            id: uuid(),
            type,
            body: [],
            data: {},
        }
    }

    // Recursive helper
    const _duplicateElement = (element) => {
        return produce(element, (draftState) => {
            draftState.id = uuid()
            if (!_.isEmpty(draftState.body)) {
                draftState.body = element.body.map((b) => _duplicateElement(b))
            }
            return draftState
        })
    }

    // Given an `element`, will return a duplicate with new uuids
    const duplicateElement = (element) => {
        return produce(element, (draftState) => {
            draftState.id = uuid()
            draftState.body = element.body.map((b) => _duplicateElement(b))
            return draftState
        })
    }

    const isToolTemplate = (type) => {
        return type.includes('TOOL_')
    }

    return {
        createEmptyType,
        createTemplateType,
        duplicateElement,
        isToolTemplate,
    }
}
