import React from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import styled from 'styled-components'
import {
    Intent,
    Card,
    Callout,
    Button,
    Divider,
    Spinner,
} from '@blueprintjs/core'
import ErrorBoundary from './error'
import BodyEdit from './body'
import TabsEdit from './tabs'
import NearbyEdit from './nearby'
import { ModuleHeader } from '../../modules/header'
import { isValidDocument } from '../../../utils'
import EditHeader from './views/header'
import MainV2Render from 'v2/components/bridge'

const HeaderEdit = styled.div`
    display: flex;
`

const EditCard = styled(Card)`
    min-height: 400px;
    display: flex;
    flex-direction: column;

    h2 {
        margin-top: 0;
    }
    .fetching {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        text-align: center;
        flex-grow: 1;
    }
`

const ModuleEdit = ({ module }) => {
    const { body, screen = 'GenericView', _id } = module

    let renderElement = (
        <React.Fragment>
            <div className="fetching">
                <h4>Fetching document...</h4>
                <div className="spinner-container">
                    <Spinner size={Spinner.SIZE_LARGE} />
                </div>
            </div>
        </React.Fragment>
    )

    if (screen === 'GenericView' && isArray(body)) {
        renderElement = <BodyEdit body={body} id={_id} editPath={`body`} />
    }

    if (
        (screen === 'GenericView' && body && body.tabs) ||
        screen === 'TabbedView'
    ) {
        renderElement = (
            <React.Fragment>
                <TabsEdit body={body} id={_id} />
            </React.Fragment>
        )
    }

    if (screen === 'NearbyView' && body && body.nearby) {
        renderElement = (
            <React.Fragment>
                <NearbyEdit body={body} id={_id} />
            </React.Fragment>
        )
    }

    if (screen === 'TimelineView') {
        renderElement = (
            <React.Fragment>
                <Callout intent={Intent.WARNING}>
                    <strong>timeline</strong> edit is not yet available :(
                </Callout>
            </React.Fragment>
        )
    }
    return renderElement
}

ModuleEdit.defaultProps = {
    module: {},
}

const ModuleEditor = ({
    modules,
    navigation,
    saveModule,
    ...props
}) => {
    let renderElement = (
        <React.Fragment>
            <Callout intent={Intent.DANGER}>Could not render module</Callout>
        </React.Fragment>
    )

    if (isEmpty(navigation)) {
        renderElement = <Callout icon="info-sign">Nothing to edit</Callout>
    }

    if (isValidDocument(navigation, modules)) {
        const id = navigation.module.id
        const module = modules[id]

        renderElement = (
            <React.Fragment>
                <ErrorBoundary>
                    <h2>{module.metadata.title}</h2>
                    <h4>Document header</h4>
                    <Callout>
                        This header renders in the app. Give it a descriptive
                        title and add an optional image.
                    </Callout>
                    <Divider />
                    <HeaderEdit>
                        <ModuleHeader header={module.header} />
                        <EditHeader
                            activePath={`header`}
                            id={id}
                            button={{
                                text: 'Edit header',
                                icon: 'widget-header',
                            }}
                        />
                    </HeaderEdit>
                    <Divider />
                    {module.screen === 'TimelineViewer' ? (
                        <Callout
                            title="Version 2 Document"
                            intent={Intent.WARNING}
                        >
                            <p>
                                This is a version 2 document and cannot be
                                viewed in this version of the editor
                            </p>
                            <p>
                                This will be fixed in a future update, but for
                                now, press <strong>Edit View</strong> to edit.
                            </p>
                            <MainV2Render module={module} />
                        </Callout>
                    ) : (
                        <ModuleEdit module={module} />
                    )}
                    <Divider />
                    <Button
                        text="Save changes"
                        minimal
                        fill
                        icon="cloud-upload"
                        onClick={() => saveModule(module._id)}
                    />
                </ErrorBoundary>
            </React.Fragment>
        )
    }

    if (navigation.url) {
        renderElement = (
            <React.Fragment>
                <Callout icon="globe">URL structure cannot be edited</Callout>
            </React.Fragment>
        )
    }
    else if (navigation.phone || navigation.appLaunch) {
        renderElement = (
            <React.Fragment>
                <Callout icon="info-sign">
                    Please use "Change view" on the left to edit this module
                </Callout>
            </React.Fragment>
        )
    }

    return <EditCard>{renderElement}</EditCard>
}

const mapStateToProps = (state, ownProps) => {
    return {
        modules: state.modules,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveModule: (id) => dispatch({ type: 'MODULES/SAVE', payload: id }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleEditor)
