import React from 'react'
import { Button, InputGroup, Label, Callout } from '@blueprintjs/core'
import isURL from 'validator/lib/isURL'

class Module extends React.Component {
    state = {
        url: {
            title: '',
            url: '',
        },
    }
    render() {
        const { url } = this.state
        const { onLink } = this.props
        return (
            <Callout title='Open a website'>
                <Label>
                    Title
                    <InputGroup
                        type="text"
                        maxLength={100}
                        placeholder="UCLA"
                        value={url.title}
                        onChange={e =>
                            this.setState({
                                url: {
                                    ...url,
                                    title: e.target.value,
                                },
                            })
                        }
                    />
                </Label>
                <Label>
                    URL
                    <InputGroup
                        type="text"
                        maxLength={500}
                        placeholder="https://www.ucla.edu"
                        value={url.url}
                        onChange={e =>
                            this.setState({
                                url: {
                                    ...url,
                                    url: e.target.value,
                                },
                            })
                        }
                    />
                </Label>
                <Button
                    minimal
                    icon='link'
                    disabled={
                        url.url === '' || url.title === '' || !isURL(url.url)
                    }
                    fill
                    text="Select this web link"
                    onClick={() => {onLink(this.state)}}
                />
            </Callout>
        )
    }
}

export default Module
