import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { Button, Intent, Dialog, Classes } from '@blueprintjs/core'

const widthFromSize = size => {
    switch (size) {
        case 'large':
            return {
                width: 1000,
                minHeight: 500,
            }
        case 'medium':
            return { width: 800 }
        default:
            return { width: 600 }
    }
}

class View extends React.Component {
    constructor(props) {
        super(props)
        const { data } = props

        this.state = {
            data,
        }
        // Data to be saved is stored in `shadowData` to enable a controlled mode.
        // When controlled, the state changes pass through to the controlled component.
        // When not controlled, the component is assumed to maintain its own state
        // this is desirable for things like a text-editor that tracks its own state
        // @note this is in place for the quill editor.
        this.shadowData = props.data
    }

    _updateData = (data, controlled = true) => {
        if (controlled) {
            this.setState({ data })
        }

        this.shadowData = data
    }

    render() {
        const {
            activePath,
            children,
            isOpen,
            handleClose,
            updateData,
            size,
        } = this.props
        const { data } = this.state

        return (
            <Dialog
                autoFocus={true}
                isOpen={isOpen}
                usePortal={true}
                lazy={true}
                title={`Edit View Item`}
                onClose={handleClose}
                onOpening={() => {
                    this.setState({ data: this.props.data })
                    this.shadowData = this.props.data
                }}
                style={widthFromSize(size)}
            >
                <div className={Classes.DIALOG_BODY}>
                    {children({ data, activePath }, this._updateData)}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button minimal onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            minimal
                            intent={Intent.PRIMARY}
                            onClick={() => {
                                updateData(this.shadowData)
                                handleClose()
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Dialog>
        )
    }
}

View.propTypes = {
    children: PropTypes.func.isRequired,
    activePath: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    const { id, activePath } = ownProps
    const data = get(state, `modules.${id}.${activePath}`)
    if (!data) {
        console.assert(
            !data,
            `Could not read data at path "modules.${id}.${activePath}"`
        )
        throw new Error(
            `Could not read data at path "modules.${id}.${activePath}"`
        )
    }
    return {
        data: data,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateData: data => {
            if (data === null) {
                console.assert(data !== null, 'A data item should not be null')
                throw new Error(
                    'A `null` data item cannot be saved to a module body'
                )
            }

            dispatch({
                type: 'UPDATE_MODULE',
                payload: {
                    path: ownProps.activePath,
                    data,
                    moduleId: ownProps.id,
                },
            })
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(View)
