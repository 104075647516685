import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '@blueprintjs/core'
import _ from 'lodash'
import produce from 'immer'
import classname from 'classnames'
import { useRecoilState } from 'recoil'

import { documentState } from 'v2/components/manager/document'
import DocumentEditSave from 'v2/components/tools/save'
import DataDrivenPreview from 'v2/components/tools/preview'
import DocumentStat from 'v2/components/edit/stat'
import { PanelHeaderDialog } from 'v2/components/edit/header'

const Wrapper = styled.div`
    width: 100%;
    padding: 10px;

    .document {
        display: flex;
        margin-bottom: 20px;
        .actions {
            flex: 1;
        }
    }

    .header-edit-title {
        padding: 10px;
        font-weight: bold;
        text-transform: capitalize;
    }

    .header-edit {
        display: flex;

        .heading {
            margin-right: 10px;
        }
    }

    .heading {
        background: #fff;

        width: 100%;
        border: 1px solid #d5dde5;
        border-radius: 3px;
        padding: 4px 8px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1rem;
        font-weight: 600;

        span {
            display: inline-block;
            padding: 2px;
            height: 30px;
            background: #fff;
            border-radius: 3px;
            margin-right: 10px;

            img {
                height: 100%;
            }
        }

        &.dashboard {
            flex-direction: column-reverse;

            span {
                height: 50px;
            }
        }
    }
`

const DashboardHeader = ({
    documentEditTime,
    onDocumentDelete,
    onDocumentSave,
    documentHasEdits,
}) => {
    const [isHeaderOpen, setHeaderOpen] = useState(false)
    const [doc, setDoc] = useRecoilState(documentState)
    const { header } = doc

    const onHeaderUpdate = (header) => {
        const nextState = produce(doc, (draftState) => {
            draftState.header = header
            return draftState
        })

        setDoc(nextState)
    }

    const headerType = _.get(header, 'type', 'view')
    const base64 = _.get(header, 'image.base64')
    const src = _.get(header, 'image.url', base64)

    return (
        <Wrapper>
            <div className="document">
                <div className="actions">
                    <DocumentEditSave
                        documentHasEdits={documentHasEdits}
                        documentEditTime={documentEditTime}
                        onDocumentSave={onDocumentSave}
                    />
                    <DataDrivenPreview doc={doc} />
                </div>
                <div className="stat">
                    <DocumentStat doc={doc} allowPublish={true} />
                </div>
            </div>
            <div className="header-edit-title">{headerType} header</div>
            <div className="header-edit">
                <div className={classname('heading', headerType)}>
                    {src ? (
                        <span>
                            <img src={src} alt={header.title} />
                        </span>
                    ) : null}
                    <div>{header.title}</div>
                </div>
                <Button
                    text="Edit"
                    minimal={true}
                    onClick={() => setHeaderOpen(true)}
                />
            </div>
            <PanelHeaderDialog
                isOpen={isHeaderOpen}
                onClose={() => setHeaderOpen(false)}
                initialData={header}
                onSave={(header) => onHeaderUpdate(header)}
            />
        </Wrapper>
    )
}

export default DashboardHeader
