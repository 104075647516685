const simple = [
    {
        type: 'text',
        data: {
            markdown: 'Enter some text here.',
        },
    },
]

const tabbed = {
    tabs: [
        {
            title: 'First Tab',
            order: 1,
            body: [
                {
                    type: 'text',
                    data: {
                        markdown: 'This is in the first tab',
                    },
                    order: 1,
                },
            ],
        },
        {
            title: 'Second Tab',
            order: 2,
            body: [
                {
                    type: 'text',
                    data: {
                        markdown: 'Viewing second tab',
                    },
                    order: 1,
                },
            ],
        },
    ],
}

const nearby = {
    nearby: [
        {
            title: 'UCLA',
            location: {
                lat: 34.069336,
                lon: -118.447166
            },
            body: [
                {
                    type: 'list',
                    data: [],
                    order: 1,
                },
            ],
        },
    ],
}


const carousel = {
    carousel: [
        {
            title: 'First Slide',
            order: 1,
            body: [
                {
                    type: 'text',
                    data: {
                        markdown: 'This is in the first slide',
                    },
                    order: 1,
                },
            ],
        },
        {
            title: 'Second Slide',
            order: 2,
            body: [
                {
                    type: 'text',
                    data: {
                        markdown: 'Viewing second slide',
                    },
                    order: 1,
                },
            ],
        },
    ],
}

const timeline = [
    // {
    //     title: 'Untitled timeline',
    //     type: 'type 1',
    //     reminderTypes: [],
    //     body: [
    //         {
    //             day: 0,
    //             title: 'Event title',
    //             type: 'type 1',
    //             content: {
    //                 markdown: 'Edit me...',
    //             },
    //             reminders: [
    //                 {
    //                     type: 'adult',
    //                     day: 0,
    //                     title: 'Reminder title',
    //                     content: {
    //                         markdown: 'Reminder note',
    //                     },
    //                     time: '12:00 PM',
    //                 },
    //             ],
    //         },
    //     ],
    // },
]

export const moduleBody = {
    simple,
    tabbed,
    nearby,
    timeline
}

export const defaultDocument = {
    org: 'neurosurgery.ucla.edu',
    updated: new Date(),
    parent: [],
    metadata: {
        title: 'Untitled',
        description: '',
        reference: 'untitled',
        updated: 'date',
        editor: 'user',
    },
    header: {
        title: 'Untitled',
        image: {
            base64: '',
        },
    },
    body: [],
}
