import React from 'react'
import { Button } from '@blueprintjs/core'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import TurndownService from 'turndown'
import showdown from 'showdown'
import Connected from './connect'

class View extends React.Component {
    constructor(props) {
        super(props)

        this.turndownService = new TurndownService()
        this.converter = new showdown.Converter()
        this.state = {
            isOpen: false,
        }
    }

    _handleClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        const { activePath, id } = this.props
        const { isOpen } = this.state

        return (
            <React.Fragment>
                <Button
                    icon="edit"
                    minimal
                    onClick={() => this.setState({ isOpen: !isOpen })}
                />
                <Connected
                    activePath={activePath}
                    id={id}
                    isOpen={isOpen}
                    handleClose={this._handleClose}
                    size={`large`}
                >
                    {({ data }, updateData) => (
                        <React.Fragment>
                            <ReactQuill
                                value={this.converter.makeHtml(data.markdown)}
                                onChange={html =>
                                    updateData({
                                        markdown: this.turndownService.turndown(
                                            html
                                        ),
                                    }, false)
                                }
                            />
                        </React.Fragment>
                    )}
                </Connected>
            </React.Fragment>
        )
    }
}

View.defaultProps = {
    title: 'Text',
    description: 'Edit text.',
    descriptor: {
        type: 'text',
        data: {
            markdown: 'Edit me...',
        },
    },
    iconName: 'new-text-box',
}

export default View
