import React from 'react'
import { Icon, Button, TextArea, Intent } from '@blueprintjs/core'
import styled from 'styled-components'

const ErrorView = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    textarea {
        width: 100%;

        margin-top: 20px;
        margin-bottom: 20px;
        max-height: 400px;
    }
`

class RenderError extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, error: '', info: '' }
        this.ref = React.createRef()
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true, error, info })
        console.log(`------- ☠️ ☠️ ☠️ -------`)
        console.log(error)
        console.log(`------- ☠️ ☠️ ☠️ -------`)
    }

    render() {
        if (this.state.hasError) {
            const { error, info } = this.state
            return (
                <ErrorView>
                    <h4>
                        <Icon icon="error" /> Something went wrong :(
                    </h4>
                    <p>
                        The app preview encountered an error and stopped
                        working. Create an issue with the details of the error,
                        as well as a description of what you were trying to do.
                    </p>
                    <p>☠️ ☠️ ☠️</p>
                    <div>
                        <code>{error.toString()}</code>
                    </div>
                    <TextArea
                        className="textarea"
                        ref={(ref) => (this.ref = ref)}
                        growVertically={true}
                        value={info.componentStack}
                    />
                    <Button
                        intent={Intent.PRIMARY}
                        minimal="true"
                        text="Copy error to clipboard"
                        onClick={() => {
                            console.log(this.ref.internalTextAreaRef.select)
                            this.ref.internalTextAreaRef.select()
                            document.execCommand('copy')
                            // console.log(this.ref.internalTextAreaRef.value)
                        }}
                    />
                </ErrorView>
            )
        }
        return this.props.children
    }
}

export default RenderError
