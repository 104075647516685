import React from 'react'
import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'
import ContextPreview from '../modules/context'

const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: ${props => props.width}px;

    .title {
        background: #E1E8ED;
        display: inline;
        padding: 2px 8px;
        font-size: ${props => props.compact ? .75 : .9}rem;
        text-align: center;
        margin-top: 2px;
    }
`

const Image = styled.img`
    height: ${props => props.height}px;
    margin-right: 8px;
    margin-left: 4px;
`

export const Parent = ({ dashboard, parent, compact }) => {
    const { items } = dashboard
    const item = items[parent.itemPath]

    if (!item) {
        return <div>no parent</div>
    }

    let width = 200
    let iconSize = 60
    let imageHeight = 80

    if (compact) {
        width = 50
        iconSize = 30
        imageHeight = 30
    }

    return (
        <Item compact={compact} width={width} >
            {item.image && item.image.base64 ? (
                <Image src={item.image.base64} height={imageHeight} />
            ) : (
                <Icon icon="media" color="#5C7080" iconSize={iconSize} />
            )}
            <div className='title' >{item.name}</div>
        </Item>
    )
}

Parent.defaultProps = {
    compact: false,
}

export default () => <ContextPreview parent={Parent} />
