import React from 'react'
import { connect } from 'react-redux'
import LandingPage from '../landing/landing-page'

const Auth = ({ kc, children }) => {
	return kc.authenticated ? React.Children.only(children) : <LandingPage />
}

const mapStateToProps = (state, ownProps) => ({
	kc: state.keycloak,
})

export default connect(mapStateToProps)(Auth)
