import React, { useState } from 'react'
import classname from 'classnames'
import {
    Button,
    ButtonGroup,
    Position,
    Popover,
    Menu,
    Icon,
    Tooltip,
    InputGroup,
    Divider,
    Dialog,
    Classes,
    Label,
    Intent,
    Callout,
    FormGroup,
    Alert,
} from '@blueprintjs/core'
import uuid from 'uuid/v4'
import _ from 'lodash'
import TimelineHeaderEditor from 'v2/components/widgets/timeline-header'
import SimpleTextEditor from 'v2/components/widgets/text'
import TimelineReminderEditor from 'v2/components/widgets/timeline-reminder'

const Validator = require('jsonschema').Validator

const TimelineTypeEdit = ({
    timeline,
    timelines,
    seriesCount,
    active,
    onEdit,
    onDelete,
    onDuplicate,
    onAddSeries,
}) => {
    const [isEditOpen, setEditOpen] = useState(false)
    const [isAddOpen, setAddOpen] = useState(false)
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false)

    return (
        <div className={classname('edit-box', { active })}>
            <div className="descriptor">
                <span className="label">timeline</span>{' '}
                <Popover position={Position.BOTTOM}>
                    <Button icon="menu" minimal="true" />
                    <Menu>
                        <Menu.Item
                            disabled={!active}
                            icon="plus"
                            text="Add New Series"
                            onClick={() => setAddOpen(true)}
                        />
                        <Menu.Item
                            icon="edit"
                            text="Edit"
                            onClick={() => setEditOpen(true)}
                        />
                        <Menu.Item
                            icon="duplicate"
                            text="Duplicate"
                            onClick={onDuplicate}
                        />
                        <Menu.Divider />
                        <Menu.Item
                            intent={Intent.DANGER}
                            icon="trash"
                            text="Delete"
                            onClick={() => setDeleteAlertOpen(true)}
                        />
                    </Menu>
                </Popover>
            </div>
            <div className="type">{timeline.data.type}</div>
            <div className="title">{timeline.data.title}</div>
            <div className="count">
                <span>{seriesCount}</span> items in series
            </div>
            <TimelineTypeDialog
                isOpen={isEditOpen}
                onClose={() => setEditOpen(false)}
                exclude={timelines
                    .filter((r) => r.data.type !== timeline.data.type)
                    .map((r) => r.data.type)}
                initialData={timeline.data}
                onSave={(data) => {
                    const payload = {
                        ...timeline,
                        data,
                    }
                    onEdit(payload)
                }}
            />
            <TimelineHeaderEditor
                isOpen={isAddOpen}
                onClose={() => setAddOpen(false)}
                options={['day', 'hour', 'minute', 'week']}
                initialData={{
                    title: 'Day zero',
                    time: {
                        unit: 'day',
                        value: 0,
                    },
                }}
                onChange={(data) => {
                    console.log(data)
                    const payload = {
                        type: 'TimelineContent',
                        id: uuid(),
                        data,
                        body: [],
                    }
                    onAddSeries(payload)
                }}
            />
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={() => setDeleteAlertOpen(false)}
                onConfirm={() => {
                    onDelete()
                    setDeleteAlertOpen(false)
                }}
            >
                <p>
                    Are you sure you want to delete <b>{timeline.data.type}</b>{' '}
                    timeline type? Deleting it will remove all the content
                    inside this series.
                </p>
            </Alert>
        </div>
    )
}

const TimelineTypeAdd = ({ timelines, onTimelineAdd }) => {
    const [isOpen, setOpen] = useState(false)
    const initialData = {
        type: '',
        title: '',
        description: '',
    }
    return (
        <>
            {_.isEmpty(timelines) ? (
                <Button
                    className="add"
                    minimal="true"
                    onClick={() => {
                        setOpen(true)
                    }}
                >
                    <Icon icon="plus" color="white" />{' '}
                    <span>Add Timeline Type</span>
                </Button>
            ) : (
                <Tooltip content="Add Timeline Type" position={Position.BOTTOM}>
                    <button className="zap-btn" onClick={() => setOpen(true)}>
                        <Icon icon="plus" color="white" />
                    </button>
                </Tooltip>
            )}
            <TimelineTypeDialog
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                exclude={timelines.map((r) => r.data.type)}
                initialData={initialData}
                onSave={(data) => {
                    const payload = {
                        type: 'Timeline',
                        id: uuid(),
                        data,
                        body: [
                            {
                                type: 'TimelineContent',
                                id: uuid(),
                                data: {
                                    title: 'Day zero',
                                    time: {
                                        unit: 'day',
                                        value: 0,
                                    },
                                },
                                body: [],
                            },
                        ],
                    }

                    onTimelineAdd(payload)
                }}
            />
        </>
    )
}

const TimelineTypeDialog = ({
    isOpen,
    onClose,
    exclude,
    initialData,
    onSave,
}) => {
    const schema = {
        id: '/Reminder',
        type: 'object',
        properties: {
            type: {
                type: 'string',
                minLength: 3,
                maxLenght: 10,
                not: { enum: exclude },
            },
            title: { type: 'string', minLength: 1 },
            description: {
                type: 'string',
            },
        },
        required: ['type', 'title'],
    }

    const errorMap = {
        'instance.type': 'Timeline type',
    }

    const [data, setValue] = useState(initialData)
    const [errors, setErrors] = useState([])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Add timeline type"
            onOpening={() => {
                setValue(initialData)
                setErrors([])
            }}
        >
            <div className={Classes.DIALOG_BODY}>
                {errors.length > 0 ? (
                    <Callout title="There was an error" intent={Intent.WARNING}>
                        <ul>
                            {errors.map((err, k) => (
                                <li key={k}>
                                    <div>
                                        <strong>
                                            {errorMap[err.property]}
                                        </strong>
                                    </div>
                                    <div>{err.stack}</div>
                                </li>
                            ))}
                        </ul>
                    </Callout>
                ) : null}
                <FormGroup
                    intent={Intent.PRIMARY}
                    label="Timeline type"
                    helperText="A timeline type defines a new timeline series"
                    labelFor="text-input-type"
                    labelInfo="(required)"
                >
                    <InputGroup
                        id="text-input-type"
                        value={data.type}
                        onChange={(e) => {
                            const type = e.target.value
                                .replace(' ', '-')
                                .toLowerCase()
                            setValue({ ...data, type })
                        }}
                    />
                </FormGroup>
                <Divider />
                <Label>
                    Title
                    <InputGroup
                        value={data.title}
                        onChange={(e) => {
                            setValue({ ...data, title: e.target.value })
                        }}
                    />
                </Label>
                <Divider />
                <Label>
                    Description
                    <InputGroup
                        value={data.description}
                        onChange={(e) => {
                            setValue({
                                ...data,
                                description: e.target.value,
                            })
                        }}
                    />
                </Label>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Cancel" onClick={onClose} minimal="true" />
                    <Button
                        text="Save changes"
                        intent={Intent.PRIMARY}
                        minimal="true"
                        onClick={() => {
                            const v = new Validator()
                            const res = v.validate(data, schema)

                            setErrors(res.errors)

                            if (res.valid) {
                                onSave(data)
                                onClose()
                            }
                        }}
                    />
                </div>
            </div>
        </Dialog>
    )
}
const NoneSelected = () => null

const TextContent = ({ data, onChange, onDelete }) => {
    const [isOpen, setOpen] = useState(false)
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false)
    return (
        <div className="TextContent editable-box">
            <div className="descriptor">
                <div className="label">text</div>
                <Popover position={Position.BOTTOM}>
                    <Button icon="menu" minimal="true" />
                    <Menu>
                        <Menu.Item
                            icon="edit"
                            text="Edit Text"
                            onClick={() => setOpen(true)}
                        />
                        <Menu.Divider />
                        <Menu.Item
                            intent={Intent.DANGER}
                            icon="trash"
                            text="Delete"
                            onClick={() => setDeleteAlertOpen(true)}
                        />
                    </Menu>
                </Popover>
            </div>
            <pre className="content text">{data.markdown}</pre>
            <SimpleTextEditor
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                initialData={data.markdown}
                onChange={(markdown) => {
                    onChange({
                        ...data,
                        markdown,
                    })
                }}
            />
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={() => setDeleteAlertOpen(false)}
                onConfirm={() => {
                    onDelete()
                    setDeleteAlertOpen(false)
                }}
            >
                <p>Are you sure you want to delete this text content?</p>
            </Alert>
        </div>
    )
}

const TimelineContent = ({
    data,
    body,
    id,
    activeID,
    onChange,
    onAddContent,
    onDelete,
    options,
}) => {
    const [isHeaderEditorOpen, setHeaderEditorOpen] = useState(false)
    const [isTextEditorOpen, setTextEditorOpen] = useState(false)
    const [isReminderEditorOpen, setReminderEditorOpen] = useState(false)
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false)

    const reminders = body.filter((b) => b.type === 'ReminderData')
    return (
        <>
            <div
                className={classname('timeline-heading editable-box', {
                    active: id === activeID,
                })}
            >
                <div className="descriptor">
                    <div className="label">entry</div>
                    <Popover position={Position.BOTTOM}>
                        <Button icon="menu" minimal="true" />
                        <Menu>
                            <Menu.Item
                                icon="edit"
                                text="Edit"
                                onClick={() => setHeaderEditorOpen(true)}
                            />
                            <Menu.Item
                                icon="new-text-box"
                                text="Add Text Content"
                                onClick={() => setTextEditorOpen(true)}
                            />
                            <Menu.Item
                                icon="time"
                                text="Add Reminder"
                                onClick={() => setReminderEditorOpen(true)}
                            />
                            <Menu.Divider />
                            <Menu.Item
                                intent={Intent.DANGER}
                                icon="trash"
                                text="Delete"
                                onClick={() => setDeleteAlertOpen(true)}
                            />
                        </Menu>
                    </Popover>
                </div>
                <div className="title">{data.title}</div>
                <div className="meta">
                    <div className="date">
                        <span>{data.time.value}</span>
                        {data.time.unit}
                    </div>
                    <div className="reminders">
                        <span>{reminders.length}</span> reminders attached
                    </div>
                </div>
            </div>
            <TimelineHeaderEditor
                isOpen={isHeaderEditorOpen}
                onClose={() => setHeaderEditorOpen(false)}
                options={['day', 'hour', 'minute', 'week']}
                initialData={data}
                onChange={onChange}
            />
            <SimpleTextEditor
                isOpen={isTextEditorOpen}
                onClose={() => setTextEditorOpen(false)}
                initialData={'Text Content'}
                onChange={(markdown) => {
                    const payload = {
                        id: uuid(),
                        type: 'TextContent',
                        data: {
                            markdown,
                        },
                    }
                    onAddContent(payload)
                }}
            />
            <TimelineReminderEditor
                isOpen={isReminderEditorOpen}
                onClose={() => setReminderEditorOpen(false)}
                initialData={{
                    type: 'parent',
                    time: '12:00 PM',
                    title: 'Reminder title',
                    text: {
                        markdown: '',
                    },
                }}
                options={options}
                onChange={(data) => {
                    const payload = {
                        id: uuid(),
                        type: 'ReminderData',
                        data,
                    }
                    onAddContent(payload)
                }}
            />
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={() => setDeleteAlertOpen(false)}
                onConfirm={() => {
                    onDelete()
                    setDeleteAlertOpen(false)
                }}
            >
                <p>
                    Are you sure you want to delete <b>{data.title}</b> timeline
                    series?
                </p>
            </Alert>
        </>
    )
}

// const TimelineSeriesDialog = ({
//     initialData,
//     isOpen,
//     onClose,
//     options,
//     onSave,
// }) => {
//     // const options = timelines.map((t) => t.data.type)
//     const initial = {
//         type: '',
//         title: '',
//         description: '',
//     }
//     const schema = {
//         id: '/TimelineSeries',
//         type: 'object',
//         properties: {
//             type: { type: 'string', pattern: `^(${options.join(')|(')})$` },
//             title: { type: 'string', minLength: 1 },
//             description: {
//                 type: 'string',
//             },
//         },
//         required: ['type', 'title'],
//     }
//
//     // const [isOpen, setOpen] = useState(false)
//     const [data, setValue] = useState(initialData)
//
//     return (
//         <Dialog
//             isOpen={isOpen}
//             onClose={onClose}
//             title="Add reminder"
//             onOpening={() => setValue(initial)}
//         >
//             <div className={Classes.DIALOG_BODY}>
//                 <Label>
//                     Title
//                     <InputGroup
//                         value={data.title}
//                         onChange={(e) => {
//                             setValue({ ...data, title: e.target.value })
//                         }}
//                     />
//                 </Label>
//                 <Divider />
//                 <Label>
//                     Timeline type
//                     <Menu>
//                         {options.map((key) => (
//                             <Menu.Item
//                                 key={key}
//                                 text={key}
//                                 active={key === data.type}
//                                 onClick={() => setValue({ ...data, type: key })}
//                             />
//                         ))}
//                     </Menu>
//                 </Label>
//                 <Divider />
//                 <Label>
//                     Description
//                     <InputGroup
//                         value={data.description}
//                         onChange={(e) => {
//                             setValue({
//                                 ...data,
//                                 description: e.target.value,
//                             })
//                         }}
//                     />
//                 </Label>
//             </div>
//             <div className={Classes.DIALOG_FOOTER}>
//                 <div className={Classes.DIALOG_FOOTER_ACTIONS}>
//                     <Button text="Cancel" onClick={onClose} minimal="true" />
//                     <Button
//                         text="Save changes"
//                         intent={Intent.PRIMARY}
//                         minimal="true"
//                         onClick={() => {
//                             const payload = {
//                                 type: 'Timeline',
//                                 id: uuid(),
//                                 data,
//                                 body: [],
//                             }
//                             const v = new Validator()
//
//                             const res = v.validate(data, schema)
//
//                             if (res.valid) {
//                                 onSave(payload)
//                                 // setOpen(false)
//                             }
//                         }}
//                     />
//                 </div>
//             </div>
//         </Dialog>
//     )
// }

export {
    TimelineContent,
    TimelineTypeEdit,
    TextContent,
    NoneSelected,
    TimelineTypeAdd,
}
