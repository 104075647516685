import React, { useState } from 'react'
import _ from 'lodash'
import {
    Classes,
    Intent,
    Dialog,
    Spinner,
    Button,
    FormGroup,
    HTMLSelect,
    InputGroup,
} from '@blueprintjs/core'

import config from 'config'
import { useContextTools } from 'v2/components/manager/api'

const PollEditor = ({ initialData, updatePath }) => {
    const defaultPolls = [{
        key: 0,
        value: 0,
        label: 'Select a poll',
    }]

    const [ data, setData ] = useState(initialData)
    const [ isDialogOpen, setDialogOpen ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ polls, setPolls ] = useState(defaultPolls)
    const [ pollId, setPollId ] = useState(0)
    const [ password, setPassword ] = useState('')
    const [ error, setError ] = useState('')
    const { updateStyle } = useContextTools()

    const _open = () => setDialogOpen(true)
    const _close = () => setDialogOpen(false)

    const _initDialog = async () => {
        setLoading(true)
        setError('')

        const response = await fetch(config.opt.url + '/polls/zap')
        const json = await response.json()

        setPolls(defaultPolls.concat(json.map(poll => ({
            key: poll.id,
            value: poll.id,
            label: poll.title,
        }))))

        setPollId(_.get(initialData, 'id', 0))
        setPassword(_.get(initialData, 'password', ''))
        setLoading(false)
    }

    const _setPollId = (event) => {
        setPollId(parseInt(event.currentTarget.value, 10))
    }

    const _setPassword = (event) => {
        setPassword(event.currentTarget.value)
    }

    const _accept = async () => {
        setLoading(true)

        try {
            const url = config.opt.url + '/polls/' + pollId
            const response = await fetch(url + '.json?password=' + password)

            if (!response.ok) {
                throw Error(await response.text())
            }

            const json = await response.json()
            const value = {
                id: pollId,
                title: json.title,
                password,
                question: json.question,
                choices: json.choices.map(choice => choice.text),
                readUrl: url + '.json',
                submitUrl: url + '/responses/quick',
            }

            setData(value)
            updateStyle(value, updatePath)
            _close()
        }

        catch (err) {
            setError(err.toString())
        }

        finally {
            setLoading(false)
        }
    }

    const _dialogBody = () => {
        if (loading) {
            return (
                <Spinner />
            )
        }

        return (
            <React.Fragment>
                <FormGroup label="Poll" labelFor="pollId">
                    <HTMLSelect
                        id="pollId"
                        options={polls}
                        value={pollId}
                        onChange={_setPollId}
                    />
                </FormGroup>

                <FormGroup
                    label="Password"
                    labelFor="password"
                    helperText={error}
                >
                    <InputGroup
                        id="password"
                        type="password"
                        value={password}
                        onChange={_setPassword}
                    />
                </FormGroup>
            </React.Fragment>
        )
    }

    const _dialogFooter = () => {
        if (loading) {
            return null
        }

        return (
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={_close}>
                    Cancel
                </Button>
                <Button
                    intent={Intent.PRIMARY}
                    disabled={!pollId || !password}
                    onClick={_accept}
                >
                    OK
                </Button>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Dialog
                title="Select OPT Poll"
                isOpen={isDialogOpen}
                onOpening={_initDialog}
                onClose={_close}
            >
                <div className={Classes.DIALOG_BODY}>
                    {_dialogBody()}
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    {_dialogFooter()}
                </div>
            </Dialog>

            {data.title}
            <Button fill onClick={_open}>
                {data.title ? 'Change Poll' : 'Setup Poll'}
            </Button>
        </React.Fragment>
    )
}

export default PollEditor
