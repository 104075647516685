import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import isEmpty from 'lodash/isEmpty'
import { Icon, Button, Callout, Intent } from '@blueprintjs/core'
import { getModuleHeader } from '../../../utils'
import { RenderHeader } from '../../modules/header'
import Document from '../index'
import MainV2Render from 'v2/components/bridge'

const EmptyList = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    color: #5c7080;
    padding: 8px 10px;
    border: 4px solid #ffb366;
`

const EmptyImage = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    color: #5c7080;
    padding: 8px 10px;
    border: 4px solid #ffb366;
`

const Image = styled.img`
    max-width: 372px;

    border: 2px solid transparent;
`

const Text = styled.div`
    padding: 0 4px;
    border: 2px solid transparent;
`

const Heading = styled.h2`
    margin: 0;
    padding: 2px 4px;
    font-weight: bold;
    border: 2px solid transparent;
    background: #efefef;
`

const List = styled.ul`
    padding: 0;
    margin: 0;
    border: 2px solid transparent;
    list-style: none;

    &.cards-gray {
        background-color: #eeeeee;
    }
`

const ListItem = styled.li`
    border-bottom: 1px solid #ddd;

    .cards-gray & {
        margin: 10px 6px;
        border: 1px solid #347bad;
        border-radius: 3px;
        background-color: white;
        cursor: pointer;
    }
`

const ListItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 4px;
    border: 2px solid transparent;
    text-align: left;

    &:hover {
        border: 2px solid #ff6e4a;
    }

    .title {
        flex-grow: 1;

        .cards-gray & {
            margin: 0 0 10px 10px;
            font-weight: 500;
            font-size: 16px;
            color: #2774ae;
        }
    }

    .inline &:hover {
        border-color: transparent;
    }

    .cards-gray & {
        padding: 0;
    }
`

const ListImage = styled.img`
    height: 38px;
    margin-right: 4px;

    .cards-gray & {
        display: block;
        height: 80px;
    }
`

const ListDescription = styled.div`
    margin: 10px;
    font-weight: 600;
    font-size: 10px;
`

const ButtonRender = styled.div`
    display: flex;
    align-items: center;

    background: #ced9e0;
    border-radius: 4px;
    margin: 4px;
    padding: 8px 10px;

    .btn-metadata {
        flex-grow: 1;
        display: flex;

        .image {
            margin-right: 10px;
        }
        img {
            max-height: 40px;
        }
    }
`

const EmptyBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

class RenderBody extends React.PureComponent {
    render() {
        if (this.props.module.screen === 'TimelineViewer') {
            return (
                <Callout title="Version 2 Document" intent={Intent.WARNING}>
                    <p>
                        This is a version 2 document and cannot be viewed in
                        this version of the editor
                    </p>
                    <p>
                        This will be fixed in a future update, but for now,
                        press <strong>Edit View</strong> to edit.
                    </p>
                    <MainV2Render
                        module={this.props.module}
                        buttonTitle="Edit Document"
                    />
                </Callout>
            )
        }
        return (
            <React.Fragment>
                {(this.props.body || []).map((elem, index) =>
                    this._renderElement(elem, index)
                )}
            </React.Fragment>
        )
    }

    _renderElement(elem, index) {
        switch (elem.type) {
            case 'button':
                return this._renderButton(elem, index)
            case 'image':
                return this._renderImage(elem, index)
            case 'text':
                return this._renderText(elem, index)
            case 'heading':
                return this._renderHeading(elem, index)
            case 'list':
                return this._renderList(elem, index)
            default:
                return (
                    <Callout key={index}>
                        A render type is not yet defined for{' '}
                        <strong>{elem.type}</strong>
                    </Callout>
                )
        }
    }

    _renderButton(elem, index) {
        return (
            <ButtonRender key={index}>
                <div className="btn-metadata">
                    {!isEmpty(elem.data.image) ? (
                        <div className="image">
                            <Image src={elem.data.image.base64} />
                        </div>
                    ) : null}
                    <div className="title">{elem.data.title}</div>
                </div>
                {this._renderArrow(elem, index)}
            </ButtonRender>
        )
    }

    _renderImage(elem, index) {
        if (elem.data.base64) {
            return (
                <div key={index}>
                    <Image src={elem.data.base64} />
                </div>
            )
        } else {
            return (
                <EmptyImage key={index}>
                    <Icon icon="media" iconSize={24} />
                    <span>&lt;image placeholder&gt;</span>
                </EmptyImage>
            )
        }
    }

    _renderText(elem, index) {
        return (
            <Text key={index}>
                <ReactMarkdown children={elem.data.markdown} escapeHtml={false} />
            </Text>
        )
    }

    _renderHeading(elem, index) {
        return <Heading key={index}>{elem.data}</Heading>
    }

    _renderList(elem, index) {
        if (isEmpty(elem.data)) {
            return (
                <EmptyList key={index} className={elem.theme}>
                    <Icon icon="list" iconSize={24} />
                    <span>&lt;empty list&gt;</span>
                </EmptyList>
            )
        }

        return (
            <List key={index} className={elem.theme}>
                {elem.data.map((item, subIndex) => {
                    let onClick = null
                    let description

                    // Card-style lists show descriptions, and are clickable
                    if (elem.theme === 'cards-gray') {
                        description = (
                            <div>
                                <div className="title">{item.title}</div>
                                <ListDescription>
                                    {item.description}
                                </ListDescription>
                            </div>
                        )

                        if (!this.props.inline) {
                            onClick = () =>
                                this._navigateTo(item, index, subIndex)
                        }
                    } else {
                        description = (
                            <React.Fragment>
                                <div className="title">{item.title}</div>
                                {this._renderArrow(item, index, subIndex)}
                            </React.Fragment>
                        )
                    }

                    return (
                        <ListItem key={subIndex} onClick={onClick}>
                            <ListItemWrapper>
                                <div>
                                    {!isEmpty(item.image) ? (
                                        <ListImage src={item.image.base64} />
                                    ) : null}
                                </div>
                                {description}
                            </ListItemWrapper>
                        </ListItem>
                    )
                })}
            </List>
        )
    }

    _renderArrow(elem, index, subIndex = null) {
        // Only display if openPanel is defined or it's inline
        // (also don't display NearbyViews in lists)
        if (
            (!this.props.openPanel && !this.props.inline) ||
            (subIndex !== null && this.props.module.screen === 'NearbyView')
        ) {
            return null
        }

        // If inline, show an element that only looks like a button
        if (this.props.inline) {
            return (
                <div className="bp3-button bp3-minimal">
                    <Icon icon="chevron-right" />
                </div>
            )
        }

        // Otherwise, show the real deal
        else {
            return (
                <Button
                    icon="chevron-right"
                    minimal
                    onClick={() => this._navigateTo(elem, index, subIndex)}
                />
            )
        }
    }

    _navigateTo(elem, index, subIndex = null) {
        const {
            modules,
            module,
            openPanel,
            selectedTabId = null,
        } = this.props

        const header = getModuleHeader(elem.navigation, modules)

        const title = <RenderHeader header={header} />

        let itemPath =
            selectedTabId !== null
                ? `${module._id}.body.tabs[${selectedTabId}]`
                : `${module._id}`
        itemPath = `${itemPath}.body[${index}]`

        if (subIndex !== null) {
            itemPath = `${itemPath}.data[${subIndex}]`
        }

        openPanel({
            component: Document,
            props: {
                parent: {
                    type: 'module',
                    id: module._id,
                    itemPath,
                },
                navigation: elem.navigation,
            },
            title,
        })
    }
}

export { RenderBody, EmptyBody }
