import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import {
    Button,
    Icon,
    Intent,
    Callout,
    Divider,
    ButtonGroup,
} from '@blueprintjs/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const UL = styled.ul`
    list-style: none;
    padding: 0;
`
const LI = styled.li`
    padding: 4px;
    border: 1px solid #bfccd6;
    border-radius: 3px;
    margin-bottom: 4px;
`

const Scroll = styled.div`
    max-height: 800px;
    overflow-y: scroll;
`

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? '#EBF1F5' : '#F5F8FA',

    // styles we need to apply on draggables
    ...draggableStyle,
})

const getListStyle = isDraggingOver => ({
    borderColor: isDraggingOver ? '#BFCCD6' : '#fff',
    padding: 8,
})

const TypeWrapper = styled.span`
    padding: 0 4px;
    margin-right: 4px;
`

const TypeIcon = ({ type, iconSize = 24 }) => {
    let renderElement = null
    switch (type) {
        case 'image':
            renderElement = <Icon icon="media" iconSize={iconSize} />
            break
        case 'text':
            renderElement = <Icon icon="new-text-box" iconSize={iconSize} />
            break
        case 'heading':
            renderElement = <Icon icon="header" iconSize={iconSize} />
            break
        case 'list':
            renderElement = <Icon icon="list" iconSize={iconSize} />
            break
        default:
            renderElement = <Icon icon="document" iconSize={iconSize} />
            break
    }
    return <TypeWrapper>{renderElement}</TypeWrapper>
}

const ItemBody = styled.div`
    display: flex;
    padding: 4px;

    color: #394b59;

    strong {
        flex-grow: 2;
        padding: 4px 0;
    }
`

const PreviewData = ({ data, size = 50 }) => {
    let renderElement = null

    if (data.markdown) {
        renderElement = data.markdown.substring(0, size)
    }

    if (isString(data)) {
        renderElement = data.substring(0, size)
    }

    if (isArray(data)) {
        renderElement = (
            <ul>
                {data.map((elem, key) => (
                    <li key={key}>{elem.title}</li>
                ))}
            </ul>
        )
    }

    if (renderElement) {
        return (
            <Callout>
                <pre style={{ margin: 0, overflow: 'hidden' }}>
                    {isString(renderElement)
                        ? `${renderElement}...`
                        : renderElement}
                </pre>
            </Callout>
        )
    }

    if (data.base64) {
        return (
            <Callout>
                <img
                    src={data.base64}
                    style={{ height: 40 }}
                    alt={data.name || `Image preview`}
                />
            </Callout>
        )
    }

    return null
}

class Item extends React.Component {
    onReorder(result) {
        try {
            // Forward reorder to list reorder handler
            this.edit.list.onDragEnd(result)
        } catch (e) {
            // statements
            console.log(e)
        }
    }

    render() {
        const { item, onItemDelete, editPath, id } = this.props
        const EditView = require(`./views/${item.type}`).default
        return (
            <React.Fragment>
                <ItemBody>
                    <EditView
                        activePath={editPath}
                        id={id}
                        ref={ref => {
                            if (item.type === 'list') {
                                this.edit = ref
                            }
                        }}
                    />
                    <Divider />
                    <TypeIcon type={item.type} />
                    <strong>{item.type}</strong>
                    <Button
                        icon="trash"
                        minimal
                        intent={Intent.DANGER}
                        onClick={onItemDelete}
                    />
                </ItemBody>
                <PreviewData data={item.data} />
            </React.Fragment>
        )
    }
}

class Module extends React.Component {
    reorderSubscribers = []

    onDragEnd = result => {
        // Forward reorder to list reorder handler
        if (result.type === 'SUBLIST') {
            this.reorderSubscribers.forEach(sub => {
                if (sub && sub.onReorder) {
                    sub.onReorder(result)
                }
            })
            return
        }

        const { module, reorderModule, editPath } = this.props

        const { source, destination } = result
        // dropped outside the list
        if (!result.destination || destination.index === source.index) {
            return
        }

        // console.log(result)
        const path =
            result.type === 'LIST'
                ? result.source.droppableId.substring(
                      `modules.${module._id}.`.length
                  )
                : `${editPath}`
        reorderModule(module._id, result, path)
    }

    render() {
        const {
            body,
            id,
            addViewToModule,
            deleteViewFromModule,
            editPath,
        } = this.props

        return (
            <Scroll>
                <h4>Document body</h4>
                <Callout>Add your app content below.</Callout>
                <DragDropContext onDragEnd={this.onDragEnd} type="MODULE">
                    <Droppable droppableId="droppable-body">
                        {(provided, snapshot) => (
                            <UL
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {body.map((item, index) => (
                                    <Draggable
                                        key={`${item.type}-${index}`}
                                        draggableId={`${item.type}-${index}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <LI
                                                key={index}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                            >
                                                <Item
                                                    item={item}
                                                    onItemDelete={() => {
                                                        deleteViewFromModule(
                                                            id,
                                                            `${editPath}[${index}]`
                                                        )
                                                    }}
                                                    id={id}
                                                    editPath={`${editPath}[${index}].data`}
                                                    ref={ref => {
                                                        if (
                                                            item.type === 'list'
                                                        ) {
                                                            this.reorderSubscribers.push(
                                                                ref
                                                            )
                                                        }
                                                    }}
                                                />
                                            </LI>
                                        )}
                                    </Draggable>
                                ))}
                            </UL>
                        )}
                    </Droppable>
                </DragDropContext>
                <Divider />
                <h4>Add items to document</h4>
                <ButtonGroup minimal>
                    <Button
                        icon="media"
                        text="Image"
                        onClick={() => {
                            let View = require(`./views/image`).default
                            // console.log(View.defaultProps)
                            addViewToModule(
                                id,
                                `${editPath}`,
                                View.defaultProps.descriptor
                            )
                        }}
                    />
                    <Button
                        icon="new-text-box"
                        text="Text"
                        onClick={() => {
                            let View = require(`./views/text`).default
                            // console.log(View.defaultProps)
                            addViewToModule(
                                id,
                                `${editPath}`,
                                View.defaultProps.descriptor
                            )
                        }}
                    />
                    <Button
                        icon="header"
                        text="Header"
                        onClick={() => {
                            let View = require(`./views/heading`).default
                            // console.log(View.defaultProps)
                            addViewToModule(
                                id,
                                `${editPath}`,
                                View.defaultProps.descriptor
                            )
                        }}
                    />
                    <Button
                        icon="list"
                        text="List"
                        onClick={() => {
                            let View = require(`./views/list`).default
                            // console.log(View.defaultProps)
                            addViewToModule(
                                id,
                                `${editPath}`,
                                View.defaultProps.descriptor
                            )
                        }}
                    />
                    <Button
                        icon="widget-button"
                        text="Button"
                        onClick={() => {
                            let View = require(`./views/button`).default
                            console.log(editPath)
                            addViewToModule(
                                id,
                                `${editPath}`,
                                View.defaultProps.descriptor
                            )
                        }}
                    />
                </ButtonGroup>
            </Scroll>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    module: state.modules[ownProps.id],
})

const mapDispatchToProps = dispatch => {
    return {
        reorderModule: (moduleId, result, path) =>
            dispatch({
                type: 'MODULE_REORDER',
                payload: {
                    moduleId,
                    result,
                    path,
                },
            }),
        addViewToModule: (moduleId, path, data) =>
            dispatch({
                type: 'ADD_VIEW_TO_MODULE',
                payload: {
                    moduleId,
                    path,
                    data,
                },
            }),
        deleteViewFromModule: (moduleId, path) =>
            dispatch({
                type: 'REMOVE_FROM_BODY',
                payload: {
                    moduleId,
                    path,
                },
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Module)
