import React, { useState } from 'react'
import useInterval from '@use-it/interval'
import moment from 'moment'
import { Icon } from '@blueprintjs/core'
import _ from 'lodash'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { useSelector } from 'react-redux'

import { documentState } from 'v2/components/manager/document'
import { getCanWrite } from 'store/selectors'

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    font-size: 12px;

    button {
        flex: 1;
        margin: 0 20px;
    }
`

const SaveButton = styled.button`
    border: 0;
    padding: 10px 20px;
    background: #2874ae;
    box-shadow: 2px 2px 0 0 rgba(40, 116, 174, 0.28);
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
        background: rgba(40, 116, 174, 0.5);
        box-shadow: 2px 2px 0 0 rgba(40, 116, 174, 0.08);
    }
`

const DocumentEditSave = ({
    documentHasEdits,
    documentEditTime,
    onDocumentSave,
}) => {
    const [doc, setDoc] = useRecoilState(documentState)
    const canWrite = useSelector(getCanWrite)
    const updated = doc.updated || ''
    let buttonText = 'Read-Only'

    const [editTimeString, setEditTimeString] = useState('')
    const [savedTimeString, setSavedTimeString] = useState(
        moment(updated).fromNow()
    )

    useInterval(() => {
        if (documentEditTime !== 0) {
            setEditTimeString(moment(documentEditTime).fromNow())
        }
    }, 1000)

    useInterval(() => {
        const _updated = _.get(doc, 'updated')
        if (_updated !== undefined) {
            setSavedTimeString(moment(_updated).fromNow())
        }
    }, 1000)

    if (canWrite) {
        buttonText = documentHasEdits === false ? 'Saved' : 'Save Changes'
    }

    return (
        <Wrapper>
            {documentEditTime !== 0 ? (
                <span>
                    <Icon icon="dot" color="tomato" /> Edited {editTimeString}
                </span>
            ) : updated === '' ? (
                <span>Last save unknown</span>
            ) : (
                <span>Saved {savedTimeString}</span>
            )}
            <SaveButton
                disabled={documentHasEdits === false || !canWrite}
                onClick={onDocumentSave}
            >
                {buttonText}
            </SaveButton>
        </Wrapper>
    )
}

export default DocumentEditSave
