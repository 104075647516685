import React, { useCallback, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Button, Navbar, Alignment, Popover, Menu, Position, Icon } from '@blueprintjs/core'
import { useCookies } from 'react-cookie'
import Keycloak from 'keycloak-js'
import classnames from 'classnames'
import styled from 'styled-components'

import keycloakConfig from '../keycloak.json'
import zaplogo from './landing/assets/zap-logo.png'
import './landing/landing-page.css'

const OrgMenuSelect = () => {
    const [cookies, setCookie] = useCookies(['org'])
    const dispatch = useDispatch()
    const { orgs, org } = useSelector((state) => state.navigation)
    const roles = useSelector((state) => state.roles)

    useEffect(() => {
        if (orgs && orgs.length > 0 && roles) {
            const isVersion1 = window.location.pathname === '/'
            let displayedOrgs = orgs
            let desiredOrg

            if (!roles.includes('admin')) {
                displayedOrgs = orgs.filter(org => (
                    roles.includes(org._id + ':editor') ||
                    roles.includes(org._id + ':viewer')
                ))
            }

            if (cookies['org']) {
                desiredOrg = displayedOrgs.find(org => org._id === cookies['org'])
            }

            if (!desiredOrg) {
                desiredOrg = displayedOrgs[0]
            }

            const versions = Array.isArray(desiredOrg.versions) ? desiredOrg.versions : [1]

            // Org only supports V2
            if (isVersion1 && !versions.includes(1)) {
                window.location = 'start'
            }

            // Org only supports V1
            else if (!isVersion1 && !versions.includes(2)) {
                window.location = '/'
            }

            else {
                dispatch({ type: 'NAVIGATION_SET_ORG', payload: desiredOrg._id })
                dispatch({ type: 'ANALYTICS/GETALL' })
                dispatch({ type: 'MODULES/GETALL' })
            }
        }
    }, [orgs, roles])

    const onChange = useCallback(
        (orgId) => {
            /// Save the user's preferred ORG in cookie.
            /// Expire after 6 months
            const isVersion1 = window.location.pathname === '/'
            const date = new Date()
            const time = date.getTime() + 1000 * 3600 * 24 * 180
            let versions = [1]

            date.setTime(time)
            setCookie('org', orgId, {
                path: '/',
                expires: date,
            })

            try {
                const desiredOrg = orgs.find(org => org._id === orgId)
                versions = Array.isArray(desiredOrg.versions) ? desiredOrg.versions : [1]
            }

            // Hacky solution for orgs not loading completely
            catch (err) {
                window.location.reload()
            }

            if (isVersion1) {
                // Org only supports V2 *or* it defaults to V2
                if (!versions.includes(1) || versions[0] === 2) {
                    window.location = 'start'
                }
            }

            else {
                // Org only supports V1 *or* it defaults to V1
                if (!versions.includes(2) || versions[0] === 1) {
                    window.location = '/'
                }

                // If we're staying in V2 but just switched orgs, we need a hacky refresh
                else {
                    window.location.reload()
                }
            }
        },
        [dispatch]
    )

    const RenderOrgMenu = useCallback(() => {
        let displayedOrgs = orgs

        if (!orgs || orgs.length < 1 || !roles) {
            return null
        }

        if (!roles.includes('admin')) {
            displayedOrgs = orgs.filter(org => (
                roles.includes(org._id + ':editor') ||
                roles.includes(org._id + ':viewer')
            ))
        }

        return (
            <Menu>
                {displayedOrgs.map((org, key) => (
                    <Menu.Item
                        key={key}
                        text={org._id}
                        onClick={() => onChange(org._id)}
                    />
                ))}
            </Menu>
        )
    }, [orgs, roles])

    if (window.location.pathname === '/start') {
        return (
            <Popover content={<RenderOrgMenu />} position={Position.BOTTOM_RIGHT}>
                <Button className="bp3-minimal" rightIcon="chevron-down">
                    {org}
                </Button>
            </Popover>
        )
    }

    return (
        <StyledOrg>
            <div>Zap org</div>
            <Popover content={<RenderOrgMenu />} position={Position.RIGHT}>
                <button>{org}</button>
            </Popover>
        </StyledOrg>
    )
}

const StyledAuthBar = styled.div`
    display: flex;
    align-items: center;

    background-color: #2874ae;
    color: #fff;

    .impressions {
        margin-right: 10px;
        display: flex;
        align-items: center;

        strong {
            margin-left: 10px;
        }
    }

    .version {
        flex: 1;
        padding-right: 10px;
        text-align: right;

        button {
            color: #fff;
            background: transparent;
            border: none;
            cursor: pointer;
        }
    }
`

const StyledOrg = styled.div`
    margin-left: 20px;
    display: flex;
    align-items: center;

    button {
        background: #ffd102;
        border: 0;
        padding: 10px 20px;
        font-size: 1rem;
        font-weight: bold;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
    }
`

const StyledUser = styled.div`
    padding-right: 10px;
    padding-top: 10px;
    .user {
        text-transform: capitalize;
        font-size: 1.2rem;
    }
    button {
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        margin-top: 10px;
        text-decoration: underline;
    }
`

const Header = styled.header`
    &.development {
        &:after {
            content: 'development build';
            position: absolute;
            right: 0;
            top: 0;
            padding: 2px 8px;
            font-size: 0.75rem;
            background: #b6d94c;
            border-bottom-left-radius: 4px;
        }
    }
`

const VersionSwitch = (props) => {
    const isVersion1 = window.location.pathname === '/'
    let versions = [1]
    let content = null

    if (props.org && Array.isArray(props.org.versions)) {
        versions = props.org.versions
    }

    if (isVersion1 && versions.includes(2)) {
        content = (
            <button onClick={() => window.location = '/start'}>
                Show new Zap Editor
            </button>
        )
    }

    else if (!isVersion1) {
        if (versions.includes(1)) {
            return (
                <Menu.Item
                    text="Return to version 1 editor"
                    onClick={() => window.location = '/'}
                />
            )
        }
        else {
            return null
        }
    }

    return (
        <div className="version">
            <div>
                <div>
                    {content}
                </div>
            </div>
        </div>
    )
}

class View extends React.Component {
    onLogin = () => {
        this.kc.login()
    }

    onLogout = () => {
        this.kc.logout()
    }

    componentDidMount() {
        const { setKeycloak, setRoles, fetchOrgs } = this.props

        fetchOrgs()

        this.kc = new Keycloak(keycloakConfig)
        this.kc
            .init({ onLoad: 'check-sso', checkLoginIframe: false })
            .then((authenticated) => {
                // Store the KC object so that we can access it from
                // redux action creators.
                setKeycloak(this.kc)

                if (authenticated) {
                    setRoles(this.kc.tokenParsed.realm_access.roles)

                    setInterval(() => {
                        this.kc.updateToken(10).catch(() => this.kc.logout())
                    }, 10000)
                }
            })
    }

    render() {
        if (window.location.pathname === '/start' && this.props.isAuthenticated && this.kc) {
            return this._renderNewHeader()
        }

        const { org, orgs } = this.props.navigation
        const activeOrg = orgs.find(o => o._id === org)

        return (
            <div id="header-stripe">
                <Header
                    className={classnames({
                        development: process.env.NODE_ENV === 'development',
                    })}
                >
                    <div>
                        <img
                            className="zap-logo"
                            src={zaplogo}
                            alt="ZAP (Zenith App Platform) UCLA"
                        />
                    </div>

                    <nav>
                        <ul id="user-menu">
                            <li>
                                {this.props.isAuthenticated && this.kc ? (
                                    <StyledUser>
                                        <div className="user">
                                            {this.kc.tokenParsed.name}
                                        </div>
                                        <div className="email">
                                            {this.kc.tokenParsed.email}
                                        </div>
                                        <button onClick={this.onLogout}>
                                            Sign out
                                        </button>
                                    </StyledUser>
                                ) : (
                                    <Button
                                        className="pt-button pt-fill pt-intent-primary login"
                                        text="Login"
                                        onClick={this.onLogin}
                                    />
                                )}
                            </li>
                        </ul>
                    </nav>
                </Header>

                {this.props.isAuthenticated ? (
                    <StyledAuthBar>
                        <OrgMenuSelect />
                        {this._renderTotalImpressions()}
                        {this._renderCurrentViewImpressions()}
                        <VersionSwitch org={activeOrg} />
                    </StyledAuthBar>
                ) : null}
            </div>
        )
    }

    _renderTotalImpressions() {
        let total = 0

        Object.keys(this.props.analytics).forEach((moduleId) => {
            // Only count impressions for active modules and dashboard
            if (
                this.props.modules[moduleId] ||
                moduleId === this.props.dashboard._id
            ) {
                total += this.props.analytics[moduleId]
            }
        })

        return (
            <div className="impressions">
                total active view impressions{' '}
                <strong>{total.toLocaleString()}</strong>
            </div>
        )
    }

    _renderCurrentViewImpressions() {
        const { preview, analytics, dashboard } = this.props
        let title = 'View Impressions'
        let impressions = 0

        if (preview && preview.parent && preview.parent.type === 'root') {
            title = 'Dashboard Impressions'
            impressions = analytics[dashboard._id] || 0
        } else if (preview && preview.navigation && preview.navigation.module) {
            impressions = analytics[preview.navigation.module.id] || 0
        }

        return (
            <div className="impressions">
                <Icon icon="caret-right" /> {title}{' '}
                <strong>{impressions.toLocaleString()}</strong>
            </div>
        )
    }

    _renderNewHeader() {
        const { org, orgs } = this.props.navigation
        const activeOrg = orgs.find(o => o._id === org)

        const helpMenuItems = [
            { text: 'ZAP User Guide', url: 'https://drive.google.com/file/d/1U9s98wqvG1KfL2b_tVkxaMmfaRI0YAao/view' },
            { text: 'ZAP Cheat Sheet', url: 'https://drive.google.com/file/d/19jGDbBXneNUk5qw50TGqFaNbQfc1kfGA/view' },
            { text: 'OPT Poll Cheat Sheet', url: 'https://drive.google.com/file/d/1IyxBUnW506s0PvHTG-78z_N2bwVJ-1pW/view' },
        ]

        const helpMenu = (
            <Menu>
                {helpMenuItems.map(item => (
                    <li key={item.url}>
                        <a
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                            className="bp3-menu-item bp3-popover-dismiss"
                        >
                            {item.text}
                        </a>
                    </li>
                ))}
            </Menu>
        )

        const userMenu = (
            <Menu>
                <Menu.Divider title={this.kc.tokenParsed.email} />
                <Menu.Item text="Sign Out" onClick={this.onLogout} />
                <VersionSwitch org={activeOrg} />
            </Menu>
        )

        return (
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>
                        <img
                            src={zaplogo}
                            alt="ZAP (Zenith App Platform) UCLA"
                            style={{ height: '45px' }}
                        />
                    </Navbar.Heading>
                </Navbar.Group>

                <Navbar.Group align={Alignment.RIGHT}>
                    <Popover content={helpMenu} position={Position.BOTTOM_RIGHT}>
                        <Button className="bp3-minimal" rightIcon="chevron-down">
                            Help
                        </Button>
                    </Popover>
                    <OrgMenuSelect />
                    <Popover content={userMenu} position={Position.BOTTOM_RIGHT}>
                        <Button className="bp3-minimal" rightIcon="chevron-down">
                            {this.kc.tokenParsed.name}
                        </Button>
                    </Popover>
                </Navbar.Group>
            </Navbar>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    isAuthenticated: state.keycloak.authenticated,
    modules: state.modules,
    analytics: state.analytics,
    preview: state.preview,
    dashboard: state.dashboard,
    navigation: state.navigation,
})

const mapDispatchToProps = (dispatch) => ({
    setKeycloak: kc => dispatch({ type: 'SET_KEYCLOAK', payload: kc }),
    setRoles: roles => dispatch({ type: 'SET_ROLES', payload: roles }),
    fetchOrgs: () => dispatch({ type: 'ORGS/FETCH' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
