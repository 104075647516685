import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
    Alert,
    Button,
    Collapse,
    Popover,
    Menu,
    Position,
    Intent,
    Classes,
    Callout,
    Dialog,
    FormGroup,
    InputGroup,
    Divider,
    Label,
    HTMLSelect,
    Checkbox,
} from '@blueprintjs/core'
import _ from 'lodash'
import { useManager } from 'v2/components/manager/store'
import RenderLoading from 'v2/components/preview/render/loading'
import { ImageUploader } from 'v2/components/widgets/image'
import DocumentEditSave from 'v2/components/tools/save'

const Validator = require('jsonschema').Validator

const Container = styled.div`
    width: 80%;
    background: #313131;
    color: #fff;
    padding: 20px 20px;
    border-radius: 9px;

    > .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .type {
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    > .content {
        > .title {
            display: flex;
            align-items: center;

            font-size: 26px;
            font-weight: 200;
            margin-bottom: 10px;

            span {
                display: inline-block;
                padding: 2px;
                height: 30px;
                background: #fff;
                border-radius: 3px;
                margin-right: 10px;

                img {
                    height: 100%;
                }
            }
        }
        .impressions {
            margin-bottom: 20px;
            span {
                font-weight: bold;
            }
        }
        .meta {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 200;

            span {
                display: inline-block;
                width: 30px;
                text-align: right;
                font-size: 24px;
                margin-right: 10px;
                font-weight: 500;
            }
        }

        .stats {
            background-color: #efeff1;
            margin: 0 -20px;
            padding: 10px 20px;
            color: #000;
            font-size: 16px;
            font-weight: 300;
        }

        .revision {
            display: flex;
            /*justify-content: space-between;*/
            align-items: center;
            /*margin-bottom: 10px;*/

            .version {
                flex: 1;
                text-align: left;
                span {
                    font-weight: bold;
                }
            }
        }

        .updated,
        .published {
            margin-top: 10px;

            .title {
                font-weight: 500;
                margin-bottom: 6px;
            }
        }
    }
`

export const revisionPrettyPrint = (rev) => {
    return rev.split('-')[0]
}

const ImageViewer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
    }
`

export const PanelHeaderDialog = ({ isOpen, onClose, initialData, onSave }) => {
    const schema = {
        id: '/DocHeader',
        type: 'object',
        properties: {
            type: {
                type: 'string',
            },
            title: {
                type: 'string',
                minLength: 0,
                maxLenght: 40,
            },
            hiddenTitle: {
                type: 'string',
                minLength: 0,
                maxLenght: 40,
            },
            image: {
                type: 'object',
                properties: {
                    base64: {
                        type: 'string',
                    },
                },
            },
        },
    }

    const errorMap = {
        'instance.title': 'Header Title',
    }

    const [data, setValue] = useState(initialData)
    const [errors, setErrors] = useState([])

    const _updateTitle = (event) => {
        const val = event.currentTarget.value

        if (data.hasOwnProperty('hiddenTitle')) {
            setValue({ ...data, hiddenTitle: val })
        }
        else {
            setValue({ ...data, title: val })
        }
    }

    const _updateHidden = (event) => {
        if (event.currentTarget.checked) {
            setValue({ ...data, title: '', hiddenTitle: data.title || '' })
        }
        else {
            const { hiddenTitle, ...rest } = data
            setValue({ ...rest, title: data.hiddenTitle || '' })
        }
    }

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Edit Header"
            onOpening={() => {
                setValue(initialData)
                setErrors([])
            }}
        >
            <div className={Classes.DIALOG_BODY}>
                {errors.length > 0 ? (
                    <Callout title="There was an error" intent={Intent.WARNING}>
                        <ul>
                            {errors.map((err, k) => (
                                <li key={k}>
                                    <div>
                                        <strong>
                                            {errorMap[err.property]}
                                        </strong>
                                    </div>
                                    <div>{err.stack}</div>
                                </li>
                            ))}
                        </ul>
                    </Callout>
                ) : null}

                <FormGroup
                    intent={Intent.PRIMARY}
                    label="Header Type"
                    helperText="Header type.  Select dashboard for expanded header"
                    labelFor="text-input-type"
                    labelInfo="(required)"
                >
                    <HTMLSelect
                        value={data.type || 'view'}
                        onChange={(e) =>
                            setValue({ ...data, type: e.currentTarget.value })
                        }
                    >
                        <option value="view">
                            View
                        </option>
                        <option value="dashboard">
                            Dashboard
                        </option>
                    </HTMLSelect>
                </FormGroup>
                <Divider />

                <FormGroup
                    intent={Intent.PRIMARY}
                    label="Title"
                    helperText="This is the title that is used on the device screen"
                    labelFor="text-input-title"
                >
                    <InputGroup
                        id="text-input-title"
                        value={data.hasOwnProperty('hiddenTitle') ? data.hiddenTitle : data.title}
                        onChange={_updateTitle}
                    />
                </FormGroup>

                <Checkbox
                    label="Title is hidden in app (only visible in the editor)"
                    checked={data.hasOwnProperty('hiddenTitle')}
                    onChange={_updateHidden}
                />
                <Divider />

                <Label>Header Icon</Label>
                {data.image.base64 !== '' ? (
                    <ImageViewer>
                        <img src={data.image.base64} alt="header" />
                        <Button
                            onClick={() =>
                                setValue({ ...data, image: { base64: '' } })
                            }
                            text="Clear image"
                            icon="trash"
                        />
                    </ImageViewer>
                ) : null}
                <ImageUploader
                    onChange={(base64) =>
                        setValue({ ...data, image: { base64 } })
                    }
                />
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Cancel" onClick={onClose} minimal="true" />
                    <Button
                        text="OK"
                        intent={Intent.PRIMARY}
                        minimal="true"
                        onClick={() => {
                            const v = new Validator()
                            const res = v.validate(data, schema)

                            setErrors(res.errors)

                            if (res.valid) {
                                onSave(data)
                                onClose()
                            }
                        }}
                    />
                </div>
            </div>
        </Dialog>
    )
}

const PanelHeader = ({
    documentEditTime,
    onDocumentDelete,
    onHeaderUpdate,
    onDocumentSave,
    documentHasEdits,
}) => {
    const { state } = useManager()
    const { editable } = state

    const impressions = useSelector((state) => state.analytics[editable._id])

    const [isOpen, setOpen] = useState(false)
    const [isHeaderOpen, setHeaderOpen] = useState(false)
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false)

    if (_.isEmpty(editable)) {
        return <RenderLoading />
    }

    const timelineCount = editable.body.filter((b) => b.type === 'Timeline')
        .length
    const reminderCount = editable.body.filter((b) => b.type === 'Reminder')
        .length

    const { header } = editable

    return (
        <Container>
            <div className="heading">
                <div className="type">{editable.screen}</div>
                <Popover position={Position.BOTTOM}>
                    <Button icon="menu" minimal="true" />
                    <Menu>
                        <Menu.Item
                            icon="edit"
                            text="Edit Header"
                            onClick={() => {
                                setHeaderOpen(true)
                            }}
                        />
                        <Menu.Divider />
                        <Menu.Item
                            intent={Intent.DANGER}
                            icon="trash"
                            text="Delete"
                            onClick={() => setDeleteAlertOpen(true)}
                        />
                    </Menu>
                </Popover>
            </div>
            <div className="content">
                <div className="title">
                    {_.get(header, 'image.base64') ? (
                        <span>
                            <img src={header.image.base64} alt={header.title} />
                        </span>
                    ) : null}
                    {header.hasOwnProperty('hiddenTitle') ? header.hiddenTitle : header.title}
                </div>
                {_.isNumber(impressions) ? (
                    <div className="impressions">
                        <span>{impressions}</span> impressions
                    </div>
                ) : null}
                <div className="meta">
                    <span>{timelineCount}</span> timeline types
                </div>
                <div className="meta">
                    <span>{reminderCount}</span> reminder types
                </div>
                <div className="stats">
                    <div className="revision">
                        <div>
                            <Button
                                icon="chevron-down"
                                minimal="true"
                                onClick={() => setOpen(!isOpen)}
                            />
                        </div>
                        <div className="version">
                            revision{' '}
                            <span>{revisionPrettyPrint(editable._rev)}</span>
                        </div>
                        <div>
                            <button>publish</button>
                        </div>
                    </div>
                    <Collapse isOpen={isOpen}>
                        <div className="updated">
                            <div className="title">Updated</div>
                            23 min ago
                        </div>
                        <div className="published">
                            <div className="title">published</div>3 days ago -
                            rev <span>2</span>
                        </div>
                    </Collapse>
                </div>
                <DocumentEditSave
                    documentHasEdits={documentHasEdits}
                    documentEditTime={documentEditTime}
                    onDocumentSave={onDocumentSave}
                />
            </div>
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={() => setDeleteAlertOpen(false)}
                onConfirm={() => {
                    onDocumentDelete()
                    setDeleteAlertOpen(false)
                }}
            >
                <p>
                    Are you sure you want to delete this{' '}
                    <b>{editable.screen}</b> view?
                </p>
            </Alert>
            <PanelHeaderDialog
                isOpen={isHeaderOpen}
                onClose={() => setHeaderOpen(false)}
                initialData={header}
                onSave={(header) => onHeaderUpdate(header)}
            />
        </Container>
    )
}

export default PanelHeader
