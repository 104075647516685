import React, { Component } from 'react'
import {
    Button,
    Dialog,
    Intent,
    Popover,
    Menu,
    Classes,
    MenuItem,
    Callout,
    InputGroup,
    Label,
    TextArea,
} from '@blueprintjs/core'
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'
import styled from 'styled-components'
import { moduleBody, defaultDocument } from './defaults'

const CreateBody = styled.div``

const Document = styled.div`
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ebf1f5;
    background: #f5f8fa;
    border-radius: 4px;
`

const selectModuleType = type => {
    let screen = 'GenericView'
    switch (type) {
        case 'timeline':
            screen = 'TimelineViewer'
            return {
                updated: new Date(),
                parent: [],
                screen,
                metadata: {
                    title: 'Untitled',
                    description: '',
                    reference: 'untitled',
                    updated: 'date',
                    editor: 'user',
                },
                header: {
                    title: 'Untitled',
                    image: {
                        base64: '',
                    },
                },
                body: [],
            }
            break
        case 'tabbed':
            screen = 'TabbedView'
            break
        case 'nearby':
            screen = 'NearbyView'
            break
        default:
            screen = 'GenericView'
    }
    return {
        ...defaultDocument,
        screen,
        body: moduleBody[type],
    }
}

class EditDialog extends Component {
    state = {
        isOpen: false,
    }
    _handleClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        const { module, moduleId, saveMetadata } = this.props
        const { isOpen } = this.state
        // Only render valid modules
        return module ? (
            <React.Fragment>
                <div>
                    <Button
                        minimal
                        small="true"
                        icon="edit"
                        fill="true"
                        onClick={() =>
                            this.setState({ isOpen: !this.state.isOpen })
                        }
                    />
                </div>
                <Dialog
                    isOpen={isOpen}
                    usePortal={true}
                    lazy={true}
                    title={`Edit Metadata`}
                    onClose={this._handleClose}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <Callout intent={Intent.PRIMARY}>
                            The document title and description are searchable.
                            This data is not rendered to the device.
                        </Callout>
                        <EditMetaData
                            metadata={module.metadata}
                            ref={ref => (this.metadata = ref)}
                        />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                minimal
                                text="Cancel"
                                onClick={() => this.setState({ isOpen: false })}
                            />
                            <Button
                                minimal
                                intent={Intent.PRIMARY}
                                onClick={() => {
                                    saveMetadata(
                                        moduleId,
                                        this.metadata.retrieveData()
                                    )
                                    this.setState({ isOpen: false })
                                }}
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        ) : null
    }
}

const mapStateToProps = (state, ownProps) => ({
    module: state.modules[ownProps.moduleId],
})

const mapDispatchToProps = dispatch => ({
    saveMetadata: (moduleId, metadata) =>
        dispatch({
            type: 'MODULES/UPDATEMETADATA',
            payload: { moduleId, metadata },
        }),
})

const EditMetaDataDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDialog)

class EditMetaData extends Component {
    constructor(props) {
        super(props)

        this.state = {
            metadata: props.metadata,
        }
    }

    retrieveData() {
        return this.state.metadata
    }

    render() {
        const { metadata } = this.state
        return (
            <Document>
                <Label>
                    Title
                    <InputGroup
                        fill
                        placeholder="Untitled"
                        value={metadata.title}
                        onChange={e =>
                            this.setState({
                                metadata: {
                                    ...metadata,
                                    title: e.target.value,
                                },
                            })
                        }
                    />
                </Label>
                <Label>
                    Description
                    <TextArea
                        fill
                        placeholder="None"
                        value={metadata.description}
                        onChange={e =>
                            this.setState({
                                metadata: {
                                    ...metadata,
                                    description: e.target.value,
                                },
                            })
                        }
                    />
                </Label>
            </Document>
        )
    }
}

class Create extends Component {
    constructor(props) {
        super(props)
        this.moduleTypes = Object.keys(moduleBody)
        this.state = {
            isOpen: false,
            selectedType: 'simple',
            metadata: {
                title: '',
                description: '',
            },
        }
    }

    render() {
        const { onCreate, org, orgLimit, modules } = this.props
        const { selectedType, metadata } = this.state

        if (modules && Object.keys(modules).length >= orgLimit) {
            return (
                <div>
                    You have exceeded the number of views you're allowed to
                    create. Your limit is <strong>{orgLimit}</strong> views.
                </div>
            )
        }
        return (
            <React.Fragment>
                <Button
                    minimal
                    icon="document"
                    fill
                    onClick={() =>
                        this.setState({ isOpen: !this.state.isOpen })
                    }
                >
                    Create a new view
                </Button>
                <Dialog
                    icon="document"
                    isOpen={this.state.isOpen}
                    onClose={() =>
                        this.setState({ isOpen: !this.state.isOpen })
                    }
                    onOpening={() =>
                        this.setState({
                            metadata: { title: '', description: '' },
                        })
                    }
                    title="Create a new view"
                >
                    <div className={Classes.DIALOG_BODY}>
                        <CreateBody>
                            <h3>Type of view?</h3>
                            <Popover>
                                <Button
                                    text={selectedType}
                                    minimal
                                    intent={Intent.PRIMARY}
                                />
                                <Menu>
                                    {this.moduleTypes.map((type, key) => (
                                        <MenuItem
                                            key={key}
                                            onClick={() =>
                                                this.setState({
                                                    selectedType: type,
                                                })
                                            }
                                            text={capitalize(type)}
                                        />
                                    ))}
                                </Menu>
                            </Popover>
                            <h3>Document metadata</h3>
                            <Callout intent={Intent.PRIMARY}>
                                Giving your document a title and description
                                will make it easier to find. This is metadata
                                that is not required and is not rendered to the
                                device.
                            </Callout>
                            <EditMetaData
                                metadata={metadata}
                                ref={ref => (this.metadata = ref)}
                            />
                        </CreateBody>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                minimal
                                text="Cancel"
                                onClick={() => this.setState({ isOpen: false })}
                            />
                            <Button
                                minimal
                                intent={Intent.PRIMARY}
                                onClick={() => {
                                    // console.log(this.metadata.retrieveData())
                                    onCreate({
                                        ...selectModuleType(selectedType),
                                        org: org,
                                        metadata: this.metadata.retrieveData(),
                                    })
                                    this.setState({ isOpen: false })
                                }}
                            >
                                Create <strong>{selectedType}</strong> view
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

const _mapStateToProps = (state, ownProps) => {
    return {
        modules: state.modules,
        orgLimit: state.admin.orgViewLimit,
    }
}

const CreateView = connect(_mapStateToProps)(Create)

const CreateLinkedView = ({ onCreate, org }) => (
    <Callout title="Create a new local view">
        <CreateView onCreate={onCreate} org={org} />
    </Callout>
)

export { CreateView, CreateLinkedView, EditMetaDataDialog }
