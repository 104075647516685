import React from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Intent } from '@blueprintjs/core'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'

const List = styled.ul`
    padding: 10px;
    margin: 0;
    list-style: none;
    background: ${props => (props.isDraggingOver ? '#CED9E0' : '#fff')};
`

const ListItem = styled.li`
    background: ${props => (props.isDragging ? '#fff' : '#F5F8FA')};
    border: 1px solid #bfccd6;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .content {
        display: grid;
        grid-template-columns: 1fr 50px;
        align-items: center;
    }
`

class Model extends React.PureComponent {
    _updateItem(item, index) {
        let items = [...this.props.items]

        items.splice(index, 1, item)
        this.props.updateItem(items)
    }

    _addItem(template) {
        let items = this.props.items
        items.push(template)
        this.props.updateItem(items)
    }

    _deleteItem(index) {
        let items = this.props.items

        items.splice(index, 1)
        this.props.updateItem(items)
    }

    onDragEnd = result => {
        const { source, destination } = result

        // dropped outside the list, or in same place.
        if (!result.destination || destination.index === source.index) {
            return
        }

        // Swap elements
        let items = [...this.props.items]
        const item = items[source.index]
        items.splice(source.index, 1)
        items.splice(destination.index, 0, item)

        // console.log(this.props.items, items)
        // Save to model
        this.props.updateItem(items)
    }

    render() {
        const { items, itemTemplate, children } = this.props
        return (
            <React.Fragment>
                <Droppable droppableId="sublist" type="SUBLIST">
                    {(provided_, snapshot) => (
                        <List ref={provided_.innerRef}>
                            {items.map((item, key) => (
                                <Draggable
                                    key={`${item.name}-${key}`}
                                    draggableId={`sub-list-${key}`}
                                    index={key}
                                >
                                    {(provided, snapshot) => (
                                        <ListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={
                                                provided.draggableProps.style
                                            }
                                        >
                                            <div className="content">
                                                {/// Render editable children models.
                                                /// Send back the `item` representation and `key`
                                                /// since child won't know what's being rendered.
                                                children(
                                                    {
                                                        item: { ...item },
                                                        index: key,
                                                    },
                                                    this._updateItem.bind(this)
                                                )}

                                                <Button
                                                    icon="trash"
                                                    minimal
                                                    intent={Intent.DANGER}
                                                    onClick={() =>
                                                        this._deleteItem(key)
                                                    }
                                                />
                                            </div>
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                        </List>
                    )}
                </Droppable>
                <Divider />
                <Button
                    fill
                    minimal
                    icon="add-to-artifact"
                    onClick={() => this._addItem(itemTemplate)}
                    text="Add new list item"
                />
            </React.Fragment>
        )
    }
}

Model.propTypes = {
    itemTemplate: PropTypes.object.isRequired,
    children: PropTypes.func.isRequired,
}

export default Model
