import React, { useState } from 'react'
import _ from 'lodash'
import {
    Dialog,
    Button,
    Label,
    Menu,
    MenuItem,
    InputGroup,
    NumericInput,
    Classes,
    Intent,
    Divider,
} from '@blueprintjs/core'

const TimelineHeaderEditor = ({
    isOpen,
    onClose,
    initialData,
    options,
    onChange,
}) => {
    const [_value, setValue] = useState(initialData)

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Timeline series"
            onOpening={() => setValue(initialData)}
        >
            <div className={Classes.DIALOG_BODY}>
                <Label>
                    Title
                    <InputGroup
                        value={_value.title}
                        onChange={(e) => {
                            setValue({
                                ..._value,
                                title: e.target.value,
                            })
                        }}
                    />
                </Label>
                <Divider />
                <Label>
                    Period
                    <NumericInput
                        min={-Infinity}
                        max={Infinity}
                        value={_value.time.value}
                        onValueChange={(_v, time) => {
                            if (_.isInteger(parseInt(time))) {
                                setValue({
                                    ..._value,
                                    time: {
                                        ..._value.time,
                                        value: parseInt(time),
                                    },
                                })
                            }
                        }}
                    />
                </Label>
                <Divider />
                <Label>
                    Unit
                    <Menu>
                        {options.map((key) => (
                            <MenuItem
                                key={key}
                                text={key}
                                active={key === _value.time.unit}
                                onClick={() =>
                                    setValue({
                                        ..._value,
                                        time: {
                                            ..._value.time,
                                            unit: key,
                                        },
                                    })
                                }
                            />
                        ))}
                    </Menu>
                </Label>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Cancel" onClick={onClose} minimal="true" />
                    <Button
                        text="Save changes"
                        intent={Intent.PRIMARY}
                        minimal="true"
                        onClick={() => {
                            onChange(_value)
                            onClose()
                        }}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default TimelineHeaderEditor
