import {
    createStore as reduxCreateStore,
    applyMiddleware,
    compose,
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import { init as socketInit } from './socket'

export const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

// TEMPORARYLY ALLOW LOGGING IN PRODUCTION
// if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`)
    const logger = createLogger({
        collapsed: true,
    })

    middlewares.push(logger)
// }

const initialState = {
    dashboard: {
        items: [],
    },
    dashboardItems: {},
    editing: [],
    modules: {},
    keycloak: {},
    navigation: {
        org: '',
        orgs: [],
        selectedTab: 'dashboard',
        messages: [],
    },
}

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const createStore = () => {
    const store = reduxCreateStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middlewares))
    )

    // Play nice with hot reload
    // https://github.com/reactjs/react-redux/releases/tag/v2.0.0
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers')
            store.replaceReducer(nextRootReducer)
        })
    }

    socketInit(store)

    return store
}

export default createStore
