import React from 'react'
import styled from 'styled-components'

import './devices.min.css'
import './preview.css'

export const Screen = styled.div`
    .bp3-panel-stack {
        position: initial;
    }
    .bp3-panel-stack-header {
        height: 40px;
    }

    box-sizing: border-box;
`

const Device = ({ children }) => (
    <div className="marvel-device iphone8 silver">
        <div className="top-bar" />
        <div className="sleep" />
        <div className="volume" />
        <div className="camera" />
        <div className="sensor" />
        <div className="speaker" />
        <div className="screen">{children}</div>
        <div className="home" />
        <div className="bottom-bar" />
    </div>
)

class Module extends React.Component {

    render() {
        const { children } = this.props
        return (
            <Device>
                <Screen>
                    {children}
                </Screen>
            </Device>
        )
    }
}

export default Module
