import React from 'react'
import { Radio, RadioGroup, Divider, Alignment } from '@blueprintjs/core'

const Module = ({ layout, handleLayoutChange }) => (
    <React.Fragment>
        <p>
            When using a layout with a featured image, the first dashboard item
            will be used as the featured image.
        </p>
        <Divider />
        <RadioGroup
            alignIndicator={Alignment.RIGHT}
            name="layout"
            onChange={handleLayoutChange}
            selectedValue={layout}
        >
            <Radio label="buttons, two across" value="half-button" />
            <Radio
                label="buttons, two across, with featured image"
                value="half-button-image"
            />
            <Radio label="buttons, three across" value="third-button" />
            <Radio
                label="buttons, three across, with featured image"
                value="third-button-image"
            />
            <Radio label="list, in blue" value="list" />
            <Radio
                label="list, in blue, with featured image"
                value="list-image"
            />
            <Radio label="list, in white" value="list-white" />
            <Radio
                label="list, in white, with featured image"
                value="list-white-image"
            />
            <Radio label='vertical with blue label' value='vertical-with-blue-label' />
            <Radio label="app-specific" value="app-specific" />
        </RadioGroup>
    </React.Fragment>
)

export default Module
