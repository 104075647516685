import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Card, Elevation, Callout, Divider } from '@blueprintjs/core'
import Identifier from './metadata'
import LayoutEdit from './layout'
import LockedOverlay from '../lockedOverlay'

class DashboardDocumentView extends PureComponent {
    render() {
        const {
            dashboard,
            updateDashboardLayout,
            saveDashboard,
            layoutLocked,
            analytics,
            modules,
        } = this.props

        return (
            <Card elevation={Elevation.TWO}>
                <h2>App Dashboard</h2>
                <Identifier
                    id={dashboard._id}
                    rev={dashboard._rev}
                    updated={dashboard.updated}
                    analytics={analytics}
                    modules={modules}
                />
                <Callout title="Layout" style={{ position: 'relative' }}>
                    {layoutLocked && <LockedOverlay />}
                    <LayoutEdit
                        layout={dashboard.layout}
                        handleLayoutChange={updateDashboardLayout}
                    />
                </Callout>
                <Divider />
                <Button
                    minimal
                    fill
                    icon="cloud-upload"
                    onClick={saveDashboard}
                >
                    Save dashboard
                </Button>
            </Card>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        dashboard: state.dashboard,
        analytics: state.analytics,
        modules: state.modules,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveDashboard: () => {
            dispatch({ type: 'DASHBOARD/SAVE' })
        },
        updateDashboardLayout: e =>
            dispatch({
                type: 'DASHBOARD_UPDATE_LAYOUT',
                payload: e.target.value,
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardDocumentView)
