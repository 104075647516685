/* eslint-disable */
import React from 'react'
import { Position, Toaster, Intent } from '@blueprintjs/core'
import styled from 'styled-components'

const Pre = styled.pre`
    background: #ff7373;
    font-size: 0.75rem;
    overflow: scroll;
    width: 440px;
    height: 500px;
    padding: 4px;
    border-radius: 4px;
`

class Module extends React.Component {
    refHandlers = {
        toaster: ref => (this.toaster = ref),
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.messages.length) {
            // REDUX hack
            // This will remove the message from the redux store
            const toast = newProps.messages.pop()
            // Convert string intent to @blueprint intent
            if (toast.intent && typeof toast.intent === 'string') {
                toast.intent = Intent[toast.intent.toUpperCase()]
            }
            // console.log(toast.message)
            if (toast.error) {
                const error = <Pre>{toast.error.toString()}</Pre>
                toast.message = (
                    <React.Fragment>
                        {toast.message}
                        <details>{error}</details>
                    </React.Fragment>
                )
            }

            this.toaster.show(toast)
        }
    }

    render() {
        return (
            <Toaster
                position={Position.TOP_CENTER}
                ref={this.refHandlers.toaster}
            />
        )
    }
}

export default Module
